import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import AgentService from "../../services/agent/AgentService";

export const AgentContext = createContext("AgentContext");

const AgentContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [agentTotalElements, setAgentTotalElements] = useState(0);
    const [agentList, setAgentList] = useState([]);

    const getAllAgents = async params => {
        try {

            setLoading(true);

            const res = await AgentService.getAllAgent(params);
            setAgentList(res.data.content);
            setAgentTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <AgentContext.Provider
            value={{
                loading,
                agentTotalElements,
                agentList,
                getAllAgents,
            }}
        >
            {children}
        </AgentContext.Provider>
    );
}

export default AgentContextProvider;
