export const RESTAURANT_TOKEN = "QizoiAmXjqTFvuTBev5Cvw==";
export const RESTAURANT_BRAND_DOMAIN = "kababking.com";
export const RESTAURANT_COPYRIGHT = "zaagsys.com";
export const RESTAURANT_SECONDARY_COLOR = "#787878";

// Theme color
export const RESTAURANT_PRIMARY_COLOR = "#AB323C";
export const RESTAURANT_PRIMARY_COLOR_FADE = "#AB323C21";
export const PRIMARY_COLOR_FADE_2 = "#EBDEDD";
export const INPUT_HOVER_COLOR = "#000000";
export const WHITE = "#FFFFFF";
export const TEXT_BASE_COLOR = "#3A3A3A";