import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";

export default class BannerService {

    static createBanner = data => privateAPI.post(
        `${URL.CREATE_BANNER_URL}`, data
    );

    static getAllBanner = params => privateAPI.get(
        `${URL.GET_ALL_BANNER_URL}`, {params}
    );

    static getBannerById = bannerId => privateAPI.get(
        `${URL.GET_BANNER_BY_ID_URL}/${bannerId}`
    );

    static updateBanner = (bannerId, data) => privateAPI.put(
        `${URL.UPDATE_BANNER_URL}/${bannerId}`, data
    );

}
