import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {LOGIN_PATH, OWNER_LOGIN_PATH} from "../../routes/Slug";

const PrivateRoute = ({isLogin, selectedBranch}) => {
    const _to = !selectedBranch ? OWNER_LOGIN_PATH : LOGIN_PATH;
    // const _to = (!loggedInAs || (loggedInAs === OWNER)) ? OWNER_LOGIN_PATH : LOGIN_PATH;
    return (isLogin ? <Outlet/> : <Navigate to={_to}/>);
}

export default PrivateRoute;
