import React, {createContext, useContext, useState} from 'react';
import {
    DELIVERY,
    POS_COMPLETE_ORDER,
    POS_ORDER_CART,
    POS_ORDER_CART_INVOICE,
    POS_ORDER_HOLD_LIST,
    POS_ORDER_INFO
} from "../../helpers/Constant";
import {
    getDiscountAmount,
    getErrorMessage,
    getPercentDiscountAmount,
    percentValueCalculation,
    precisionFactor
} from "../../helpers/Utils";
import BranchOrderService from "../../services/order/BranchOrderService";
import {Toast} from "../../components/common/Toast";
import {AuthContext} from "../AuthContextProvider";
import CustomerService from "../../services/CustomerService";

export const PosOrderContext = createContext("PosOrderContext");

const _posOrderMap = localStorage.getItem(POS_ORDER_CART);
const _posOrderInvoice = localStorage.getItem(POS_ORDER_CART_INVOICE);
const _posOrderInfo = localStorage.getItem(POS_ORDER_INFO);
const _posOrderHoldList = localStorage.getItem(POS_ORDER_HOLD_LIST);

const PosOrderContextProvider = ({children}) => {

    const {branchConfig} = useContext(AuthContext);

    const [posOrderMap, setPosOrderMap] = useState(_posOrderMap ? JSON.parse(_posOrderMap) : null);
    const [posOrderSelectedItemId, setPosOrderSelectedItemId] = useState("");
    const initPosCartInvoice = {
        totalProductPrice: 0,
        totalAddOnPrice: 0,
        discount: {discountValue: 0, discountType: "FLAT"},
        subTotal: 0,
        finalPrice: 0,
        tax: 0,
        inputDiscountValue: 0,
        readOnlyDiscount: 0,
        customerPaid: 0,
        returnAmount: 0,
    }
    const [posCartInvoice, setPosCartInvoice] = useState(
        _posOrderInvoice ? JSON.parse(_posOrderInvoice) : initPosCartInvoice
    );

    const _paymentTypes = [
        {id: 1, type: "CASH", isSelected: true, amount: ""},
        {id: 2, type: "ONLINE", isSelected: false, amount: ""},
        {id: 3, type: "MFS", isSelected: false, amount: ""}
    ]
    const [posOrderInfo, setPosOrderInfo] = useState(
        _posOrderInfo ? JSON.parse(_posOrderInfo) : {
            orderType: "DINE_IN",
            paymentTypes: _paymentTypes,
            tableList: [],
            addressList: [],
        }
    );
    const [createOrderLoading, setCreateOrderLoading] = useState(false);
    const [posOrderHoldList, setPosOrderHoldList] = useState(_posOrderHoldList ? JSON.parse(_posOrderHoldList) : []);

    const [posOrderProductMultiplyCount, setPosOrderProductMultiplyCount] = useState(0);

    const addPosOrder = (branchProduct, id) => {

        let _posOrderMap = {...posOrderMap};

        if (_posOrderMap[branchProduct.id]) {

            const posOrderDetails = _posOrderMap[branchProduct.id];

            _posOrderMap[branchProduct.id] = {
                ...posOrderDetails,
                quantity: posOrderDetails.quantity + (posOrderProductMultiplyCount > 0 ? posOrderProductMultiplyCount : 1)
            }

        } else {

            _posOrderMap = {
                ...posOrderMap,
                [branchProduct.id]: {
                    branchProduct: branchProduct,
                    quantity: posOrderProductMultiplyCount > 0 ? posOrderProductMultiplyCount : 1,
                    cartAddOnDetailsList: []
                }
            }
        }

        localStorage.setItem(POS_ORDER_CART, JSON.stringify(_posOrderMap))
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap, id);

    }

    const removeItem = (id, editId) => {

        delete posOrderMap[id];

        localStorage.setItem(POS_ORDER_CART, JSON.stringify(posOrderMap));
        setPosOrderMap({...posOrderMap});

        if (Object.keys(posOrderMap)?.length < 1) {
            clearPosOrderLocalStorage();
            return;
        }

        getPosCartInvoiceCalculation(posOrderMap, editId);

    }

    const incrementHandle = (id, operation, editId) => {

        let _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[id];

        if (operation === -1 && posOrderDetails.quantity === 1) {
            return;
        }

        _posOrderMap[id] = {
            ...posOrderDetails,
            quantity: posOrderDetails.quantity + operation
        }

        localStorage.setItem(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap, editId);

    }

    const addPosCartAddOn = (branchProductId, branchAddOn) => {

        const _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[branchProductId];

        _posOrderMap[branchProductId] = {
            ...posOrderDetails,
            cartAddOnDetailsList: [
                ...posOrderDetails.cartAddOnDetailsList,
                {
                    branchAddOn,
                    quantity: 1
                }
            ]
        }

        localStorage.setItem(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap);

    }

    const incrementAddOn = (branchProductId, branchAddOnId, operation, id) => {

        const _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[branchProductId];

        _posOrderMap[branchProductId] = {
            ...posOrderDetails,
            cartAddOnDetailsList: posOrderDetails.cartAddOnDetailsList.map(cartAddOnDetails => {

                if (operation === -1 && cartAddOnDetails.quantity === 1) {
                    return cartAddOnDetails;
                }

                if (cartAddOnDetails.branchAddOn.id === branchAddOnId) {
                    return {
                        ...cartAddOnDetails,
                        quantity: cartAddOnDetails.quantity + operation
                    }
                }
                return cartAddOnDetails;
            })
        }

        localStorage.setItem(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap, id);

    }

    const removeAddOn = (branchProductId, branchAddOnId, id) => {

        const _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[branchProductId];

        _posOrderMap[branchProductId] = {
            ...posOrderDetails,
            cartAddOnDetailsList: posOrderDetails.cartAddOnDetailsList
                .filter(cartAddOnDetails => cartAddOnDetails.branchAddOn.id !== branchAddOnId)
        }

        localStorage.setItem(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap, id);

    }

    const handleDialPadChange = (quantity, id) => {

        const _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[posOrderSelectedItemId];

        const _quantity = `${_posOrderMap[posOrderSelectedItemId].quantity}${quantity}`

        _posOrderMap[posOrderSelectedItemId] = {
            ...posOrderDetails,
            quantity: parseInt(_quantity)
        }

        localStorage.setItem(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap, id);

    }

    const handleDeleteDialPad = id => {

        const _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[posOrderSelectedItemId];

        let quantityParsedToString = posOrderDetails.quantity.toString();

        if (quantityParsedToString.length > 1) {

            const _quantity = quantityParsedToString.slice(0, quantityParsedToString.length - 1);

            _posOrderMap[posOrderSelectedItemId] = {
                ...posOrderDetails,
                quantity: parseInt(_quantity)
            }

            localStorage.setItem(POS_ORDER_CART, JSON.stringify(_posOrderMap));
            setPosOrderMap(_posOrderMap);

            getPosCartInvoiceCalculation(_posOrderMap, id);

        }

    }

    const clearDialPad = id => {

        const _posOrderMap = {...posOrderMap};
        const posOrderDetails = _posOrderMap[posOrderSelectedItemId];

        _posOrderMap[posOrderSelectedItemId] = {
            ...posOrderDetails,
            quantity: 1
        }

        localStorage.setItem(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        setPosOrderMap(_posOrderMap);

        getPosCartInvoiceCalculation(_posOrderMap, id);

    }

    const changePosCartDiscount = (discountValue, discountType) => {

        const _discountValue = getDiscountAmount(posCartInvoice.totalProductPrice, discountValue, discountType)

        const _subTotal = posCartInvoice.totalProductPrice - _discountValue;

        const taxPercentage = branchConfig?.taxPercentage ? branchConfig.taxPercentage : 0;
        const taxValue = percentValueCalculation(_subTotal, taxPercentage);

        const _posCartInvoice = {
            ...posCartInvoice,
            discount: {discountValue, discountType},
            subTotal: _subTotal,
            finalPrice: _subTotal + taxValue,
            tax: taxValue,
            readOnlyDiscount: _discountValue,
            returnAmount: posCartInvoice.customerPaid - (_subTotal + taxValue),
        }

        setPosCartInvoice(_posCartInvoice);
        localStorage.setItem(POS_ORDER_CART_INVOICE, JSON.stringify(_posCartInvoice));

    }

    const getPosCartInvoiceCalculation = (posOrderMap, id) => {

        let _totalAddOnPrice = 0;
        let _totalProductPrice = 0;
        let _totalPrice = 0;

        for (let key in posOrderMap) {

            _totalProductPrice += getPercentDiscountAmount(posOrderMap[key].branchProduct.price, posOrderMap[key].branchProduct.discountPercent) * posOrderMap[key].quantity;

            for (let i = 0; i < posOrderMap[key].cartAddOnDetailsList.length; i++) {
                _totalAddOnPrice += posOrderMap[key].cartAddOnDetailsList[i].quantity * posOrderMap[key].cartAddOnDetailsList[i].branchAddOn.price
            }

        }

        // _totalProductPrice = _totalAddOnPrice + _totalProductPrice;
        _totalPrice = _totalAddOnPrice + _totalProductPrice;

        let _discount = {...posCartInvoice.discount};
        let _readOnlyDiscount;

        if (id) {

            if (_totalPrice > posCartInvoice.originalCartInvoice.totalPrice) {
                // if (_totalProductPrice > posCartInvoice.originalCartInvoice.totalPrice) {

                _readOnlyDiscount = _totalPrice - posCartInvoice.originalCartInvoice.subTotal;
                // _readOnlyDiscount = _totalProductPrice - posCartInvoice.originalCartInvoice.subTotal;

                _discount = {
                    discountType: "FLAT",
                    discountValue: precisionFactor(_readOnlyDiscount)
                }

            } else {

                _readOnlyDiscount = posCartInvoice.originalCartInvoice.discountAmount;

                _discount = {
                    discountType: "FLAT",
                    discountValue: _readOnlyDiscount
                }

            }

        }

        _readOnlyDiscount = getDiscountAmount(_totalPrice, _discount.discountValue, _discount.discountType);
        // _readOnlyDiscount = getDiscountAmount(_totalProductPrice, _discount.discountValue, _discount.discountType);

        let taxPercentage = branchConfig?.taxPercentage ? branchConfig.taxPercentage : 0;

        let _subTotal = _totalPrice - _readOnlyDiscount;
        // let _subTotal = _totalProductPrice - _readOnlyDiscount;
        let taxValue = percentValueCalculation(_subTotal, taxPercentage);

        const _posCartInvoice = {
            ...posCartInvoice,
            discount: _discount,
            totalPrice: _totalPrice,
            totalProductPrice: _totalProductPrice,
            totalAddOnPrice: _totalAddOnPrice,
            subTotal: _subTotal,
            finalPrice: _subTotal + taxValue,
            tax: taxValue,
            readOnlyDiscount: _readOnlyDiscount,
            returnAmount: posCartInvoice.customerPaid - (_subTotal + taxValue),
        }

        setPosCartInvoice(_posCartInvoice);
        localStorage.setItem(POS_ORDER_CART_INVOICE, JSON.stringify(_posCartInvoice));

    }

    const handlePosOrderInfoChange = data => {

        return new Promise((resolve, reject) => {
            const _posOrderInfo = {...posOrderInfo, ...data};
            setPosOrderInfo(_posOrderInfo);
            localStorage.setItem(POS_ORDER_INFO, JSON.stringify(_posOrderInfo));
            resolve(); // Resolve the promise once the operation is done
        });

    }

    const clearPosOrderInfoCustomer = () => {

        const _posOrderInfo = {...posOrderInfo};

        delete _posOrderInfo?.fullName;
        delete _posOrderInfo?.countryCode;
        delete _posOrderInfo?.mobileNumber;
        delete _posOrderInfo?.email;
        delete _posOrderInfo?.addressList;
        delete _posOrderInfo?.address;

        setPosOrderInfo(_posOrderInfo);
        localStorage.setItem(POS_ORDER_INFO, JSON.stringify(_posOrderInfo));

    }

    const clearOrderInfoAddress = () => {

        const _posOrderInfo = {...posOrderInfo};

        delete _posOrderInfo?.addressList;
        delete _posOrderInfo?.address;

        setPosOrderInfo(_posOrderInfo);
        localStorage.setItem(POS_ORDER_INFO, JSON.stringify(_posOrderInfo));

    }

    const createBranchOrder = async () => {
        try {

            setCreateOrderLoading(true);

            const cartDetailsList = convertCartDetailsMap();

            const _paymentTypes = posOrderInfo.paymentTypes.filter(payment => payment.isSelected && payment.amount >= 0);

            let deliveryAddress = undefined;
            if (posOrderInfo.orderType === DELIVERY) {
                if (posOrderInfo?.addressList && posOrderInfo?.addressList?.length > 0) {

                    for (let i = 0; i < posOrderInfo.addressList.length; i++) {
                        if (posOrderInfo.addressList[i].selected) {
                            deliveryAddress = posOrderInfo.addressList[i];
                            break;
                        }
                    }

                } else {
                    deliveryAddress = posOrderInfo.address;
                }
            }

            const data = {
                cartDetailsList,
                payments: _paymentTypes.length <= 0 ? [{type: "CASH", amount: 0}] : _paymentTypes,
                countryCode: posOrderInfo?.countryCode,
                fullName: posOrderInfo?.fullName,
                mobileNumber: posOrderInfo?.mobileNumber,
                email: posOrderInfo?.email,
                guestCount: posOrderInfo.guestCount,
                specialNote: posOrderInfo?.specialNote,
                tableList: posOrderInfo?.tableList,
                orderType: posOrderInfo?.orderType,
                discount: posCartInvoice.discount,
                tips: posOrderInfo?.tips,
                // autoComplete: posOrderInfo?.autoComplete,
                autoComplete: localStorage.getItem(POS_COMPLETE_ORDER) ? JSON.parse(localStorage.getItem(POS_ORDER_INFO)).autoComplete : posOrderInfo?.autoComplete,
                deliveryAddress
            }

            const res = await BranchOrderService.createBranchOrder(data);

            Toast("success", "Created", "Order has been created successfully");

            clearPosOrderLocalStorage();

            setCreateOrderLoading(false);

            return res.data;

        } catch (error) {

            setCreateOrderLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return null;
        }
    }

    const updateBranchOrder = async id => {
        try {

            setCreateOrderLoading(true);

            const cartDetailsList = convertCartDetailsMap();

            const _paymentTypes = posOrderInfo.paymentTypes.filter(payment => payment.isSelected);

            let deliveryAddress = undefined;
            if (posOrderInfo.orderType === DELIVERY) {
                if (posOrderInfo?.addressList && posOrderInfo?.addressList?.length > 0) {

                    for (let i = 0; i < posOrderInfo.addressList.length; i++) {
                        if (posOrderInfo.addressList[i].selected) {
                            deliveryAddress = posOrderInfo.addressList[i];
                            break;
                        }
                    }

                } else {
                    deliveryAddress = posOrderInfo.address;
                }
            }

            delete posOrderInfo.paymentTypes;
            delete posOrderInfo?.addressList;
            delete posOrderInfo?.address;

            const data = {
                cartDetailsList,
                payments: _paymentTypes,
                ...posOrderInfo,
                deliveryAddress,
                discount: posCartInvoice.discount
            }

            const res = await BranchOrderService.updateBranchOrder(id, data);

            Toast("success", "Created", "Order has been updated successfully");

            if (res) {
                clearPosOrderLocalStorage();
            }

            setCreateOrderLoading(false);

            return res.data;

        } catch (error) {

            setCreateOrderLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return null;
        }
    }

    const convertCartDetailsMap = () => {

        let cartDetailsList = [];

        for (let key in posOrderMap) {
            cartDetailsList.push(posOrderMap[key]);
        }

        return cartDetailsList;
    }

    const addToHold = () => {

        const id = new Date().getMilliseconds();

        const data = {
            posOrderMap,
            posOrderInfo,
            posCartInvoice,
            id
        }

        const _posOrderHoldList = [...posOrderHoldList, data]

        setPosOrderHoldList(_posOrderHoldList);
        localStorage.setItem(POS_ORDER_HOLD_LIST, JSON.stringify(_posOrderHoldList));

        Toast("success", "Added", "Your cart has been added to hold successfully");

        clearPosOrderLocalStorage();

    }

    const clearPosOrderLocalStorage = () => {
        setPosOrderInfo(
            {
                orderType: "DINE_IN",
                paymentTypes: _paymentTypes
            }
        );
        setPosOrderMap(null);
        setPosCartInvoice(initPosCartInvoice);

        localStorage.removeItem(POS_ORDER_CART);
        localStorage.removeItem(POS_ORDER_INFO);
        localStorage.removeItem(POS_ORDER_CART_INVOICE);
    }

    const resumeHoldOrder = id => {

        const findPosHoldOrder = posOrderHoldList.find(posHoldOrder => posHoldOrder.id === id);

        setPosOrderMap(findPosHoldOrder.posOrderMap);
        setPosCartInvoice(findPosHoldOrder.posCartInvoice);
        setPosOrderInfo(findPosHoldOrder.posOrderInfo);

        localStorage.setItem(POS_ORDER_CART, JSON.stringify(findPosHoldOrder.posOrderMap));
        localStorage.setItem(POS_ORDER_CART_INVOICE, JSON.stringify(findPosHoldOrder.posCartInvoice));
        localStorage.setItem(POS_ORDER_INFO, JSON.stringify(findPosHoldOrder.posOrderInfo));

        let _posHoldOrderList = posOrderHoldList.filter(posHoldOrder => posHoldOrder.id !== id);

        if (posOrderMap) {

            const id = new Date().getMilliseconds();

            const data = {
                posOrderMap,
                posOrderInfo,
                posCartInvoice,
                id
            }

            _posHoldOrderList = [..._posHoldOrderList, data]
        }

        setPosOrderHoldList(_posHoldOrderList);
        localStorage.setItem(POS_ORDER_HOLD_LIST, JSON.stringify(_posHoldOrderList));

    }

    const removeHoldOrder = id => {

        const filteredHoldList = posOrderHoldList.filter(posHoldOrder => posHoldOrder.id !== id);

        setPosOrderHoldList(filteredHoldList);
        localStorage.setItem(POS_ORDER_HOLD_LIST, JSON.stringify(filteredHoldList));

        Toast("success", "Removed", "Hold order has been removed successfully");

    }

    const editPosOrder = async data => {

        const _posOrderMap = {};

        let addressList = [];
        if (data?.customer && data.orderType === DELIVERY) {
            const res = await CustomerService.getAllAddressByCustomer(data.customer.id);

            addressList = res.data.content.map(address => {
                if (address.id === data.deliveryAddress.id) {
                    return {
                        ...address,
                        selected: true
                    }
                } else {
                    return {...address, selected: false}
                }
            })
        }

        data.cartDetailsList.forEach(cartDetails => {
            _posOrderMap[cartDetails.branchProduct.id] = cartDetails;
        });

        const subTotal = data.invoice.totalPrice - data.invoice.discountAmount;

        const _posCartInvoice = {
            ...posCartInvoice,
            originalCartInvoice: {
                totalPrice: data.invoice.totalPrice,
                subTotal,
                discountAmount: data.invoice.discountAmount
            },
            totalProductPrice: data.invoice.totalPrice,
            discount: {
                discountValue: data.invoice.discount.discountValue,
                discountType: data.invoice.discount.discountType ? data.invoice.discount.discountType : "FLAT"
            },
            subTotal,
            finalPrice: data.invoice.finalPrice,
            tax: data.invoice.taxAmount,
            readOnlyDiscount: data.invoice.discountAmount,
        }

        const paymentTypes = _paymentTypes.map(paymentType => {

            const find = data.payments.find(resPayment => {
                return resPayment.type === paymentType.type
            });

            if (find) {
                return {
                    ...paymentType,
                    isSelected: true,
                    amount: find.amount
                }
            } else {
                return paymentType
            }
        })

        const _orderInfo = {
            orderType: data.orderType,
            paymentTypes,
            fullName: data.fullName,
            guestCount: data.guestCount,
            mobileNumber: data.mobileNumber,
            email: data?.email,
            specialNote: data.specialNote,
            countryCode: data.countryCode,
            tips: data.tips,
            tableList: data.tableList,
            address: data?.deliveryAddress,
            addressList
        }

        setPosOrderMap(_posOrderMap);
        setPosCartInvoice(_posCartInvoice);
        setPosOrderInfo(_orderInfo);

        localStorage.setItem(POS_ORDER_CART, JSON.stringify(_posOrderMap));
        localStorage.setItem(POS_ORDER_CART_INVOICE, JSON.stringify(_posCartInvoice));
        localStorage.setItem(POS_ORDER_INFO, JSON.stringify(_orderInfo));

    }

    const clearPosOrderCart = () => {

        clearPosOrderLocalStorage();

    }

    // this is for customer return amount calculation
    const handlePaidAmountChange = value => {

        const _posCartInvoice = {
            ...posCartInvoice,
            customerPaid: value,
            returnAmount: value ? value - posCartInvoice?.finalPrice : 0
        }

        setPosCartInvoice(_posCartInvoice);
        localStorage.setItem(POS_ORDER_CART_INVOICE, JSON.stringify(_posCartInvoice));

    }

    return (
        <PosOrderContext.Provider
            value={{
                posOrderMap,
                addPosOrder,
                removeItem,
                incrementHandle,
                addPosCartAddOn,
                incrementAddOn,
                removeAddOn,
                handleDialPadChange,
                posOrderSelectedItemId,
                setPosOrderSelectedItemId,
                handleDeleteDialPad,
                clearDialPad,
                posCartInvoice,
                changePosCartDiscount,
                handlePosOrderInfoChange,
                posOrderInfo,
                createOrderLoading,
                createBranchOrder,
                addToHold,
                posOrderHoldList,
                resumeHoldOrder,
                removeHoldOrder,
                editPosOrder,
                clearPosOrderCart,
                updateBranchOrder,
                clearPosOrderInfoCustomer,
                posOrderProductMultiplyCount,
                setPosOrderProductMultiplyCount,
                clearOrderInfoAddress,
                handlePaidAmountChange
            }}
        >
            {children}
        </PosOrderContext.Provider>
    );
}

export default PosOrderContextProvider;
