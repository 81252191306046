import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";
import {getBranchId} from "../helpers/Utils";

export default class BranchService {

    static createBranch = data => privateAPI.post(
        `${URL.CREATE_BRANCH}`, data
    );

    static getAllBranch = params => privateAPI.get(
        `${URL.GET_ALL_BRANCH}`, {params}
    );

    static getBranchById = branchId => privateAPI.get(
        `${URL.GET_BRANCH_BY_ID}/${branchId}`
    );

    static getAllBranchEmployee = () => privateAPI.get(
        `${URL.GET_ALL_BRANCH_EMPLOYEE}/${getBranchId()}`
    );

    static updateBranch = (branchId, data) => privateAPI.put(
        `${URL.UPDATE_BRANCH}/${branchId}`, data
    );

    static assignEmployee = (employeeId, branchId) => privateAPI.put(
        `${URL.ASSIGN_EMPLOYEE}/${employeeId}/${branchId}`, null
    );

    static generateQRCode = branchId => privateAPI.get(
        `${URL.GENERATE_QR_CODE_URL}/${branchId}`, {responseType: "blob"}
    );

}
