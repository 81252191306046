import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";
import publicAPI from "../rest_handlers/publicAPI";

export default class GlobalConfigService {

    static createGlobalConfig = data => privateAPI.post(
        `${URL.CREATE_GLOBAL_CONFIG_URL}`, data
    );

    static getAllGlobalConfig = params => privateAPI.get(
        `${URL.GET_ALL_GLOBAL_CONFIG_URL}`, {params}
    );

    static getGlobalConfigById = id => privateAPI.get(
        `${URL.GET_GLOBAL_CONFIG_BY_ID_URL}/${id}`
    );

    static getActiveGlobalConfig = () => privateAPI.get(
        `${URL.GET_ACTIVE_GLOBAL_CONFIG_URL}`
    );

    static getPublicGlobalConfig = () => publicAPI.get(
        `${URL.GET_PUBLIC_ACTIVE_GLOBAL_CONFIG_URL}`
    );

    static updateGlobalConfig = (id, data) => privateAPI.put(
        `${URL.UPDATE_GLOBAL_CONFIG_URL}/${id}`, data
    );

}
