import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import BranchOrderService from "../../services/order/BranchOrderService";

export const BranchOrderContext = createContext("BranchOrderContext");

const BranchOrderContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [branchOrderList, setBranchOrderList] = useState([]);

    const [orderHistoryLoading, setOrderHistoryLoading] = useState(null);
    const [orderHistoryList, setOrderHistoryList] = useState([]);

    const [branchOrderCountByStatus, setBranchOrderCountByStatus] = useState(null);
    const [branchOrderCountLoading, setBranchOrderCountLoading] = useState(false);

    const getAllBranchOrder = async params => {
        try {

            setLoading(true);

            const res = await BranchOrderService.getAllBranchOrder(params);
            setBranchOrderList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getBranchOrderCountByStatus = async () => {
        try {

            setBranchOrderCountLoading(true);

            const res = await BranchOrderService.getBranchOrderCountByStatus();
            setBranchOrderCountByStatus(res.data);

            setBranchOrderCountLoading(false);

        } catch (error) {

            setBranchOrderCountLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getBranchOrderHistory = async orderId => {
        try {

            setOrderHistoryLoading(false)

            const res = await BranchOrderService.getBranchOrderHistory(orderId);
            setOrderHistoryList(res.data);

            setOrderHistoryLoading(false);

        } catch (error) {

            setOrderHistoryLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <BranchOrderContext.Provider
            value={{
                loading,
                orderHistoryLoading,
                totalElements,
                branchOrderList,
                orderHistoryList,
                branchOrderCountByStatus,
                branchOrderCountLoading,
                getAllBranchOrder,
                getBranchOrderHistory,
                getBranchOrderCountByStatus,
            }}
        >
            {children}
        </BranchOrderContext.Provider>
    );
}

export default BranchOrderContextProvider;
