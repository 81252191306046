import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";
import {getBranchId} from "../../helpers/Utils";

export default class CategoryService {

    static createCategory = data => privateAPI.post(
        `${URL.CREATE_PRODUCT_CATEGORY}`, data
    );

    static getAllCategory = params => privateAPI.get(
        `${URL.GET_ALL_PRODUCT_CATEGORY}`, {params}
    );

    // for branch
    static getAllCategoryForBranch = params => privateAPI.get(
        `${URL.GET_ALL_PRODUCT_CATEGORY_FOR_BRANCH}/${getBranchId()}`, {params}
    );

    static getCategoryById = categoryId => privateAPI.get(
        `${URL.GET_PRODUCT_CATEGORY_BY_ID}/${categoryId}`
    );

    static updateCategory = (categoryId, data) => privateAPI.put(
        `${URL.UPDATE_PRODUCT_CATEGORY}/${categoryId}`, data
    );

}
