import {Locales} from "../Locales";

export const bn_BD = {
    [Locales.bengali]: {
        // common
        "common.error": "ত্রুটি",
        "common.created": "তৈরি হয়েছে",
        "common.added": "যোগ করা হয়েছে",
        "common.updated": "আপডেট করা হয়েছে",
        "common.submit": "জমা দিন",
        "common.email": "ইমেইল",
        "common.password": "পাসওয়ার্ড",
        "common.all": "সব",
        "common.action": "অ্যাকশন",
        "common.reset": "রিসেট",
        "common.search_list": "সার্চ তালিকা",
        "common.select_language": "ভাষা নির্বাচন করুন",
        "common.language_required": "অনুগ্রহ করে একটি ভাষা নির্বাচন করুন",
        "common.image": "ছবি",
        "common.address": "ঠিকানা",
        "common.city": "শহর",
        "common.country": "দেশ",
        "common.zipcode": "জিপ কোড",
        "common.assigned": "নির্ধারিত",
        "common.yes": "হ্যাঁ",
        "common.no": "না",
        "common.approved": "এপ্রুভড",
        "common.approve": "এপ্রুভ",
        "common.approve_small": "অনুমোদন",
        "common.disable": "ডিসএবল হয়েছে",
        "common.disable_small": "ডিসএবল",
        "common.password_changed": "পাসওয়ার্ড পরিবর্তন হয়েছে",
        "common.confirm_msg": "আপনি কি নিশ্চিত যে আপনি চান",
        "common.the_order": "অর্ডার",
        "common.the_product": "পণ্য",
        "common.the_promo": "প্রচার",
        "common.the_banner": "ব্যানার",
        "common.the_add_ons": "অ্যাড-অন",
        "common.success_msg_has": "হয়েছে",
        "common.success": "সফলভাবে",
        "common.activated": "অ্যাক্টিভেটেড",
        "common.mile": "মাইল",
        "common.create": "তৈরি করুন",
        "common.update": "আপডেট করুন",
        "common.optional": "অপশনাল",
        "common.add": "যোগ করুন",
        "common.created_at": "তৈরি করা হয়েছে",
        "common.created_date": "তারিখ তৈরি করা হয়েছে",
        "common.cancelled": "ক্যানসেল",
        "common.download": "ডাউনলোড",
        "common.date": "তারিখ",
        "common.items": "আইটেম",
        "common.assign_to_branch": "আপনার শাখায় এসাইন করুন",
        "common.edit": "এডিট",
        "common.details": "ডিটেলস",

        // nav header
        "nav_header.profile": "প্রোফাইল",
        "nav_header.logout": "লগ আউট",
        "nav_header.english": "ইংরেজি",
        "nav_header.bengali": "বাংলা",
        "nav_header.urdu": "উর্দু",
        "nav_header.hindi": "হিন্দি",
        "nav_header.pashto": "পশ্তু",

        // aside left
        "aside_left.dashboard": "ড্যাশবোর্ড",
        "aside_left.user_management": "ইউসার ম্যানেজমেন্ট",
        "aside_left.user_list": "ইউসার তালিকা",
        "aside_left.product_management": "পণ্য ম্যানেজমেন্ট",
        "aside_left.product": "পণ্য",
        "aside_left.product_category": "ক্যাটেগরি",
        "aside_left.product_add_ons": "অ্যাড-অন",
        "aside_left.branch": "ব্রাঞ্চ",
        "aside_left.branch_list": "ব্রাঞ্চ তালিকা",
        "aside_left.orders": "অর্ডার",
        "aside_left.roles": "রোল",
        "aside_left.assign_pashto": "এসাইন কর্মচারী",
        "aside_left.requested_product": "রিকোয়েস্টেড পণ্য",
        "aside_left.requested_add_ons_list": "রিকোয়েস্টেড অ্যাড-অন",
        "aside_left.branch_product": "ব্রাঞ্চ পণ্য",
        "aside_left.branch_add_ons": "ব্রাঞ্চ অ্যাড-অন",
        "aside_left.promo": "প্রমো",
        "aside_left.global": "গ্লোবাল",
        "aside_left.employee_list": "কর্মচারী তালিকা",
        "aside_left.customer_list": "গ্রাহকের তালিকা",
        "aside_left.global_config": "গ্লোবাল কনফিগ",
        "aside_left.customer": "গ্রাহক",
        "aside_left.banner": "ব্যানার",
        "aside_left.analytics": "বিশ্লেষণ",
        "aside_left.catering": "ক্যাটারিং",
        "aside_left.catering_products": "কেটারিং পণ্য",
        "aside_left.catering_sections": "কেটারিং ক্যাটেগরি",
        "aside_left.catering_menu": "কেটারিং মেনু",
        "aside_left.catering_order": "কেটারিং অর্ডার",
        "aside_left.kitchen_display": "কিচেন ডিসপ্লে",
        "aside_left.pos": "পিওএস",
        "aside_left.ingredient_management": "ইনগ্রেডিয়েন্ট ম্যানেজমেন্ট",
        "aside_left.ingredient_category": "ইনগ্রেডিয়েন্ট ক্যাটেগরি",
        "aside_left.ingredient": "ইনগ্রেডিয়েন্ট",
        "aside_left.requested_ingredient": "রিকোয়েস্টেড ইনগ্রেডিয়েন্ট",
        "aside_left.supplier": "সাপ্লায়ার",
        "aside_left.stock_management": "স্টক ম্যানেজমেন্ট",
        "aside_left.stock": "স্টক",
        "aside_left.stock_in": "স্টক ইন",
        "aside_left.stock_out": "স্টক আউট",
        "aside_left.expense": "ব্যয়",
        "aside_left.agent": "এজেন্ট",
        "aside_left.agent_management": "এজেন্ট ম্যানেজমেন্ট",
        "aside_left.agent_payment": "এজেন্ট পেমেন্ট",
        "aside_left.branch_config": "ব্রাঞ্চ কনফিগ",
        "aside_left.stock_summary": "স্টক সামারি",
        "aside_left.expired_stock": "মেয়াদ উত্তীর্ণ স্টক",
        "aside_left.table": "টেবিল",
        "aside_left.requested_products": "রিকোয়েস্টেড পণ্য",
        "aside_left.catering_product": "কেটারিং পণ্য",
        "aside_left.order": "অর্ডার",
        "aside_left.requested_menu": "রিকোয়েস্টেড মেনু",
        "aside_left.franchises": "ফ্র্যাঞ্চাইজিস",
        "aside_left.order_management": "অর্ডার ব্যবস্থাপনা",
        "aside_left.order_reports": "অর্ডার রিপোর্ট",
        "aside_left.order_item_reports": "অর্ডার আইটেম রিপোর্ট",
        "aside_left.order_history": "অর্ডার ইতিহাস",

        // owner login
        "login.email_required_msg": "ইমেল প্রয়োজন",
        "login.valid_email_msg": "দয়া করে একটি ভ্যালিড ইমেল লিখুন",
        "login.password_required_msg": "পাসওয়ার্ড প্রয়োজন",
        "login.sign_in": "সাইন ইন",
        "login.sign_in_msg": "সাইন ইন করতে আপনার ইমেল এবং পাসওয়ার্ড লিখুন!",

        // user
        "user.total_employee": "মোট কর্মচারী",
        "user.create_user": "ব্যবহারকারী তৈরি করুন",
        "user.update_user": "ব্যবহারকারী আপডেট করুন",
        "user.full_name": "পুরো নাম",
        "user.create_success_msg": "ব্যবহারকারী সফলভাবে তৈরি হয়েছে",
        "user.update_success_msg": "ব্যবহারকারী সফলভাবে আপডেট হয়েছে",
        "user.user_data_not_available": "ব্যবহারকারীর তথ্য পাওয়া যায়নি",
        "user.profile_picture": "প্রোফাইল ছবি",
        "user.phone_number": "ফোন নম্বর",
        "user.date_of_birth": "জন্ম তারিখ",
        "user.gender": "লিঙ্গ",
        "user.active": "এক্টিভ",
        "user.disabled": "ডিসএবল",
        "user.banned": "ব্যানড",
        "user.verified": "ভেরিফাইড",
        "user.email_verified": "ইমেল ভেরিফাইড",
        "user.phone_verified": "ফোন ভেরিফাইড",
        "user.profile": "প্রোফাইল",
        "user.profile_update_msg": "প্রোফাইল সফলভাবে আপডেট হয়েছে",
        "user.changed_password_msg": "পাসওয়ার্ড সফলভাবে পরিবর্তিত হয়েছে",
        "user.create_employee": "কর্মচারী তৈরি করুন",
        "user.update_employee": "কর্মচারী আপডেট করুন",
        "user.select_role": "রোল নির্বাচন করুন",

        // user form
        "user_form.full_name_required": "পুরো নাম প্রয়োজন",
        "user_form.email_required": "ইমেল প্রয়োজন",
        "user_form.male": "পুরুষ",
        "user_form.female": "মহিলা",
        "user_form.others": "অন্যান্য",
        "user_form.role_required": "রোল প্রয়োজন",
        "user_form.phone_number_required": "ফোন নম্বর প্রয়োজন",
        "user_form.invalid_email": "ইনভ্যালিড ইমেল",
        "user_form.hotline": "হটলাইন",
        "user_form.hotline_required": "হটলাইন প্রয়োজন",

        // customer
        "customer.total_customer": "মোট গ্রাহক",
        "customer.customer_list": "গ্রাহক তালিকা",
        "customer.customer_name": "গ্রাহকের নাম",
        "customer.customer_create_msg": "গ্রাহক সফলভাবে তৈরি হয়েছে",
        "customer.invalid_phone": "ইনভ্যালিড ফোন নম্বর",
        "customer.customer_name_required": "গ্রাহকের নাম প্রয়োজন",
        "customer.cancel": "ক্যানসেল",
        "customer.save": "সংরক্ষণ",
        "customer.customer_phone": "গ্রাহকের ফোন",

        // role
        "role.role_list": "রোল তালিকা",
        "role.roles": "রোল",
        "role.create_role": "রোল তৈরি করুন",
        "role.update_role": "রোল আপডেট করুন",
        "role.create_success_msg": "রোল সফলভাবে তৈরি হয়েছে",
        "role.update_success_msg": "রোল সফলভাবে আপডেট হয়েছে",
        "role.role_data_not_available": "রোল তথ্য পাওয়া যায়নি",
        "role.alias": "এলিয়াস",
        "role.role_level": "রোল লেভেল",
        "role.select_role": "রোল নির্বাচন করুন",

        // role form
        "role_form.predefine_role_msg": "এটি প্রিডিফাইনে রোল, তাই আপনি এলিয়াস এডিট করতে পারবেন না",
        "role_form.alias_required_msg": "এলিয়াস প্রয়োজন",
        "role_form.all": "সব",
        "role_form.system": "সিস্টেম",
        "role_form.restaurant": "রেস্টুরেন্ট",
        "role_form.branch": "ব্রাঞ্চ",
        "role_form.customer": "কাস্টমার",
        "role_form.role_level_required": "রোল লেভেল প্রয়োজন",
        // Product
        "product.product_list": "পণ্যের তালিকা",
        "product.previous_product_list": "পূর্ববর্তী অর্ডার পণ্যের তালিকা",
        "product.branch_product_list": "ব্রাঞ্চ পণ্যের তালিকা",
        "product.create_product": "পণ্য তৈরি করুন",
        "product.name": "পণ্যের নাম",
        "product.create_success_msg": "পণ্যটি সফলভাবে তৈরি হয়েছে",
        "product.update_success_msg": "পণ্যটি সফলভাবে আপডেট হয়েছে",
        "product.update_product": "পণ্য আপডেট করুন",
        "product.product_not_available": "পণ্যের তথ্য পাওয়া যায়নি",
        "product.type": "পণ্যের টাইপ",
        "product.select_type": "পণ্যের টাইপ নির্বাচন করুন",
        "product.category": "পণ্যের ক্যাটেগরি",
        "product.price": "মূল্য",
        "product.description": "বিবরণ",
        "product.single_type": "সিঙ্গেল",
        "product.combo_type": "কম্বো",
        "product.select_product": "পণ্য নির্বাচন করুন",
        "product.enable": "এনএইবল",
        "product.quantity": "পরিমাণ",
        "product.discount_percent": "ডিসকাউন্ট পার্সেন্ট",
        "product.discount_percent_negative": "ডিসকাউন্ট পার্সেন্ট অবশ্যই একটি পসিটিভ সংখ্যা হতে হবে",
        "product.approved_msg": "পণ্যটি সফলভাবে এপ্রুভড হয়েছে",
        "product.approved_status_msg": "পণ্যটি",
        "product.sold_unit": "বিক্রিত ইউনিট",
        "product.combo_items": "কম্বো আইটেমস",
        "product.popular": "জনপ্রিয়",
        "product.change_popular_status": "জনপ্রিয়তা স্টেটাস পরিবর্তন করুন",
        "product.popular_status_sure": "আপনি কি নিশ্চিত যে আপনি জনপ্রিয়তা স্টেটাস পরিবর্তন করতে চান?",
        "product.related_products": "রিলেটেড পণ্য",
        "product.shortcut_key": "শর্টকাট কী",
        "product.product_small": "পণ্য",
        // Requested Product
        "requested_product.requested_product_list": "রিকোয়েস্টেড পণ্যের তালিকা",

        // Branch Request Product
        "branch_request_product.assign_success_msg": "পণ্যটি সফলভাবে অ্যাসাইনড করা হয়েছে",
        "branch_request_product.request_product": "রিকোয়েস্ট পণ্য",
        "branch_request_product.create_product": "রিকোয়েস্ট পণ্য তৈরি করুন",
        "branch_request_product.update_product": "রিকোয়েস্ট পণ্য আপডেট করুন",
        "branch_request_product.request_success_msg": "পণ্যের রিকোয়েস্ট সফলভাবে তৈরি হয়েছে",
        "branch_request_product.request_update_success_msg": "পণ্যের রিকোয়েস্ট সফলভাবে আপডেট হয়েছে",

        // Product Form
        "product_form.name_required": "পণ্যের নাম প্রয়োজন",
        "product_form.type_required": "পণ্যের টাইপ প্রয়োজন",
        "product_form.category_required": "পণ্যের ক্যাটেগরি প্রয়োজন",
        "product_form.price_required": "পণ্যের মূল্য প্রয়োজন",
        "product_form.duplicate_product": "আপনি ইতিমধ্যে এই পণ্যটি যোগ করেছেন",
        "product_form.negative_price": "মূল্য শূন্যের চেয়ে বড় হতে হবে",
        "product_form.product_sale_threshold": "সর্বাধিক বিক্রয় পরিমাণ",
        "product_form.product_sale_threshold_negative": "সর্বাধিক বিক্রয় পরিমাণ অবশ্যই একটি পসিটিভ সংখ্যা হতে হবে",

        // Product Category
        "product_category.total_category": "মোট ক্যাটেগরি",
        "product_category.category_list": "ক্যাটেগরির তালিকা",
        "product_category.create_category": "ক্যাটেগরি তৈরি করুন",
        "product_category.update_category": "ক্যাটেগরি আপডেট করুন",
        "product_category.create_success_msg": "ক্যাটেগরি সফলভাবে তৈরি হয়েছে",
        "product_category.update_success_msg": "ক্যাটেগরি সফলভাবে আপডেট হয়েছে",
        "product_category.category_data_not_available": "ক্যাটেগরির ডেটা পাওয়া যায়নি",
        "product_category.category_name": "ক্যাটেগরির নাম",
        "product_category.available_from": "এভেইলেবল থেকে",
        "product_category.available_to": "এভেইলেবল থেকে",
        "product_category.available_days": "এভেইলেবল দিন",
        "product_category.available_week_days": "এভেইলেবল সপ্তাহের দিনগুলি",

        // Global Ingredient Category
        "ingredient_category.ingredient_category_list": "ইন্ডগ্রেডিয়েন্টের ক্যাটেগরি তালিকা",
        "ingredient_category.create_ingredient_category": "ইনগ্রেডিয়েন্ট ক্যাটেগরি তৈরি করুন",
        "ingredient_category.total_ingredient_category": "মোট ইন্ডগ্রেডিয়েন্টের ক্যাটেগরি",
        "ingredient_category.ingredient_category_name": "ইন্ডগ্রেডিয়েন্টের ক্যাটেগরির নাম",
        "ingredient_category.description": "বিবরণ",

        // Ingredient
        "ingredient.ingredient_list": "ইন্ডগ্রেডিয়েন্টের তালিকা",
        "ingredient.create_ingredient": "ইনগ্রেডিয়েন্ট তৈরি করুন",
        "ingredient.total_ingredient": "মোট ব্রাঞ্চের ইনগ্রেডিয়েন্ট",
        "ingredient.ingredient_name": "ইন্ডগ্রেডিয়েন্টের নাম",
        "ingredient.description": "বিবরণ",
        "ingredient.category_name": "ক্যাটেগরির নাম",
        "ingredient.unit": "ইউনিট",
        "ingredient.quantity": "পরিমাণ",
        "ingredient.default_price": "ডিফল্ট মূল্য",
        "ingredient.default_price_positive": "ডিফল্ট মূল্য অবশ্যই একটি পসিটিভ সংখ্যা হতে হবে",
        "ingredient.required_ingredient_name": "ইন্ডগ্রেডিয়েন্টের নাম প্রয়োজন",
        "ingredient.required_unit": "ইউনিট প্রয়োজন",
        "ingredient.update_ingredient": "ইনগ্রেডিয়েন্ট আপডেট করুন",
        "ingredient.approved_msg": "উপাদানটি সফলভাবে এপ্রুভড হয়েছে",
        "ingredient.unApproved_msg": "উপাদানটি সফলভাবে আনএপ্রুভড হয়েছে",
        "ingredient.search_by_name": "ইন্ডগ্রেডিয়েন্টের নামে অনুসন্ধান করুন",
        "ingredient.select_unit": "ইউনিট নির্বাচন করুন",
        "ingredient.price_positive": "মূল্যটি অবশ্যই একটি পসিটিভ সংখ্যা হতে হবে",

        // Requested Ingredient
        "requested_ingredient.requested_ingredient_list": "রিকোয়েস্টেড ইন্ডগ্রেডিয়েন্টের তালিকা",
        "requested_ingredient.total_requested_ingredient": "মোট রিকোয়েস্টেড ইনগ্রেডিয়েন্ট",
        "requested_ingredient.requested_ingredient_name": "রিকোয়েস্টেড ইন্ডগ্রেডিয়েন্টের নাম",
        "requested_ingredient.update_requested_ingredient": "আপডেট রিকোয়েস্টেড ইনগ্রেডিয়েন্ট",
        "requested_ingredient.create_success_msg": "ইনগ্রেডিয়েন্ট সফলভাবে তৈরি হয়েছে",

        // Branch Global Ingredient Category
        "branch_ingredient.create_branch_ingredient": "ব্রাঞ্চ ইনগ্রেডিয়েন্ট তৈরি করুন",
        "branch_ingredient.branch_ingredient_list": "ব্রাঞ্চ ইন্ডগ্রেডিয়েন্টের তালিকা",
        "branch_ingredient.total_branch_ingredient": "মোট ব্রাঞ্চ ইনগ্রেডিয়েন্ট",
        "branch_ingredient.branch_ingredient_name": "ব্রাঞ্চ ইন্ডগ্রেডিয়েন্টের নাম",
        "branch_ingredient.request_branch_ingredient": "রিকোয়েস্ট ব্রাঞ্চ ইনগ্রেডিয়েন্ট",
        "branch_ingredient.create_request_branch_ingredient": "রিকোয়েস্ট ব্রাঞ্চ ইনগ্রেডিয়েন্ট",
        "branch_ingredient.update_branch_ingredient": "আপডেট ব্রাঞ্চ ইনগ্রেডিয়েন্ট",
        "branch_ingredient.update_ingredient": "ইনগ্রেডিয়েন্ট আপডেট করুন",
        "branch_ingredient.quantity": "পরিমাণ",
        "branch_ingredient.assign_supplier": "সাপ্লায়ার নির্ধারণ করুন",
        "branch_ingredient.update_msg": "ব্রাঞ্চ উপাদানটি সফলভাবে আপডেট হয়েছে",
        "branch_request_ingredient.assign_success_msg": "ব্রাঞ্চ ইনগ্রেডিয়েন্ট সফলভাবে অ্যাসাইনড করা হয়েছে",

        // Supplier
        "supplier.supplier_list": "সাপ্লায়ার তালিকা",
        "supplier.create_supplier": "সাপ্লায়ার তৈরি করুন",
        "supplier.update_supplier": "সাপ্লায়ার আপডেট করুন",
        "supplier.total_supplier": "মোট ",
        "supplier.supplier_name": "সাপ্লায়ারের নাম",
        "supplier.description": "বিবরণ",
        "supplier.update_successfully": "সাপ্লায়ার সফলভাবে আপডেট হয়েছে",
        "supplier.supplier_name_required": "সাপ্লায়ারের নাম প্রয়োজন",
        "supplier.supplier_data_unavailable": "সাপ্লায়ারের তথ্য পাওয়া যায়নি",
        "supplier.supplier_assigned_msg": "সাপ্লায়ার সফলভাবে এপ্রুভড হয়েছে",

        // Agent
        "agent.agent_list": "এজেন্টের তালিকা",
        "agent.create_agent": "এজেন্ট তৈরি করুন",
        "agent.update_agent": "এজেন্ট আপডেট করুন",
        "agent.add_agent": "এজেন্ট যোগ করুন",
        "agent.total_agent": "মোট এজেন্ট",
        "agent.agent_name": "এজেন্টের নাম",
        "agent.name": "নাম",
        "agent.email": "ইমেল",
        "agent.mobile_number": "মোবাইল নম্বর",
        "agent.description": "বিবরণ",
        "agent.address": "ঠিকানা",
        "agent.agent_create_msg": "এজেন্ট সফলভাবে তৈরি হয়েছে",
        "agent.advance": "অগ্রীম",
        "agent.payments": "পেমেন্ট",

        // Agent Form
        "agent_form.name_required": "এজেন্টের নাম প্রয়োজন",
        "agent_form.mobile_num_required": "মোবাইল নম্বর প্রয়োজন",
        "agent_form.address_required": "ঠিকানা প্রয়োজন",
        "agent.agent_update_msg": "এজেন্ট সফলভাবে আপডেট হয়েছে",

        // Agent Payment
        "agent_payment.cancel_msg": "এজেন্টের পেমেন্ট সফলভাবে ক্যানসেল করা হয়েছে",
        "agent_payment.payment_category": "পেমেন্ট ক্যাটেগরি",
        "agent_payment.receive_amount": "গ্রহণ পরিমাণ",
        "agent_payment.cancel_payment": "এজেন্টের পেমেন্ট ক্যানসেল করুন",
        "agent_payment.cancel_confirm_msg": "আপনি কি নিশ্চিত যে আপনি এজেন্টের পেমেন্ট ক্যানসেল করতে চান",

        // Agent Payment Form
        "agent_payment_form.amount": "পরিমাণ",
        "agent_payment_form.amount_required": "পরিমাণ প্রয়োজন",
        "agent_payment_form.amount_positive": "পরিমাণটি 0 এর বেশি হতে হবে",
        "agent_payment_form.payment_category_required": "পেমেন্ট ক্যাটেগরি প্রয়োজন",
        "agent_payment_form.received": "গ্রহণ করা",
        "agent_payment_form.payment_type_required": "পেমেন্ট টাইপ প্রয়োজন",
        "agent_payment_form.cash": "নগদ",
        "agent_payment_form.mfs": "মোবাইল ফিন্যান্স সার্ভিস",
        "agent_payment_form.payment_create_msg": "এজেন্টের পেমেন্ট সফলভাবে তৈরি হয়েছে",
        "agent_payment_form.payment_update_msg": "এজেন্টের পেমেন্ট সফলভাবে আপডেট হয়েছে",
        "agent_payment_form.payment_unavailable": "পেমেন্ট ডেটা পাওয়া যায় নি",

        // Branch Supplier
        "supplier.assign_ingredient": "ইনগ্রেডিয়েন্ট নির্ধারণ করুন",
        "supplier.assign": "নির্ধারণ করুন",
        "supplier.ingredient_required": "ইনগ্রেডিয়েন্ট প্রয়োজন",
        "supplier.supplier_created_successfully": "সাপ্লায়ার সফলভাবে তৈরি হয়েছে",

        // Product Category Form
        "product_category_form.required_category_name": "ক্যাটেগরির নাম প্রয়োজন",

        // Add-ons
        "product_add_ons.total_add_on": "মোট অ্যাড-অন",
        "product_add_ons.add_ons_list": "অ্যাড-অন তালিকা",
        "product_add_ons.update_add_ons": "অ্যাড-অন আপডেট করুন",
        "product_add_ons.branch_add_ons_list": "ব্রাঞ্চ অ্যাড-অন তালিকা",
        "product_add_ons.create_add_ons": "অ্যাড-অন তৈরি করুন",
        "product_add_ons.create_success_msg": "অ্যাড-অন সফলভাবে তৈরি হয়েছে",
        "product_add_ons.update_success_msg": "অ্যাড-অন সফলভাবে আপডেট হয়েছে",
        "product_add_ons.add_ons_not_available": "অ্যাড-অন ডেটা পাওয়া যায় নি",
        "product_add_ons.add_ons_name": "অ্যাড-অন নাম",
        "product_add_ons.select_add_ons": "অ্যাড-অন নির্বাচন করুন",
        "product_add_ons.duplicate_add_ons": "এই অ্যাড-অন ইতিমধ্যে নির্বাচিত",
        "product_add_ons.approve_msg": "অ্যাড-অন সফলভাবে এপ্রুভড হয়েছে",
        "product_add_ons.approve_status_msg": "অ্যাড-অন অনুমোদনের স্থিতি সফলভাবে আপডেট হয়েছে",

        // Add-ons Form
        "product_add_ons_form.add_ons_name_required": "অ্যাড-অন নাম প্রয়োজন",
        "product_add_ons_form.add_ons_price_negative": "অ্যাড-অন মূল্য অবশ্যই একটি পসিটিভ সংখ্যা হতে হবে",

        // Request Add-ons
        "request_aad_ons.request_add_ons": "অ্যাড-অন রিকোয়েস্ট করুন",
        "request_aad_ons.create_success_msg": "অ্যাড-অন রিকোয়েস্ট সফলভাবে তৈরি হয়েছে",
        "request_aad_ons.update_success_msg": "অ্যাড-অন রিকোয়েস্ট সফলভাবে আপডেট হয়েছে",
        "request_aad_ons.create_request_add_ons": "অ্যাড-অন রিকোয়েস্ট তৈরি করুন",
        "request_aad_ons.update_request_add_ons": "অ্যাড-অন রিকোয়েস্ট আপডেট করুন",

        // Branch
        "branch.branch_list": "ব্রাঞ্চ তালিকা",
        "branch.branch": "ব্রাঞ্চ",
        "branch.create_branch": "ব্রাঞ্চ তৈরি করুন",
        "branch.create_success_msg": "ব্রাঞ্চ সফলভাবে তৈরি হয়েছে",
        "branch.branch_name": "ব্রাঞ্চের নাম",
        "branch.update_success_msg": "ব্রাঞ্চ সফলভাবে আপডেট হয়েছে",
        "branch.update_branch": "ব্রাঞ্চ আপডেট করুন",
        "branch.branch_data_not_available": "ব্রাঞ্চ ডেটা পাওয়া যায় নি",
        "branch.select_branch": "ব্রাঞ্চ নির্বাচন করুন",
        "branch.branch_details": "ব্রাঞ্চ বিশদ",
        "branch.employee": "কর্মচারী",
        "branch.total_branch": "মোট ব্রাঞ্চ",

        // Branch Form
        "branch_form.branch_name_required": "ব্রাঞ্চের নাম প্রয়োজন",
        "branch_form.select_restaurant": "রেস্টুরেন্ট নির্বাচন করুন",
        "branch_form.restaurant_required": "রেস্টুরেন্ট প্রয়োজন",
        "branch_form.enabled": "এনএইবল",
        "branch_form.delivery_radius": "ডেলিভারি এলাকা ব্যাসার্ধ (মাইল)",
        "branch_form.delivery_radius_required": "ডেলিভারি এলাকা ব্যাসার্ধ প্রয়োজন",
        "branch_form.branch_location": "ব্রাঞ্চ অবস্থান",
        "branch_form.branch_location_required": "ব্রাঞ্চ অবস্থান প্রয়োজন",
        "branch_form.order_types": "অর্ডার প্রকার",
        "branch_form.opening_time": "খোলার সময়",
        "branch_form.closing_time": "বন্ধের সময়",
        "branch_form.deliver_radius_negative": "ডেলিভারি এলাকা ব্যাসার্ধ শূন্য থেকে বড় হতে হবে",

        // franchise
        "franchise.franchise_list": "ফ্র্যাঞ্চাইজি তালিকা",
        "franchise.franchise": "ফ্র্যাঞ্চাইজি",
        "franchise.create_franchise": "ফ্র্যাঞ্চাইজি তৈরি করুন",
        "franchise.create_success_msg": "ফ্র্যাঞ্চাইজি সফলভাবে তৈরি হয়েছে",
        "franchise.franchise_name": "ফ্র্যাঞ্চাইজির নাম",
        "franchise.update_success_msg": "ফ্র্যাঞ্চাইজি সফলভাবে আপডেট হয়েছে",
        "franchise.update_franchise": "ফ্র্যাঞ্চাইজি আপডেট করুন",
        "franchise.franchise_data_not_available": "ফ্র্যাঞ্চাইজির ডেটা পাওয়া যায়নি",
        "franchise.select_franchise": "ফ্র্যাঞ্চাইজি নির্বাচন করুন",
        "franchise.franchise_details": "ফ্র্যাঞ্চাইজির বিবরণ",
        "franchise.employee": "কর্মচারী",
        "franchise.total_franchise": "মোট ফ্র্যাঞ্চাইজি",

        // franchise form
        "franchise_form.franchise_name_required": "ফ্র্যাঞ্চাইজির নাম প্রয়োজন",
        "franchise_form.select_restaurant": "রেস্টুরেন্ট নির্বাচন করুন",
        "franchise_form.restaurant_required": "রেস্টুরেন্ট প্রয়োজন",
        "franchise_form.enabled": "এনএইবলড",
        "franchise_form.delivery_radius": "ডেলিভারি এলাকা ব্যাসার্ধ (মাইল)",
        "franchise_form.delivery_radius_required": "ডেলিভারি এলাকা ব্যাসার্ধ প্রয়োজন",
        "franchise_form.franchise_location": "ফ্র্যাঞ্চাইজির লোকেশন",
        "franchise_form.franchise_location_required": "ফ্র্যাঞ্চাইজির লোকেশন প্রয়োজন",
        "franchise_form.order_types": "অর্ডার প্রকার",
        "franchise_form.opening_time": "খোলার সময়",
        "franchise_form.closing_time": "বন্ধের সময়",
        "franchise_form.deliver_radius_negative": "ডেলিভারি এলাকা ব্যাসার্ধ শূন্যের চেয়ে বড় হতে হবে",
        "franchise_form.franchise": "ফ্র্যাঞ্চাইজি",

        // Assign Employee
        "assign_employee.select_employee": "কর্মচারী নির্বাচন করুন",
        "assign_employee.success_msg": "সিলেক্টেড ব্রাঞ্চে সফলভাবে কর্মচারী নিয়োগ করা হয়েছে",
        "assign_employee.employee_required": "কর্মচারী প্রয়োজন",
        "assign_employee.branch_required": "শাখা প্রয়োজন",

        // Order
        "order.order_list": "অর্ডার তালিকা",
        "order.order_number": "অর্ডার নম্বর",
        "order.customer_name": "গ্রাহকের নাম",
        "order.status": "স্টেটাস",
        "order.order_type": "অর্ডার প্রকার",
        "order.payment_type": "পেমেন্ট প্রকার",
        "order.paid": "পেইড",
        "order.create_order": "অর্ডার তৈরি করুন",
        "order.update_order": "অর্ডার আপডেট করুন",
        "order.order_details": "অর্ডার বিশদ",
        "order.order_info": "অর্ডার তথ্য",
        "order.customer_info": "কাস্টমার তথ্য",
        "order.invoice": "চালান",
        "order.customer_id": "কাস্টমার আইডি",
        "order.total_price": "মোট মূল্য",
        "order.total_product_price": "মোট পণ্য মূল্য",
        "order.total_add_on_price": "মোট অ্যাড-অন মূল্য",
        "order.total_product": "মোট পণ্য",
        "order.total_add_on": "মোট অ্যাড-অন",
        "order.promo_discount": "প্রোমো ডিসকাউন্ট",
        "order.point_amount": "পয়েন্ট পরিমাণ",
        "order.final_price": "চূড়ান্ত মূল্য",
        "order.not_available": "অর্ডার ডেটা পাওয়া যায় নি",
        "order.total_order": "মোট অর্ডার",
        "order.order_type_required": "অর্ডার টাইপ প্রয়োজন",
        "order.select_table": "টেবিল নির্বাচন করুন",
        "order.table_required": "টেবিল প্রয়োজন",
        "order.discount_type": "ডিসকাউন্ট টাইপ",
        "order.discount": "ডিসকাউন্ট",
        "order.comment": "মন্তব্য",
        "order.comment_required": "মন্তব্য প্রয়োজন",
        "order.remarks": "মন্তব্য",
        "order.guest_numbers": "অতিথির সংখ্যা",
        "order.event_date": "ইভেন্ট তারিখ",
        "order.total_payable_amount": "মোট পরিশোধ যোগ্য পরিমাণ",
        "order.invoice_number": "চালান নম্বর",
        "order.guests": "অতিথি",
        "order.generate_date": "তৈরির তারিখ",
        "order.generate_time": "তৈরির সময়",
        "order.generate_by": "তৈরি করেছেন",
        "order.change": "পরিবর্তন",
        "order.thank_order": "অর্ডার করার জন্য আপনাকে ধন্যবাদ",
        "order.visit": "পরিদর্শন",
        "order.to_online_order": "অনলাইনে অর্ডার করার জন্য",
        "order.phone_no": "ফোন নম্বর",

        // Branch Product
        "branch_product.availability_msg": "ব্রাঞ্চের পণ্যের উপস্থিতি সফলভাবে আপডেট হয়েছে",
        "branch_product.update_msg": "ব্রাঞ্চের পণ্যটি সফলভাবে আপডেট হয়েছে",
        "branch_product.related_product_success_msg": "রিলেটেড পণ্য সফলভাবে যোগ করা হয়েছে",
        "branch_product.product_has_been": "পণ্য হয়েছে",
        "branch_product.successfully": "সফলভাবে",
        "branch_product.enabled_small": "এনএইবল করা হয়েছে",
        "branch_product.disabled_small": "ডিসএবল করা হয়েছে",

        // Promo
        "promo.total_promo": "মোট প্রোমো",
        "promo.promo_code": "প্রোমো কোড",
        "promo.promo_list": "প্রোমো তালিকা",
        "promo.create_promo": "প্রোমো তৈরি করুন",
        "promo.update_promo": "প্রোমো আপডেট করুন",
        "prom.create_success_msg": "প্রোমো সফলভাবে তৈরি হয়েছে",
        "prom.update_success_msg": "প্রোমো সফলভাবে আপডেট হয়েছে",
        "prom.data_not_available": "প্রোমো তথ্য এভেইলেবল নেই",
        "prom.select_promo": "প্রোমো নির্বাচন করুন",

        // Promo Form
        "promo_form.promo_code_required": "প্রোমো কোড প্রয়োজন",
        "promo_form.valid_from": "ভ্যালিড থেকে",
        "promo_form.valid_to": "ভ্যালিড পর্যন্ত",
        "promo_form.valid_from_required": "ভ্যালিড তারিখ প্রয়োজন",
        "promo_form.valid_to_required": "ভ্যালিড তারিখ প্রয়োজন",
        "promo_form.price_type": "মূল্যের টাইপ",
        "promo_form.price_type_required": "মূল্যের টাইপ প্রয়োজন",
        "promo_form.flat_amount": "ফ্ল্যাট পরিমাণ",
        "promo_form.flat_amount_required": "ফ্ল্যাট পরিমাণ প্রয়োজন",
        "promo_form.percent": "শতকরা",
        "promo_form.percent_required": "শতকরা প্রয়োজন",
        "promo_form.applicable_percent_amount": "এপ্লিকেবল শতকরা পরিমাণ",
        "promo_form.applicable_order_amount": "এপ্লিকেবল অর্ডারের পরিমাণ",
        "promo_form.flat_amount_negative": "ফ্ল্যাট পরিমাণ শূন্যের বেশি হতে হবে",
        "promo_form.percent_negative": "শতকরা শূন্যের বেশি হতে হবে",
        "promo_form.percent_max_amount_negative": "শতকরা সর্বাধিক পরিমাণ অধিক ০ হতে হবে",
        "promo_form.min_order_amount_negative": "অর্ডারের সর্বনিম্ন পরিমাণ অবশ্যই একটি পসিটিভ সংখ্যা হতে হবে",
        "promo_form.desc": "বিবরণ",

        // Order Type
        "order_type.delivery": "হোম ডেলিভারি",
        "order_type.takeaway": "স্ব-নিয়ন্ত্রণ",
        "order_type.dine_in": "ডাইন ইন",
        "order_type.catering": "খাবার ক্যাটারিং",

        // Global Config
        "global_config.global_config_list": "গ্লোবাল কনফিগারেশন তালিকা",
        "global_config.create_global_config": "গ্লোবাল কনফিগারেশন তৈরি করুন",
        "global_config.update_global_config": "গ্লোবাল কনফিগারেশন আপডেট করুন",
        "global_config.create_msg": "গ্লোবাল কনফিগারেশন সফলভাবে তৈরি করা হয়েছে",
        "global_config.update_msg": "গ্লোবাল কনফিগারেশন সফলভাবে আপডেট করা হয়েছে",
        "global_config.data_not_available": "গ্লোবাল কনফিগারেশন তথ্য এভেইলেবল নেই",
        "global_config.activate_confirm_title": "গ্লোবাল কনফিগারেশন এক্টিভ করুন",
        "global_config.disable_confirm_title": "গ্লোবাল কনফিগারেশন অক্ষম করুন",
        "global_config.activate_confirm_desc": "আপনি কি নিশ্চিত যে আপনি গ্লোবাল কনফিগারেশন এক্টিভেট করতে চান?",
        "global_config.disable_confirm_desc": "আপনি কি নিশ্চিত যে আপনি গ্লোবাল কনফিগারেশন অক্ষম করতে চান?",
        "global_config.active_success_msg": "গ্লোবাল কনফিগারেশন সফলভাবে এক্টিভেট করা হয়েছে",
        "global_config.disable_success_msg": "গ্লোবাল কনফিগারেশন সফলভাবে ডিসএবল করা হয়েছে",

        // Global Config Form
        "global_config_form.order_point": "অর্ডার প্রতি পয়েন্ট",
        "global_config_form.value_per_point": "প্রতি পয়েন্টের মান",
        "global_config_form.applicable_max_point_value": "প্রযোজ্য সর্বোচ্চ পয়েন্টের মান",
        "global_config_form.branch_search_area": "ব্রাঞ্চ অনুসন্ধান এলাকা (মাইল)",
        "global_config_form.branch_search_area_negative": "ব্রাঞ্চ অনুসন্ধান এলাকা অবশ্যই একটি পসিটিভ সংখ্যা হতে হবে",
        "global_config_form.branch_search_area_column": "ব্রাঞ্চ অনুসন্ধান এলাকা",
        "global_config_form.value_per_point_negative": "প্রতি পয়েন্টের মান অবশ্যই একটি পসিটিভ সংখ্যা হতে হবে",
        "global_config_form.applicable_max_point_value_negative": "প্রযোজ্য সর্বোচ্চ পয়েন্টের মান অবশ্যই একটি পসিটিভ সংখ্যা হতে হবে",
        "global_config_form.currency": "কার্রেন্সি",
        "global_config_form.currency_required": "কার্রেন্সি প্রয়োজন",
        "global_config_form.select_currency": "কার্রেন্সি নির্বাচন করুন",
        "global_config_form.order_cancel_feature": "অর্ডার ক্যানসেল বৈশিষ্ট্য",
        "global_config_form.cancel_count_per_day": "প্রতি দিন সর্বাধিক ক্যানসেল গণনা",
        "global_config_form.cancel_count_per_day_negative": "প্রতি দিন সর্বাধিক ক্যানসেল গণনা অবশ্যই একটি পসিটিভ সংখ্যা হতে হবে",

        // Banner
        "banner.banner_list": "ব্যানারের তালিকা",
        "banner.create_banner": "ব্যানার তৈরি করুন",
        "banner.update_banner": "ব্যানার আপডেট করুন",
        "banner.total_banner": "মোট ব্যানার",
        "banner.banner_type": "ব্যানার টাইপ",
        "banner.banner_title": "ব্যানার টাইটেল",
        "banner.banner_create_success": "ব্যানার সফলভাবে তৈরি করা হয়েছে",
        "banner.banner_update_success": "ব্যানার সফলভাবে আপডেট করা হয়েছে",
        "banner.banner_data_not_available": "ব্যানার তথ্য এভেইলেবল নেই",
        "banner.sequence": "অবস্থান",

        // Banner Form
        "banner_form.title_required": "ব্যানার টাইটেল প্রয়োজন",
        "banner_form.banner_type_required": "ব্যানার টাইপ প্রয়োজন",
        "banner_form.promo_required": "প্রমো প্রয়োজন",

        // Restaurant Dashboard
        "restaurant_dashboard.total_earning": "মোট আয়",
        "restaurant_dashboard.total_earning_not_available": "মোট আয় তথ্য এভেইলেবল নেই",
        "restaurant_dashboard.top_branch": "শীর্ষ ব্রাঞ্চ",
        "restaurant_dashboard.earning_by_branch": "ব্রাঞ্চ অনুসারে আয়",
        "restaurant_dashboard.earning_by_branch_not_available": "ব্রাঞ্চ অনুসারে আয় তথ্য পাওয়া যায়নি",
        "restaurant_dashboard.total_order": "মোট অর্ডার",
        "restaurant_dashboard.total_order_not_available": "মোট অর্ডার তথ্য পাওয়া যায়নি",
        "restaurant_dashboard.order_by_branch": "ব্রাঞ্চ অনুসারে অর্ডার",
        "restaurant_dashboard.order_by_branch_not_available": "ব্রাঞ্চ অনুসারে অর্ডারের তথ্য পাওয়া যায়নি",
        "restaurant_dashboard.top_section_not_available": "মোট সারমর্ম তথ্য পাওয়া যায়নি",

        // Branch Dashboard
        "branch_dashboard.order_summary": "অর্ডার সামারি",
        "branch_dashboard.completed_order": "সম্পন্ন অর্ডার",
        "branch_dashboard.ongoing_order": "চলমান অর্ডার",
        "branch_dashboard.cancel_order": "ক্যানসেল অর্ডার",
        "branch_dashboard.completed": "সম্পন্ন",
        "branch_dashboard.ongoing": "চলমান",
        "branch_dashboard.canceled": "ক্যানসেল",
        "branch_dashboard.order_type_data_not_avl": "অর্ডারের টাইপের তথ্য পাওয়া যায়নি",
        "branch_dashboard.order_summary_data_not_avl": "অর্ডারের সামারি তথ্য পাওয়া যায়নি",
        "branch_dashboard.top_products": "শীর্ষ পণ্য",
        "branch_dashboard.top_product_not_avl": "শীর্ষ পণ্যের তথ্য পাওয়া যায়নি",
        "branch_dashboard.order": "অর্ডার",
        "branch_dashboard.recent_order_reviews": "রিসেন্ট অর্ডার রিভিউস",
        "branch_dashboard.recent_order_reviews_not_avl": "রিসেন্ট অর্ডার রিভিউস তথ্য পাওয়া যায়নি",
        "branch_dashboard.order_id": "অর্ডার আইডি",
        "branch_dashboard.reviewed_branch": "রিভিউড ব্রাঞ্চ",
        "branch_dashboard.data_time": "তারিখ ও সময়",
        "branch_dashboard.reviewed_by": "রিভিউ করেছেন",
        "branch_dashboard.message": "বার্তা",
        "branch_dashboard.rating": "রেটিং",
        "branch_dashboard.recent_product_reviews": "রিসেন্ট পণ্য রিভিউস",
        "branch_dashboard.recent_product_reviews_not_avl": "রিসেন্ট পণ্য রিভিউস তথ্য পাওয়া যায়নি",
        "branch_dashboard.reviewed_product": "রিভিউড পণ্য",
        "branch_dashboard.product_review": "পণ্য রিভিউ",
        "branch_dashboard.order_review": "অর্ডার রিভিউ",

        // Analytics
        "analytics.top_branches": "শীর্ষ ব্রাঞ্চগুলি",
        "analytics.top_branch_not_avl": "শীর্ষ ব্রাঞ্চ তথ্য পাওয়া যায়নি",

        // Table
        "table.table_list": "টেবিলের তালিকা",
        "table.table_data_not_available": "টেবিল তথ্য পাওয়া যায়নি",
        "table.create_table": "টেবিল তৈরি করুন",
        "table.update_table": "টেবিল আপডেট করুন",
        "table.create_success_msg": "টেবিলটি সফলভাবে তৈরি করা হয়েছে",
        "table.update_success_msg": "টেবিলটি সফলভাবে আপডেট করা হয়েছে",
        "table_form.name_required": "টেবিল নম্বর প্রয়োজন",
        "table_form.table_name": "টেবিলের নাম",
        "table_form.description": "বর্ণনা",
        "table_form.max_person": "সর্বোচ্চ ব্যক্তি",
        "table_form.max_person_btwn": "সর্বাধিক ব্যক্তি ১ থেকে ১০০ এর মধ্যে হতে হবে",
        "table_form.max_person_required": "সর্বাধিক ব্যক্তি প্রয়োজন",

        // Catering Section
        "catering_section.catering_section_list": "কেটারিং সেকশন তালিকা",
        "catering_section.create_catering_section": "কেটারিং সেকশন তৈরি করুন",
        "catering_section.catering_section_name": "সেকশনের নাম",
        "catering_section.catering_section_name_required": "কেটারিং সেকশনের নাম প্রয়োজন",
        "catering_section.note": "নোট",
        "catering_section.create_success_msg": "কেটারিং সেকশন সফলভাবে তৈরি হয়েছে",
        "catering_section.update_success_msg": "কেটারিং সেকশন সফলভাবে আপডেট হয়েছে",
        "catering_section.data_not_available": "কেটারিং সেকশনের তথ্য পাওয়া যায়নি",
        "catering_section.update_catering_section": "কেটারিং সেকশন আপডেট করুন",
        "catering_section.catering_section": "কেটারিং সেকশন",

        // Catering Product
        "catering.request_catering_product": "কেটারিং পণ্যের রিকোয়েস্ট",
        "catering.product_list": "কেটারিং পণ্য তালিকা",
        "catering.create_product": "কেটারিং পণ্য তৈরি করুন",
        "catering.update_product": "কেটারিং পণ্য আপডেট করুন",
        "catering.create_product_success_msg": "কেটারিং পণ্য সফলভাবে তৈরি হয়েছে",
        "catering.update_product_success_msg": "কেটারিং পণ্য সফলভাবে আপডেট হয়েছে",

        // Catering Menu
        "catering.catering_menu_list": "কেটারিং মেনু তালিকা",
        "catering.create_catering_menu": "কেটারিং মেনু তৈরি করুন",
        "catering.update_catering_menu": "কেটারিং মেনু আপডেট করুন",
        "catering.catering_menu_name": "কেটারিং মেনুর নাম",
        "catering.catering_menu_name_required": "কেটারিং মেনুর নাম প্রয়োজন",
        "catering.catering_menu_details": "কেটারিং মেনুর বিস্তারিত",
        "catering.branch_menu_assigned": "ব্রাঞ্চ মেনু সফলভাবে অ্যাসাইন করা হয়েছে",
        "catering.request_catering_menu": "কেটারিং মেনু রিকোয়েস্ট করুন",
        "catering.menu_name": "মেনু নাম",
        "catering.add_at_least_one_item": "অন্তত একটি আইটেম যোগ করুন",
        "catering.duplicate": "নকল",
        "catering.item_already_added": "এই আইটেমটি ইতিমধ্যে যোগ করা হয়েছে",
        "catering.add_alternative": "বিকল্প যোগ করুন",
        "catering.empty_plus": "ফাঁকা প্লাস",
        "catering.item_can_selected": "আইটেম নির্বাচন করা যাবে",
        "catering.please_select_product": "দয়া করে পণ্য নির্বাচন করুন",
        "catering.choice_of_item": "আইটেমের পছন্দ",
        "catering.choice_of_item_required": "আইটেমের পছন্দ প্রয়োজন",
        "catering.at_least_choice_of_item": "আইটেমের পছন্দ অন্তত 1 টি হতে হবে",
        "catering.menu_data_unavailable": "কেটারিং মেনু ডেটা পাওয়া যায়নি",
        "catering.you_have_to_select": "আপনাকে অন্তত নির্বাচন করতে হবে",
        "catering.because_choice": "কারণ আপনার আইটেমের পছন্দ হলো",
        "catering.catering_menu_create_msg": "কেটারিং মেনু সফলভাবে তৈরি হয়েছে",
        "catering.catering_menu_update_msg": "কেটারিং মেনু সফলভাবে আপডেট হয়েছে",
        "catering.catering_menu_has_been": "কেটারিং মেনু হয়েছে",
        "catering.the_catering_menu": "কেটারিং মেনু",

        // catering অর্ডার
        "catering.catering_order_list": "কেটারিং অর্ডারের তালিকা",
        "catering.create_catering_order": "কেটারিং অর্ডার তৈরি করুন",
        "catering.update_catering_order": "কেটারিং অর্ডার আপডেট করুন",
        "catering.billed_to": "বিল করা হয়েছে",
        "catering.invoice_no": "চালান নম্বর",
        "catering.no_of_guests": "অতিথির সংখ্যা",
        "catering.including_tax": "কর সহ",
        "catering.invoice_total": "মোট চালান",
        "catering.event_details": "ইভেন্টের বিবরণ",
        "catering.signature": "স্বাক্ষর",
        "catering.client_signature": "ক্লায়েন্টের স্বাক্ষর",
        "catering.thank_you_for_coming": "আমাদের রেস্টুরেন্টে আসার জন্য আপনাকে ধন্যবাদ",

        // কেটারিং অর্ডার ফরম
        "catering_order_form.included": "ইনক্লুডেড",
        "catering_order_form.additional": "অতিরিক্ত",
        "catering_order_form.current_order": "বর্তমান অর্ডার",
        "catering_order_form.all_catering_menu": "সমস্ত কেটারিং মেনু",
        "catering_order_form.enter_guest": "অতিথি লিখুন",
        "catering_order_form.select_catering_menu": "কেটারিং মেনু নির্বাচন করুন",
        "catering_order_form.menu_section": "মেনু ক্যাটেগরি",
        "catering_order_form.select_menu": "মেনু নির্বাচন করুন",
        "catering_order_form.confirm": "নিশ্চিত করুন",
        "catering_order_form.alternative_items": "বিকল্প আইটেম",
        "catering_order_form.choose": "নির্বাচন করুন",
        "catering_order_form.item_here": "আইটেম এখানে",
        "catering_order_form.selected_items": "সিলেক্টেড আইটেমস",
        "catering_order_form.selected": "সিলেক্টেড",
        "catering_order_form.select_category": "ক্যাটেগরি নির্বাচন করুন",
        "catering_order_form.search_product_name": "পণ্যের নাম অনুসন্ধান করুন",
        "catering_order_form.choice_of": "পছন্দ",
        "catering_order_form.percentage": "শতাংশ",
        "catering_order_form.flat": "ফ্ল্যাট",
        "catering_order_form.note_optional": "নোট (ঐচ্ছিক)",
        "catering_order_form.select_event_date": "ইভেন্টের তারিখ নির্বাচন করুন",
        "catering_order_form.event_location": "ইভেন্টের স্থান",
        "catering_order_form.ny_sales_tax": "বিক্রয় কর",
        "catering_order_form.event_date_required": "ইভেন্টের তারিখ প্রয়োজন, দয়া করে আপনার ইভেন্টের তারিখটি নির্বাচন করুন",
        "catering_order_form.customer_required": "কাস্টমার প্রয়োজন, দয়া করে কাস্টমার নির্বাচন করুন",
        "catering_order_form.least_one_menu": "অন্তত একটি মেনু যোগ করুন",
        "catering_order_form.catering_invoice": "কেটারিং চালান",

        // সব
        "product.catering_type": "কেটারিং",

        // স্টক
        "stock.stock_list": "স্টক তালিকা",
        "stock.stock_small": "স্টক",
        "stock.create_stock": "তৈরি করুন",
        "stock.update_stock": "আপডেট করুন",
        "stock.total_stock": "মোট স্টক",
        "stock.stock_number": "স্টক নম্বর",
        "stock.stock_details": "স্টক বিবরণ",
        "stock.paid_amount": "পেইড পরিমাণ",
        "stock.stock_type": "স্টক টাইপ",
        "stock.stock_data_unavailable": "স্টক ডেটা পাওয়া যায়নি",
        "stock.stock_create_msg": "স্টক সফলভাবে তৈরি হয়েছে",
        "stock.stock_update_msg": "স্টক সফলভাবে আপডেট হয়েছে",
        "stock.expired_at": "মেয়াদ উত্তীর্ণ হয়েছে",
        "stock.expired_date": "মেয়াদ উত্তীর্ণের তারিখ",
        "stock.available_qty": "এভেইলেবল পরিমাণ",

        // স্টকের বিবরণ
        "stock_details.ingredient_info": "ইনগ্রেডিয়েন্ট তথ্য",
        "stock_details.stock_info": "স্টকের তথ্য",
        "stock_details.agent_info": "এজেন্টের তথ্য",
        "stock_details.balance": "ব্যালেন্স",
        "stock_details.total_spent": "মোট ব্যয়",

        // স্টক ইন ফরম
        "stock_in_form.one_item_required": "অন্তত একটি আইটেম নির্বাচন করুন",
        "stock_in_form.mfg_date": "উৎপাদনের তারিখ",
        "stock_in_form.expire_date": "মেয়াদ উত্তীর্ণের তারিখ",
        "stock_in_form.select_supplier": "সাপ্লায়ার নির্বাচন করুন",
        "stock_in_form.select_ingredient": "ইনগ্রেডিয়েন্ট নির্বাচন করুন",
        "stock_in_form.ingredient_required": "ইনগ্রেডিয়েন্ট প্রয়োজন",
        "stock_in_form.unit_price": "ইউনিট মূল্য",
        "stock_in_form.unit_price_max_zero": "ইউনিট মূল্য সমান অথবা অধিক হতে হবে ০",
        "stock_in_form.qty_max_one": "পরিমাণ অবশ্যই ১ এর বেশি হতে হবে",
        "stock_in_form.due_amount": "বকেয়া পরিমাণ",
        "stock_in_form.voucher_number": "ভাউচার নম্বর",
        "stock_in_form.attachment": "সংযুক্তি",
        "stock_in_form.stock_date": "স্টক তারিখ",
        "stock_in_form.select_agent": "এজেন্ট নির্বাচন করুন",
        "stock_in_form.select_date": "তারিখ নির্বাচন করুন",
        "stock_in_form.threshold": "থ্রেশহোল্ড",
        "stock_in_form.threshold_max_one": "থ্রেশহোল্ড অবশ্যই একটি গতিশীল সংখ্যা হতে হবে",

        // স্টক সামারি
        "stock_summary.stock_summary_list": "স্টক সামারি তালিকা",
        "stock_summary.stock_summary_details": "স্টক সামারি বিবরণ",
        "stock_summary.total_stock_item": "মোট স্টক আইটেম",
        "stock_summary.total_stock_summary": "মোট স্টক সামারি",
        "stock_summary.available": "এভেইলেবল",
        "stock_summary.unavailable": "আনএভেইলেবল",
        "stock_summary.select_stock_availability": "স্টক উপস্থিতি নির্বাচন করুন",
        "stock_summary.stock_summary_download": "স্টক সামারি ডাউনলোড",

        // স্টক আউট
        "stock_out.stock_out_list": "স্টক আউট তালিকা",
        "stock_out.total_stock_out": "মোট স্টক আউট",
        "stock_out.stock_out_details": "স্টক আউট বিবরণ",
        "stock.stock_out_msg": "স্টক সফলভাবে আউট হয়েছে",
        "stock.stock_out_update_msg": "স্টক সফলভাবে আপডেট হয়েছে",
        "stock.out_of_stock": "স্টকে নেই",
        "stock.stock_out_small": "স্টক আউট",

        // স্টক আউট ফরম
        "stock_out_form.select_stock": "স্টক নির্বাচন করুন",
        "stock_out_form.stock_required": "স্টক প্রয়োজন",
        "stock_out_form.qty_required": "পরিমাণ প্রয়োজন",
        "stock_out_form.qty_between": "পরিমাণ ১ এবং",
        "stock_out_form.stock_type_required": "স্টক টাইপ প্রয়োজন",
        "stock_out_form.missing": "অনুপস্থিত",
        "stock_out_form.out": "আউট",
        "stock_out_form.spoilage": "ধ্বংস",
        "stock_out_form.wastage": "অপচয়",

        // স্টক সামারি
        "stock_summary.create_date_range": "তৈরির তারিখের রেঞ্জ",
        "stock_summary.stock_date_range": "স্টকের তারিখের রেঞ্জ",
        "stock_summary.expire_date_range": "মেয়াদ উত্তীর্ণের তারিখের রেঞ্জ",

        // branch config
        "branch_config.branch_config_list": "ব্রাঞ্চ কনফিগ তালিকা",
        "branch_config.create_branch_config": "ব্রাঞ্চ কনফিগ তৈরি করুন",
        "branch_config.update_branch_config": "ব্রাঞ্চ কনফিগ আপডেট করুন",
        "branch_config.create_msg": "ব্রাঞ্চ কনফিগ সফলভাবে তৈরি হয়েছে",
        "branch_config.update_msg": "ব্রাঞ্চ কনফিগ সফলভাবে আপডেট হয়েছে",
        "branch_config.data_not_available": "ব্রাঞ্চ কনফিগ তথ্য পাওয়া যায়নি",
        "branch_config.activate_confirm_title": "ব্রাঞ্চ কনফিগ এক্টিভ করুন",
        "branch_config.disable_confirm_title": "ব্রাঞ্চ কনফিগ ডিসএবল করুন",
        "branch_config.activate_confirm_desc": "আপনি কি নিশ্চিত যে আপনি ব্রাঞ্চ কনফিগ এক্টিভেট করতে চান?",
        "branch_config.disable_confirm_desc": "আপনি কি নিশ্চিত যে আপনি ব্রাঞ্চ কনফিগ ডিসএবল করতে চান?",
        "branch_config.disable_success_msg": "ব্রাঞ্চ কনফিগ সফলভাবে ডিসএবল করা হয়েছে",
        "branch_config.mx_cancel_count": "সর্বাধিক ক্যানসেল গণনা / দিন",
        "branch_config.order_cancel_time": "অর্ডার ক্যানসেল সময় (মিনিট)",
        "branch_config.tax_percentage": "কর শতাংশ",
        "branch_config.order_cancel_time_required": "অর্ডার ক্যানসেল সময় প্রয়োজন",
        "branch_config.order_cancel_time_least": "অর্ডার ক্যানসেল সময় মিনিটে অবশ্যই ৫ এর বেশি হতে হবে",
        "branch_config.tax_percentage_greater": "কর শতাংশ অবশ্যই ০ এর বেশি হতে হবে",

        // এজেন্ট পেমেন্ট
        "agent_payment.total_agent_payment": "মোট এজেন্ট পেমেন্ট",
        "agent_payment.agent_payment_list": "এজেন্ট পেমেন্ট তালিকা",
        "agent_payment.create_agent_payment": "এজেন্ট পেমেন্ট তৈরি করুন",
        "agent_payment.update_agent_payment": "এজেন্ট পেমেন্ট আপডেট করুন",

        // মেয়াদ উত্তীর্ণ স্টক
        "expire_stock.expired_stock_list": "মেয়াদ উত্তীর্ণ স্টকের তালিকা",

        // পস বাম মেনু
        "pos_left_menu.all_items": "সব আইটেম",

        // পস মধ্যম বিষয়বস্তু
        "pos_middle_content.its_all_msg": "এটা সব, আর কিছু নেই",
        "pos_middle_content.out_of_stock": "স্টকে নেই",
        "pos_middle_content.search_place_holder": "নাম বা শর্টকাট দিয়ে আইটেম অনুসন্ধান করুন",
        "pos_middle_content.dine_in": "ডাইন-ইন",

        // পস কার্ট
        "pos_cart.cart": "কার্ট",
        "pos_cart.payment": "পেমেন্ট",
        "pos_cart.clear_cart": "কার্ট খালি করুন",
        "pos_cart.total_items": "মোট আইটেম",
        "pos_cart.sub_total": "উপ-মোট",
        "pos_cart.sale_tax": "বিক্রয় কর",
        "pos_cart.hold": "ধরুন",
        "pos_cart.save_print_kot": "সংরক্ষণ এবং প্রিন্ট KOT",
        "pos_cart.save_print_bill": "বিল সংরক্ষণ এবং প্রিন্ট করুন",
        "pos_cart.item_name": "আইটেমের নাম",
        "pos_cart.delete": "মুছে ফেলুন",
        "pos_cart.qty": "পরিমাণ",
        "pos_cart.table_duplicate_add_msg": "টেবিলটি ইতিমধ্যে যোগ করা হয়েছে",
        "pos_cart.table_no": "টেবিল নং",
        "pos_cart.order_note": "অর্ডার নোট",
        "pos_cart.order_instruction_place_holder": "যেকোনো অর্ডার নির্দেশিকা যেমনঃ সস, মসলা যোগ করুন ইত্যাদি",
        "pos_cart.select_customer": "কাস্টমার নির্বাচন করুন",
        "pos_cart.new_customer": "নতুন কাস্টমার",
        "pos_cart.no_of_guest": "অতিথির সংখ্যা",
        "pos_cart.table_number": "টেবিল নম্বর",
        "pos_cart.paid_amount_small": "পেইড পরিমাণ",
        "pos_cart.tables": "টেবিল",
        "pos_cart.online": "অনলাইন",
        "pos_cart.tips": "টিপস",
        "pos_cart.table_already_added": "এই টেবিলটি ইতিমধ্যে যোগ করা হয়েছে",
        "pos_cart.select_delivery_address": "ডেলিভারি ঠিকানা নির্বাচন করুন",
        "pos_cart.address_title": "ঠিকানা টাইটেল",
        "pos_cart.address_title_placeholder": "ঠিকানা টাইটেল উদাহরণঃ অফিস",
        "pos_cart.select_branch_location": "আপনার ব্রাঞ্চ অবস্থান নির্বাচন করুন",

        // পস অর্ডার তালিকা
        "pos_order_list.priority_updated": "অগ্রাধিকার আপডেট করা হয়েছে",
        "pos_order_list.successfully": "সফলভাবে",
        "pos_order_list.priority_has_been": "অগ্রাধিকার হয়েছে",
        "pos_order_list.set": "সেট",
        "pos_order_list.canceled_small": "ক্যানসেল হয়েছে",
        "pos_order_list.order_cancel_msg": "অর্ডার সফলভাবে ক্যানসেল হয়েছে",
        "pos_order_list.update_status": "স্টেটাস আপডেট করুন",
        "pos_order_list.payment_status": "পেমেন্ট স্টেটাস",
        "pos_order_list.table_view": "টেবিল দেখুন",
        "pos_order_list.hold_list": "হোল্ড তালিকা",
        "pos_order_list.cancel_priority": "অগ্রাধিকার ক্যানসেল করুন",
        "pos_order_list.set_priority": "অগ্রাধিকার সেট করুন",
        "pos_order_list.print_kot": "প্রিন্ট KOT",
        "pos_order_list.print_bill": "প্রিন্ট বিল",
        "pos_order_list.cancel_order": "অর্ডার ক্যানসেল করুন",
        "pos_order_list.edit_order": "অর্ডার এডিট করুন",
        "pos_order_list.order_edit_confirm_msg": "আপনি কি নিশ্চিত যে আপনি এই অর্ডারটি এডিট করতে চান",
        "pos_order_list.order_clear_msg": "আপনার পূর্বের অর্ডার মুছে ফেলা হবে",
        "pos_order_list.order_date_time": "অর্ডারের তারিখ ও সময়",
        "pos_order_list.unpaid": "বিল বাকি",
        "pos_order_list.no_guest": "অতিথির সংখ্যা",
        "pos_order_list.order_status": "অর্ডারের স্টেটাস",
        "pos_order_list.customer_details": "গ্রাহকের বিবরণ",
        "pos_order_list.delivery_address": "ডেলিভারি ঠিকানা",
        "pos_order_list.payment_details": "পেমেন্টের বিবরণ",
        "pos_order_list.total_paid": "মোট পরিশোধ",
        "pos_order_list.total_due": "মোট বাকি",
        "pos_order_list.payment_method": "পেমেন্ট পদ্ধতি",
        "pos_order_list.total": "মোট",
        "pos_order_list.add_qty": "পরিমাণ যোগ করুন",
        "pos_order_list.add_price": "মূল্য যোগ করুন",
        "pos_order_list.add_on_total": "মোট যোগ করুন",
        "pos_order_list.item_details": "আইটেমের বিবরণ",
        "pos_order_list.point_discount": "পয়েন্ট ডিসকাউন্ট",
        "pos_order_list.search_order_by_id": "অর্ডার আইডি দ্বারা অনুসন্ধান করুন",
        "pos_order_list.assign_delivery_partner": "ডেলিভারি পার্টনার নির্ধারণ করুন",
        "pos_order_list.assign": "নির্ধারণ করুন",
        "pos_order_list.track_order": "অর্ডার ট্র্যাক করুন",

        // পস হোল্ড তালিকা
        "pos_hold_list.resume_hold_order": "হোল্ড অর্ডার পুনরায় চালু করুন",
        "pos_hold_list.confirm_hold_order_resume": "আপনি কি নিশ্চিত যে আপনি এই হোল্ড অর্ডারটি পুনরায় চালু করতে চান",
        "pos_hold_list.remove_hold_order": "হোল্ড অর্ডার মুছুন",
        "pos_hold_list.confirm_remove_hold_order": "আপনি কি নিশ্চিত যে আপনি এই হোল্ড অর্ডারটি মুছতে চান",

        // random user
        "user_form.please_assign_role": "দয়া করে একটি রোল নির্ধারণ করুন",
        "user_form.assign_role": "রোল নির্ধারণ করুন",
        "user_form.search_role": "রোল অনুসন্ধান করুন",
        "user_form.ok": "ঠিক আছে",
        "user.change_password": "পাসওয়ার্ড পরিবর্তন করুন",
        "user.assigned_role_list": "নির্ধারিত রোল তালিকা",
        "user.assigned_roles": "নির্ধারিত রোল",
        "user.branch_manager": "ব্রাঞ্চ ম্যানেজার",
        "user.waiter": "ওয়েটার",
        "user.chef": "শেফ",
        "user.franchise_owner": "ফ্র্যাঞ্চাইজ মালিক",

        // analytics
        "analytics.order_type": "অর্ডার টাইপ",
        "analytics.customer_type": "গ্রাহকের ধরণ",
        "analytics.total_number": "মোট সংখ্যা",
        "analytics.customer_map": "গ্রাহকের মানচিত্র",
        "analytics.no_data": "কোন ডেটা নেই",
        "analytics.new_customer": "নতুন গ্রাহক",
        "analytics.retained_customer": "সংরক্ষিত গ্রাহক",
        "analytics.excel": "এক্সেল",
        "analytics.pdf": "পিডিএফ",
        "analytics.customer_count": "গ্রাহক গণনা",
        "analytics.restaurant_name": "রেস্তোরাঁর নাম",
        "analytics.customer": "গ্রাহক",
        "analytics.total_customer": "মোট গ্রাহক",
        "analytics.order_count": "অর্ডার গণনা",
        "analytics.sl": "নং.",
        "analytics.start_date": "শুরুর তারিখ",
        "analytics.end_date": "শেষ তারিখ",
        "analytics.generated_at": "উত্পন্ন হয়েছে",
        "analytics.all_right_reserved": "সব অধিকার সংরক্ষিত",

        // cart
        "cart.save_order": "অর্ডার সংরক্ষণ করুন",
        "cart.complete_order": "অর্ডার সম্পন্ন করুন"
    }
}
