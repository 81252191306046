import React, {createContext, useState} from 'react';
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";
import TableService from "../services/TableService";

export const TableContext = createContext("TableContext");

const TableContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [tableList, setTableList] = useState([]);
    const [tableCountInfo, setTableCountInfo] = useState({
        countAll: 0,
        countEmpty: 0,
        countOngoing: 0
    })

    const getAllTable = async params => {
        try {

            setLoading(true);

            const res = await TableService.getAllTable(params);
            setTableList(res.data.branchTableStatusList);
            setTableList(res.data.content);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    const getAllTableOrderStatisics = async params => {
        try {

            setLoading(true);

            const res = await TableService.getAllTableOrderStatisics(params);
            setTableList(res.data.branchTableStatusList);
            setTableCountInfo({
                countAll: res.data.countAll,
                countEmpty: res.data.countEmpty,
                countOngoing: res.data.countOngoing
            });

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", "Error", message);

        }
    }

    return (
        <TableContext.Provider
            value={{
                loading,
                tableCountInfo,
                tableList,
                getAllTable,
                getAllTableOrderStatisics,
            }}
        >
            {children}
        </TableContext.Provider>
    );
}

export default TableContextProvider;
