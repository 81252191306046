import axios from "axios";
import {LANGUAGE} from "../helpers/Constant";
import {RESTAURANT_TOKEN} from "../helpers/RestaurantConstant";

const lng = localStorage.getItem(LANGUAGE);

const instance = axios.create({
    baseURL: "",
    headers: {
        "Content-Type": "application/json",
        "Restaurant-Token": RESTAURANT_TOKEN,
        "Accept-Language": lng,
        "Registration-Device": "PORTAL",
    }
});

export default instance;
