import React, {createContext, useState} from 'react';
import OrderService from "../../services/order/OrderService";
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";

export const OrderContext = createContext("OrderContext");

const OrderContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [orderList, setOrderList] = useState([]);

    const getAllOrder = async params => {
        try {

            setLoading(true);

            const res = await OrderService.getAllOrder(params);
            setOrderList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <OrderContext.Provider
            value={{
                loading,
                totalElements,
                orderList,
                getAllOrder,
            }}
        >
            {children}
        </OrderContext.Provider>
    );
}

export default OrderContextProvider;
