import React, {createContext, useState} from 'react';
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import {
    ACCESS_TOKEN,
    BRANCH,
    BRANCH_CONFIG,
    GLOBAL_CONFIG,
    PROFILE,
    REFRESH_TOKEN,
    RESTAURANT
} from "../helpers/Constant";
import GlobalConfigService from "../services/GlobalConfigService";
import BranchConfigService from "../services/BranchConfigService";


export const AuthContext = createContext("AuthContext");

const auth = localStorage.getItem(ACCESS_TOKEN);

const profileData = localStorage.getItem(PROFILE);

const _selectedRestaurant = localStorage.getItem(RESTAURANT);

const _selectedBranch = localStorage.getItem(BRANCH);

let _branchConfig = localStorage.getItem(BRANCH_CONFIG);
_branchConfig = _branchConfig ? JSON.parse(_branchConfig) : null;

const AuthContextProvider = ({children}) => {

    const [isLogin, setIsLogin] = useState(!!auth);
    const [profileLoading, setProfileLoading] = useState(false);

    const [profile, setProfile] = useState(profileData ? JSON.parse(profileData) : null);

    const [loading, setLoading] = useState(false);

    const [selectedRestaurant, setSelectedRestaurant] = useState(_selectedRestaurant ? JSON.parse(_selectedRestaurant) : null);
    const [selectedBranch, setSelectedBranch] = useState(_selectedRestaurant ? JSON.parse(_selectedBranch) : null);

    const [branchConfig, setBranchConfig] = useState(_branchConfig);

    const login = async credential => {

        try {

            setLoading(true);

            const response = await AuthService.login(credential);

            localStorage.setItem(ACCESS_TOKEN, response.data.token.access);
            localStorage.setItem(REFRESH_TOKEN, response.data.token.refresh);

            setIsLogin(true);
            setLoading(false);

        } catch (error) {

            setLoading(false);
            setIsLogin(false);

            const message = getErrorMessage(error);
            // Toast("error", Translator("common.error"), message);
            Toast("error", "Error", message);

        }

    }

    const ownerLogin = async credential => {

        try {

            setLoading(true);

            const response = await AuthService.ownerLogin(credential);

            localStorage.setItem(ACCESS_TOKEN, response.data.token.access);
            localStorage.setItem(REFRESH_TOKEN, response.data.token.refresh);

            setIsLogin(true);
            setLoading(false);

        } catch (error) {

            setLoading(false);
            setIsLogin(false);

            const message = getErrorMessage(error);
            // Toast("error", Translator("common.error"), message);
            Toast("error", "Error", message);

        }

    }

    const getUserProfile = async () => {

        try {

            setProfileLoading(true);

            const response = await UserService.getUserProfile();

            localStorage.setItem(RESTAURANT, JSON.stringify(response.data.restaurant));
            localStorage.setItem(BRANCH, JSON.stringify(response.data.branch));

            const globalConfigRes = await GlobalConfigService.getPublicGlobalConfig();
            localStorage.setItem(GLOBAL_CONFIG, JSON.stringify(globalConfigRes.data));

            if (response.data.branch) {

                const branchConfigRes = await BranchConfigService.getPublicBranchConfig();

                localStorage.setItem(BRANCH_CONFIG, JSON.stringify(branchConfigRes.data));
                setBranchConfig(branchConfigRes.data);

            }

            setProfile({...response.data});
            setSelectedRestaurant(response.data.restaurant);
            setSelectedBranch(response.data.branch);

            setProfileLoading(false);

        } catch (error) {

            setProfileLoading(false);

            const message = getErrorMessage(error);
            // Toast("error", Translator("common.error"), message);
            Toast("error", "Error", message);

        }
    }

    const logout = () => {
        setIsLogin(false);
        localStorage.clear();
    }

    return (
        <AuthContext.Provider
            value={{
                isLogin,
                profile,
                loading,
                profileLoading,
                permissions: profile ? profile.permissions : [],
                selectedRestaurant,
                selectedBranch,
                setProfile,
                branchConfig,
                login,
                ownerLogin,
                logout,
                getUserProfile,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
