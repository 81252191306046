import {Locales} from "../Locales";

export const af_PS = {
    [Locales.pashto]: {
        // common
        "common.error": "غلطی",
        "common.created": "پیدا شوی",
        "common.added": "زیاد شوی",
        "common.updated": "اپ ډیټ شوی",
        "common.submit": "پیژندل",
        "common.email": "برېښلیک",
        "common.password": "پاسورډ",
        "common.all": "ټول",
        "common.action": "کارروائی",
        "common.reset": "بیا تنظیمول",
        "common.search_list": "لټون کولو لیست",
        "common.select_language": "زده کړه ټاکل",
        "common.language_required": "لطفا یوه ژبه غوره کړئ",
        "common.image": "انځور",
        "common.address": "پته",
        "common.city": "ښار",
        "common.country": "هیواد",
        "common.zipcode": "زپ کوډ",
        "common.assigned": "وړاندی شوی",
        "common.yes": "هو",
        "common.no": "نه",
        "common.approved": "پسندیدل شوی",
        "common.approve": "پسندیدل کول",
        "common.approve_small": "پسندیدل کول",
        "common.disable": "غیرفعال کول",
        "common.disable_small": "غیرفعال کول",
        "common.password_changed": "پاسورډ بدل شوی",
        "common.confirm_msg": "آیا تاسو اطمینان لرئ چه مونږ باید",
        "common.the_order": "د لاندی د سفارش",
        "common.the_product": "د محصول",
        "common.the_promo": "د پرومو",
        "common.the_banner": "د بینر",
        "common.the_add_ons": "د اضافه کولو",
        "common.success_msg_has": "په کام کې موفق شوه",
        "common.success": "په بریالیتوب سره",
        "common.activated": "فعال شوی",
        "common.mile": "مایل",
        "common.create": "جوړول",
        "common.update": "اوسمهالول",
        "common.optional": "اختیاري",
        "common.add": "زیاتول",
        "common.created_at": "په وخت کې پیدا شوی",
        "common.created_date": "پیدا شوی نیټه",
        "common.cancelled": "لغو شوی",
        "common.download": "ښکاره کول",
        "common.date": "نیټه",
        "common.items": "توکي",
        "common.assign_to_branch": "خپله برانچ ته وړاندی کول",
        "common.edit": "سمول",
        "common.details": "تفصیلات",

        // nav header
        "nav_header.profile": "پېژندل",
        "nav_header.logout": "د خارجیدل",
        "nav_header.english": "انګلیسي",
        "nav_header.bengali": "بنگالي",
        "nav_header.urdu": "اردو",
        "nav_header.hindi": "هندي",
        "nav_header.pashto": "پښتو",

        // aside left
        "aside_left.dashboard": "د داشبورډ",
        "aside_left.user_management": "کارن مدیریت",
        "aside_left.user_list": "کارن لیست",
        "aside_left.product_management": "محصول مدیریت",
        "aside_left.product": "محصول",
        "aside_left.product_category": "کټګوري",
        "aside_left.product_add_ons": "زیاتولونه",
        "aside_left.branch": "شعبه",
        "aside_left.branch_list": "شعبو لیست",
        "aside_left.orders": "د سفارشونه",
        "aside_left.roles": "وظایف",
        "aside_left.assign_employee": "کارن وړاندی کول",
        "aside_left.requested_product": "طلب شوی محصول",
        "aside_left.requested_add_ons_list": "طلب شوی زیاتولونه",
        "aside_left.branch_product": "شعبو محصول",
        "aside_left.branch_add_ons": "شعبو زیاتولونه",
        "aside_left.promo": "پرومو",
        "aside_left.global": "عمومي",
        "aside_left.employee_list": "کارن لیست",
        "aside_left.customer_list": "مشتری لیست",
        "aside_left.global_config": "عمومي ترتيبونه",
        "aside_left.customer": "مشتری",
        "aside_left.banner": "بینر",
        "aside_left.analytics": "تحلیل",
        "aside_left.catering": "کیټرنګ",
        "aside_left.catering_products": "کیټرنګ محصولات",
        "aside_left.catering_sections": "کیټرنګ بخشونه",
        "aside_left.catering_menu": "کیټرنګ مینو",
        "aside_left.catering_order": "کیټرنګ سفارش",
        "aside_left.kitchen_display": "آشپزخانه نمایش",
        "aside_left.pos": "POS",
        "aside_left.ingredient_management": "د مواد مدیریت",
        "aside_left.ingredient_category": "مواد کټګوري",
        "aside_left.ingredient": "مواد",
        "aside_left.requested_ingredient": "طلب شوی مواد",
        "aside_left.supplier": "عرضه کونکي",
        "aside_left.stock_management": "مخزن مدیریت",
        "aside_left.stock": "مخزن",
        "aside_left.stock_in": "مخزن کښته شوی",
        "aside_left.stock_out": "مخزن څروته شوی",
        "aside_left.expense": "اخراج",
        "aside_left.agent": "اګنټ",
        "aside_left.agent_management": "اګنټ مدیریت",
        "aside_left.agent_payment": "اګنټ د تادیه",
        "aside_left.branch_config": "شعبو ترتيب",
        "aside_left.stock_summary": "مخزن خلاصه",
        "aside_left.expired_stock": "منقضي مخزن",
        "aside_left.table": "ټولې",
        "aside_left.requested_products": "طلب شوی محصولات",
        "aside_left.catering_product": "کیټرنګ محصول",
        "aside_left.order": "سفارش",
        "aside_left.requested_menu": "طلب شوی مینو",
        "aside_left.franchises": "فرانشایزونه",
        "aside_left.order_management": "د امر اداره کول",
        "aside_left.order_reports": "د امر راپورټونه",
        "aside_left.order_item_reports": "د امر زه انځور راپورټونه",
        "aside_left.order_history": "د امر تاریخ",

        // owner login
        "login.email_required_msg": "برېښلیک ضروری ده",
        "login.valid_email_msg": "لطفا یوازې معتبر برېښلیک داخل کړئ",
        "login.password_required_msg": "پټ نوم ضروری ده",
        "login.sign_in": "ننوتل",
        "login.sign_in_msg": "داخل شوی برېښلیک او پټ نوم دا د ننوتلو لپاره ورکړئ!",

        // user
        "user.total_employee": "د کارن کل",
        "user.create_user": "کارن جوړول",
        "user.update_user": "کارن اوسمهالول",
        "user.full_name": "ټول اسم",
        "user.create_success_msg": "کارن په بریالیتوب سره جوړ شو",
        "user.update_success_msg": "کارن په بریالیتوب سره اوسمهال شو",
        "user.user_data_not_available": "کارن معلومات شته نه",
        "user.profile_picture": "پېژندل عکس",
        "user.phone_number": "د ټلیفون شمیره",
        "user.date_of_birth": "د پیداولو نیټه",
        "user.gender": "جنس",
        "user.active": "فعال",
        "user.disabled": "نااختيار",
        "user.banned": "په بندیدو",
        "user.verified": "تصدیق شوی",
        "user.email_verified": "برېښلیک تصدیق شوی",
        "user.phone_verified": "ټلیفون تصدیق شوی",
        "user.profile": "پېژندل",
        "user.profile_update_msg": "پېژندل په بریالیتوب سره اوسمهال شو",
        "user.changed_password_msg": "پاسورډ په بریالیتوب سره بدل شو",
        "user.create_employee": "کارن جوړول",
        "user.update_employee": "کارن اوسمهالول",
        "user.select_role": "د رول انتخاب کړئ",

        // user form
        "user_form.full_name_required": "ټول اسم ضروري ده",
        "user_form.email_required": "برېښلیک ضروري ده",
        "user_form.male": "نر",
        "user_form.female": "ښځه",
        "user_form.others": "بل",
        "user_form.role_required": "رول ضروري ده",
        "user_form.phone_number_required": "ټلیفون شمیره ضروري ده",
        "user_form.invalid_email": "برېښلیک ناباوره",
        "user_form.hotline": "هالاين",
        "user_form.hotline_required": "هالاين ضروري ده",

        // customer
        "customer.total_customer": "ټول مشتری",
        "customer.customer_list": "مشتری لیست",
        "customer.customer_name": "مشتری نوم",
        "customer.customer_create_msg": "مشتری په بریالیتوب سره جوړ شو",
        "customer.invalid_phone": "ناباوره ټلیفون شمیره",
        "customer.customer_name_required": "مشتری نوم ضروري ده",
        "customer.cancel": "لغوه کول",
        "customer.save": "خوندي کول",
        "customer.customer_phone": "مشتری ټلیفون",

        // role
        "role.role_list": "رول لیست",
        "role.roles": "رولونه",
        "role.create_role": "رول جوړول",
        "role.update_role": "رول اوسمهالول",
        "role.create_success_msg": "رول په بریالیتوب سره جوړ شو",
        "role.update_success_msg": "رول په بریالیتوب سره اوسمهال شو",
        "role.role_data_not_available": "رول معلومات شته نه",
        "role.alias": "پیژندل",
        "role.role_level": "رول سطح",
        "role.select_role": "رول انتخاب کړئ",

        // role form
        "role_form.predefine_role_msg": "دا پیش خوا رول ده په اړه د پیژندل اوسمهال کولای شم نشي",
        "role_form.alias_required_msg": "پیژندل ضروري ده",
        "role_form.all": "ټول",
        "role_form.system": "سیستم",
        "role_form.restaurant": "ریستوران",
        "role_form.branch": "شعبه",
        "role_form.customer": "مشتری",
        "role_form.role_level_required": "رول سطح ضروري ده",

        // product
        "product.product_list": "محصول لیست",
        "product.previous_product_list": "پخواني سفارش محصول لیست",
        "product.branch_product_list": "شعبو محصول لیست",
        "product.create_product": "محصول جوړول",
        "product.name": "محصول نوم",
        "product.create_success_msg": "محصول په بریالیتوب سره جوړ شو",
        "product.update_success_msg": "محصول په بریالیتوب سره اوسمهال شو",
        "product.update_product": "محصول اوسمهالول",
        "product.product_not_available": "محصول معلومات شته نه",
        "product.type": "محصول ډول",
        "product.select_type": "محصول ډول انتخاب کړئ",
        "product.category": "محصول کټګوري",
        "product.price": "قیمت",
        "product.description": "توضیحات",
        "product.single_type": "تکی",
        "product.combo_type": "کمبو",
        "product.select_product": "محصول انتخاب کړئ",
        "product.enable": "فعال",
        "product.quantity": "مقدار",
        "product.discount_percent": "درصد تخفیف",
        "product.discount_percent_negative": "درصد تخفیف باید یوه مثبت عدد وي",
        "product.approved_msg": "محصول په بریالیتوب سره تصدیق شو",
        "product.approved_status_msg": "محصول",
        "product.sold_unit": "فروخته شوی واحد",
        "product.combo_items": "کمبو مواد",
        "product.popular": "مشهور",
        "product.change_popular_status": "مشهوریت د حالت بدلول",
        "product.popular_status_sure": "آیا تاسو په مشهور حالت کولای شي؟",
        "product.related_products": "مربوط محصولات",
        "product.shortcut_key": "کلید لانچر",
        "product.product_small": "محصول",

        // requested product
        "requested_product.requested_product_list": "د طلب شوی محصولو لیست",

        // branch request product
        "branch_request_product.assign_success_msg": "محصول په بریالیتوب سره اختصاص شو",
        "branch_request_product.request_product": "محصول طلب",
        "branch_request_product.create_product": "محصول جوړول",
        "branch_request_product.update_product": "محصول اوسمهالول",
        "branch_request_product.request_success_msg": "محصول طلب په بریالیتوب سره جوړ شو",
        "branch_request_product.request_update_success_msg": "محصول طلب په بریالیتوب سره اوسمهال شو",

        // product form
        "product_form.name_required": "محصول نوم ضروري ده",
        "product_form.type_required": "محصول ډول ضروري ده",
        "product_form.category_required": "محصول کټګوري ضروري ده",
        "product_form.price_required": "محصول قیمت ضروري ده",
        "product_form.duplicate_product": "تاسو دا محصول اضافه کړلای شي",
        "product_form.negative_price": "قیمت باید یوه مثبت عدد وي",
        "product_form.product_sale_threshold": "د زیاتر شوی مقدار",
        "product_form.product_sale_threshold_negative": "د زیاتر شوی مقدار باید یوه مثبت عدد وي",

        // product category
        "product_category.total_category": "ټول کټګورۍ",
        "product_category.category_list": "کټګورۍ لیست",
        "product_category.create_category": "کټګورۍ جوړول",
        "product_category.update_category": "کټګورۍ اوسمهالول",
        "product_category.create_success_msg": "کټګورۍ په بریالیتوب سره جوړ شو",
        "product_category.update_success_msg": "کټګورۍ په بریالیتوب سره اوسمهال شو",
        "product_category.category_data_not_available": "کټګورۍ معلومات شته نه",
        "product_category.category_name": "کټګورۍ نوم",
        "product_category.available_from": "د موجودې تاریخ له لاسه",
        "product_category.available_to": "د موجودې تاریخ تر څلورو",
        "product_category.available_days": "د موجودې ورځونو له لاسه",
        "product_category.available_week_days": "د موجودې ورځونو له لاسه",


        // global ingredient category
        "ingredient_category.ingredient_category_list": "د اوسه کې شامل شوی مواد کټګورۍ لیست",
        "ingredient_category.create_ingredient_category": "د اوسه کې شامل شوی مواد کټګورۍ جوړول",
        "ingredient_category.total_ingredient_category": "ټول اوسه کې شامل شوی مواد کټګورۍ",
        "ingredient_category.ingredient_category_name": "د اوسه کې شامل شوی مواد کټګورۍ نوم",
        "ingredient_category.description": "توضیحات",

        // ingredient
        "ingredient.ingredient_list": "د موادو لیست",
        "ingredient.create_ingredient": "مواد جوړول",
        "ingredient.total_ingredient": "ټول مواد",
        "ingredient.ingredient_name": "مواد نوم",
        "ingredient.description": "توضیحات",
        "ingredient.category_name": "کټګورۍ نوم",
        "ingredient.unit": "واحد",
        "ingredient.quantity": "مقدار",
        "ingredient.default_price": "پخواني قیمت",
        "ingredient.default_price_positive": "پخواني قیمت باید یوه مثبت عدد وي",
        "ingredient.required_ingredient_name": "مواد نوم ضروري ده",
        "ingredient.required_unit": "واحد ضروري ده",
        "ingredient.update_ingredient": "مواد اوسمهالول",
        "ingredient.approved_msg": "د مواد په برخه کې رازول شوی",
        "ingredient.unApproved_msg": "د مواد په برخه کې د رازولو ناورول",
        "ingredient.search_by_name": "د مواد په نوم سره لټون",
        "ingredient.select_unit": "واحد انتخاب کړئ",
        "ingredient.price_positive": "قیمت باید مثبت عدد وي",

        // مطلوب مواد
        "requested_ingredient.requested_ingredient_list": "مطلوب موادونه لیست",
        "requested_ingredient.total_requested_ingredient": "مجموعی مطلوب مواد",
        "requested_ingredient.requested_ingredient_name": "مطلوب مواد نوم",
        "requested_ingredient.update_requested_ingredient": "مطلوب مواد تازه کول",
        "requested_ingredient.create_success_msg": "مواد بریالیت",

        // مواد د برخه سره لومړني کټګورۍ
        "branch_ingredient.create_branch_ingredient": "د برخه مواد جوړول",
        "branch_ingredient.branch_ingredient_list": "د برخه موادونو لیست",
        "branch_ingredient.total_branch_ingredient": "مجموعی برخه مواد",
        "branch_ingredient.branch_ingredient_name": "برخه مواد نوم",
        "branch_ingredient.request_branch_ingredient": "د برخه مواد غوښتل",
        "branch_ingredient.create_request_branch_ingredient": "د برخه مواد غوښتل",
        "branch_ingredient.update_branch_ingredient": "مواد تازه کول",
        "branch_ingredient.update_ingredient": "مواد تازه کول",
        "branch_ingredient.quantity": "مقدار",
        "branch_ingredient.assign_supplier": "تامینونکی ورکول",
        "branch_ingredient.update_msg": "برخه مواد په برخه کې تازه کیږي",
        "branch_request_ingredient.assign_success_msg": "برخه مواد تامینونکی کېږي",

        // تامین کنندې
        "supplier.supplier_list": "تامینونکی لیست",
        "supplier.create_supplier": "تامینونکی جوړول",
        "supplier.update_supplier": "تامینونکی تازه کول",
        "supplier.total_supplier": "مجموعی تامینونکي",
        "supplier.supplier_name": "تامینونکي نوم",
        "supplier.description": "توضیحات",
        "supplier.update_successfully": "تامینونکی په برخه کې تازه کیږي",
        "supplier.supplier_name_required": "تامینونکي نوم ضروري ده",
        "supplier.supplier_data_unavailable": "تامینونکي معلومات د ناسیدو شوی",
        "supplier.supplier_assigned_msg": "تامینونکی په برخه کې ورکړل شو",

        // ایجنټ
        "agent.agent_list": "ایجنټ لیست",
        "agent.create_agent": "ایجنټ جوړول",
        "agent.update_agent": "ایجنټ تازه کول",
        "agent.add_agent": "ایجنټ اضافه کول",
        "agent.total_agent": "مجموعی ایجنټ",
        "agent.agent_name": "ایجنټ نوم",
        "agent.name": "نوم",
        "agent.email": "ایمل",
        "agent.mobile_number": "موبایل شمیره",
        "agent.description": "توضیحات",
        "agent.address": "پته",
        "agent.agent_create_msg": "ایجنټ په برخه کې جوړه شو",
        "agent.advance": "پیش-پیش",
        "agent.payments": "پیسې",

        // ایجنټ فورم
        "agent_form.name_required": "ایجنټ نوم ضروري ده",
        "agent_form.mobile_num_required": "موبایل شمیره ضروري ده",
        "agent_form.address_required": "پته ضروري ده",
        "agent.agent_update_msg": "ایجنټ تازه کړل شوه",

        // ایجنټ پیسې
        "agent_payment.cancel_msg": "ایجنټ پیسې پاخښل شوه",
        "agent_payment.payment_category": "پیسې کټګوری",
        "agent_payment.receive_amount": "مونږ ته ورکړی شوی",
        "agent_payment.cancel_payment": "ایجنټ پیسې پاخښل کول",
        "agent_payment.cancel_confirm_msg": "آیا څه چې څنګه پیسې پاخښلیدلی؟",

        // ایجنټ پیسې فورم
        "agent_payment_form.amount": "مقدار",
        "agent_payment_form.amount_required": "مقدار ضروري ده",
        "agent_payment_form.amount_positive": "مقدار باید لویی پیسې وي",
        "agent_payment_form.payment_category_required": "پیسې کټګوری ضروري ده",
        "agent_payment_form.received": "ورکړل شوه",
        "agent_payment_form.payment_type_required": "پیسې د قسم ضروري ده",
        "agent_payment_form.cash": "کیسه",
        "agent_payment_form.mfs": "د MFS",
        "agent_payment_form.payment_create_msg": "ایجنټ پیسې په برخه کې په بریالیت پېژندل شو",
        "agent_payment_form.payment_update_msg": "ایجنټ پیسې په برخه کې په بریالیت پېژندل شو",
        "agent_payment_form.payment_unavailable": "پیسې معلومات ناست",

        // تامین کنندې
        "supplier.assign_ingredient": "مواد ټاکل",
        "supplier.assign": "ټاکل",
        "supplier.ingredient_required": "مواد ضروري ده",
        "supplier.supplier_created_successfully": "تامینونکی په برخه کې په بریالیت پېژندل شوه",

        // د کټګورۍ محصول په شمول
        "product_category_form.required_category_name": "کټګورۍ نوم ضروري ده",

        // مېزانونه
        "product_add_ons.total_add_on": "مجموعی محصولات",
        "product_add_ons.add_ons_list": "محصولاتو لیست",
        "product_add_ons.update_add_ons": "محصولاتو تازه کول",
        "product_add_ons.branch_add_ons_list": "برخه محصولاتو لیست",
        "product_add_ons.create_add_ons": "محصولاتو جوړول",
        "product_add_ons.create_success_msg": "محصولات په برخه کې په بریالیت پېژندل شوه",
        "product_add_ons.update_success_msg": "محصولات په برخه کې په بریالیت پېژندل شوه",
        "product_add_ons.add_ons_not_available": "محصولات معلومات ناست",
        "product_add_ons.add_ons_name": "محصولات نوم",
        "product_add_ons.select_add_ons": "محصولات غوره کړئ",
        "product_add_ons.duplicate_add_ons": "دا محصولات له مخه کړل شوی ده",
        "product_add_ons.approve_msg": "محصولات په برخه کې په بریالیت پېژندل شوه",
        "product_add_ons.approve_status_msg": "محصولات په برخه کې په بریالیت پېژندل شوه",

        // محصولات فورم
        "product_add_ons_form.add_ons_name_required": "محصولات نوم ضروري ده",
        "product_add_ons_form.add_ons_price_negative": "محصولات قیمت باید یو مثبت عدد وي",

        // محصولات غوښتل
        "request_aad_ons.request_add_ons": "محصولات غوښتل",
        "request_aad_ons.create_success_msg": "محصولات په برخه کې په بریالیت پېژندل شوه",
        "request_aad_ons.update_success_msg": "محصولات په برخه کې په بریالیت پېژندل شوه",
        "request_aad_ons.create_request_add_ons": "محصولات غوښتل",
        "request_aad_ons.update_request_add_ons": "محصولات غوښتل",

        // برخه
        "branch.branch_list": "برخه لیست",
        "branch.branch": "برخه",
        "branch.create_branch": "برخه جوړول",
        "branch.create_success_msg": "برخه په بریالیت پېژندل شوه",
        "branch.branch_name": "برخه نوم",
        "branch.update_success_msg": "برخه په بریالیت پېژندل شوه",
        "branch.update_branch": "برخه تازه کول",
        "branch.branch_data_not_available": "برخه معلومات ناست",
        "branch.select_branch": "برخه غوره کړئ",
        "branch.branch_details": "برخه معلومات",
        "branch.employee": "کارمند",
        "branch.total_branch": "مجموعی برخه",

        // برخه فورم
        "branch_form.branch_name_required": "برخه نوم ضروري ده",
        "branch_form.select_restaurant": "رستوران غوره کړئ",
        "branch_form.restaurant_required": "رستوران ضروري ده",
        "branch_form.enabled": "فعال",
        "branch_form.delivery_radius": "پورته اطراف (میل)",
        "branch_form.delivery_radius_required": "پورته اطراف ضروري ده",
        "branch_form.branch_location": "برخه ځای",
        "branch_form.branch_location_required": "برخه ځای ضروري ده",
        "branch_form.order_types": "د رامنځتوب تنوعونه",
        "branch_form.opening_time": "خلاص وخت",
        "branch_form.closing_time": "بند وخت",
        "branch_form.deliver_radius_negative": "د توزيع دايرې شعاع باید لوړ څخه وسیله",

        // franchise
        "franchise.franchise_list": "فرانشایز لیست",
        "franchise.franchise": "فرانشایز",
        "franchise.create_franchise": "فرانشایز تخلیق کړئ",
        "franchise.create_success_msg": "فرانشایز په بریالیتوب سره جوړ شو",
        "franchise.franchise_name": "فرانشایز نوم",
        "franchise.update_success_msg": "فرانشایز په بریالیتوب سره اوسمهال شو",
        "franchise.update_franchise": "فرانشایز تازه کړئ",
        "franchise.franchise_data_not_available": "فرانشایز معلومات شته نه دی",
        "franchise.select_franchise": "فرانشایز انتخاب کړئ",
        "franchise.franchise_details": "فرانشایز معلومات",
        "franchise.employee": "کارکوونکی",
        "franchise.total_franchise": "کل فرانشایز",

        // franchise form
        "franchise_form.franchise_name_required": "فرانشایز نوم چاپیره ده",
        "franchise_form.select_restaurant": "رستوران انتخاب کړئ",
        "franchise_form.restaurant_required": "رستوران چاپیره ده",
        "franchise_form.enabled": "فعال شوی",
        "franchise_form.delivery_radius": "ترسره دهلیز مونږښت (مایل)",
        "franchise_form.delivery_radius_required": "ترسره دهلیز مونږښت چاپیره ده",
        "franchise_form.franchise_location": "فرانشایز ځای",
        "franchise_form.franchise_location_required": "فرانشایز ځای چاپیره ده",
        "franchise_form.order_types": "د سفارش ډولونه",
        "franchise_form.opening_time": "بیل کولو وخت",
        "franchise_form.closing_time": "بند کولو وخت",
        "franchise_form.deliver_radius_negative": "ترسره دهلیز مونږښت باید ډیره ورته نوم ولری",
        "franchise_form.franchise": "فرانشایز",

        // assign employee
        "assign_employee.select_employee": "کارمند غورځول",
        "assign_employee.success_msg": "کارمند په دې منځ کې په کامیابی سره ورسۍ",
        "assign_employee.employee_required": "کارمند په ملک کې ملاتړه شته",
        "assign_employee.branch_required": "منځ په ملک کې ملاتړه شته",

        // order
        "order.order_list": "د لږ تنظیم",
        "order.order_number": "د لږ نمبر",
        "order.customer_name": "ګیراکولو نوم",
        "order.status": "حالت",
        "order.order_type": "د لږ نوع",
        "order.payment_type": "د پیسې نوع",
        "order.paid": "پول ورکړل شوی",
        "order.create_order": "د لږ پیل کول",
        "order.update_order": "د لږ تازه کول",
        "order.order_details": "د لږ تفصیلات",
        "order.order_ifo": "د لږ معلومات",
        "order.customer_ifo": "ګیراکونو معلومات",
        "order.invoice": "اینوائس",
        "order.customer_id": "ګیراکولو شناخت",
        "order.total_price": "ټول قیمت",
        "order.total_product_price": "ټول محصول قیمت",
        "order.total_add_on_price": "ټول اضافی قیمت",
        "order.total_product": "ټول محصول",
        "order.total_add_on": "ټول اضافی",
        "order.promo_discount": "د وګورئی تخفیف",
        "order.point_amount": "نقطه مقدار",
        "order.final_price": "ورکړۍ قیمت",
        "order.not_available": "د لږ معلومات نه موندل شوی",
        "order.total_order": "ټول لږ",
        "order.order_type_required": "د لږ نوع مهم",
        "order.select_table": "د میز غورځول",
        "order.table_required": "میز مهمه",
        "order.discount_type": "تخفیف نوع",
        "order.discount": "تخفیف",
        "order.comment": "تبصره",
        "order.comment_required": "تبصره مهمه",
        "order.remarks": "ملنه",
        "order.guest_numbers": "مهمه مهمات",
        "order.event_date": "رویداد دې",
        "order.total_payable_amount": "ټول مدفوع قیمت",
        "order.invoice_number": "اینوائس نمبر",
        "order.guests": "مهمات",
        "order.generate_date": "پیدا کول شې",
        "order.generate_time": "پیدا وخت",
        "order.generate_by": "په خلک کې پیدا شوی",
        "order.change": "تغییر",
        "order.thank_order": "تشکر اوسمهاله",
        "order.visit": "سره سره",
        "order.to_online_order": "خپله غورځولو ته",
        "order.phone_no": "د ټلیفون شمیره",

        // branch product
        "branch_product.availability_msg": "د منځ محصولات ته لاسرسی موندل شوی",
        "branch_product.update_msg": "د منځ محصولات سره موندل شوی",
        "branch_product.related_product_success_msg": "مربوط محصول په کامیابی سره اضافه شوی",
        "branch_product.product_has_been": "محصول",
        "branch_product.successfully": "به کامیابی",
        "branch_product.enabled_small": "فعال",
        "branch_product.disabled_small": "غیر فعال",

        // promo
        "promo.total_promo": "کل پرومو",
        "promo.promo_code": "پرومو کوډ",
        "promo.promo_list": "پرومو لیست",
        "promo.create_promo": "پرومو جوړول",
        "promo.update_promo": "پرومو تازه کول",
        "prom.create_success_msg": "پرومو په کامیابی سره جوړ شوی",
        "prom.update_success_msg": "پرومو په کامیابی سره تازه شوی",
        "prom.data_not_available": "پرومو معلومات موجود نه دی",
        "prom.select_promo": "پرومو غوره کړئ",

        // promo form
        "promo_form.promo_code_required": "پرومو کوډ مهمه",
        "promo_form.valid_from": "د توکی شروع",
        "promo_form.valid_to": "د توکی پای",
        "promo_form.valid_from_required": "د شروع د توکی مهمه",
        "promo_form.valid_to_required": "د پای د توکی مهمه",
        "promo_form.price_type": "قیمت نوع",
        "promo_form.price_type_required": "قیمت نوع مهمه",
        "promo_form.flat_amount": "سطح مقدار",
        "promo_form.flat_amount_required": "سطح مقدار مهمه",
        "promo_form.percent": "فیصد",
        "promo_form.percent_required": "فیصد مهمه",
        "promo_form.applicable_percent_amount": "د موجود فیصد مقدار",
        "promo_form.applicable_order_amount": "د موجود لږ مقدار",
        "promo_form.flat_amount_negative": "سطح مقدار باید د اوږدوالو کې لوړتره وي",
        "promo_form.percent_negative": "فیصد باید د اوږدوالو کې لوړتره وي",
        "promo_form.percent_max_amount_negative": "فیصد بیشترین مقدار باید مثبت شمیره وي",
        "promo_form.min_order_amount_negative": "لږ ترتیب مقدار باید مثبت شمیره وي",
        "promo_form.desc": "تفصیل",

        // order type
        "order_type.delivery": "توزیع",
        "order_type.takeaway": "زموږه کړه",
        "order_type.dine_in": "خوراک لپاره",
        "order_type.catering": "کیټرینګ",

        // global config
        "global_config.global_config_list": "د ځانګړې پېژندل",
        "global_config.create_global_config": "د ځانګړې پېژندل جوړول",
        "global_config.update_global_config": "د ځانګړې پېژندل تازه کول",
        "global_config.create_msg": "د ځانګړې پېژندل په کامیابی سره جوړ شوی",
        "global_config.update_msg": "د ځانګړې پېژندل په کامیابی سره تازه شوی",
        "global_config.data_not_available": "د ځانګړې پېژندل معلومات موجود نه دی",
        "global_config.activate_confirm_title": "فعال کول ځانګړې پېژندل",
        "global_config.disable_confirm_title": "ناکارمل کول ځانګړې پېژندل",
        "global_config.activate_confirm_desc": "آیا تاسو څنګه د ځانګړې پېژندل فعالولای شئ؟",
        "global_config.disable_confirm_desc": "آیا تاسو څنګه د ځانګړې پېژندل ناکارمل کولای شئ؟",
        "global_config.active_success_msg": "د ځانګړې پېژندل په کامیابی سره فعاله شو",
        "global_config.disable_success_msg": "د ځانګړې پېژندل په کامیابی سره ناکارمل شو",

        // global config form
        "global_config_form.order_point": "د لږ د نقطې",
        "global_config_form.value_per_point": "د هر نقطه قیمت",
        "global_config_form.applicable_max_point_value": "د ټوله مخکې نقطې قیمت",
        "global_config_form.branch_search_area": "منځ زده کول محل (مایل)",
        "global_config_form.branch_search_area_negative": "د منځ زده کول محل باید مثبت شمیره وي",
        "global_config_form.branch_search_area_column": "منځ زده کول محل",
        "global_config_form.value_per_point_negative": "د هر نقطه قیمت باید مثبت شمیره وي",
        "global_config_form.applicable_max_point_value_negative": "د ټوله مخکې نقطې قیمت باید مثبت شمیره وي",
        "global_config_form.currency": "د کارنسۍ",
        "global_config_form.currency_required": "واپسی ده",
        "global_config_form.select_currency": "د کارنسۍ انتخاب کول",
        "global_config_form.order_cancel_feature": "د لیست لغو کول خصوصیت",
        "global_config_form.cancel_count_per_day": "د په ورځو لغوونکی شمیر",
        "global_config_form.cancel_count_per_day_negative": "د ورځو لغوونکو شمیر باید موجب شمیره وي",

        // banner
        "banner.banner_list": "بینر لیست",
        "banner.create_banner": "بینر جوړول",
        "banner.update_banner": "بینر تازه کول",
        "banner.total_banner": "ټوله بینر",
        "banner.banner_type": "بینر ډول",
        "banner.banner_title": "بینر سرلیک",
        "banner.banner_create_success": "بینر په کامیابی سره جوړ شوی",
        "banner.banner_update_success": "بینر په کامیابی سره تازه شوی",
        "banner.banner_data_not_available": "بینر معلومات موجود نه دی",
        "banner.sequence": "ځای",

        // banner form
        "banner_form.title_required": "بینر سرلیک مهمه",
        "banner_form.banner_type_required": "بینر ډول مهمه",
        "banner_form.promo_required": "پرومو مهمه",

        // restaurant dashboard
        "restaurant_dashboard.total_earning": "ټولټال اربګ",
        "restaurant_dashboard.total_earning_not_available": "ټولټال اربګ معلومات موجود نه دی",
        "restaurant_dashboard.top_branch": "نومخه شاخه",
        "restaurant_dashboard.earning_by_branch": "د شاخونو اربګ",
        "restaurant_dashboard.earning_by_branch_not_available": "د شاخونو اربګ معلومات موجود نه دی",
        "restaurant_dashboard.total_order": "ټولټال لیست",
        "restaurant_dashboard.total_order_not_available": "ټولټال لیست معلومات موجود نه دی",
        "restaurant_dashboard.order_by_branch": "د شاخونو لیست",
        "restaurant_dashboard.order_by_branch_not_available": "د شاخونو لیست معلومات موجود نه دی",
        "restaurant_dashboard.top_section_not_available": "ټوله خلاصه معلومات موجود نه دی",

        // branch dashboard
        "branch_dashboard.order_summary": "لیست لیکل",
        "branch_dashboard.completed_order": "بشپړ امر",
        "branch_dashboard.ongoing_order": "د مرسته امر",
        "branch_dashboard.cancel_order": "لغوه شوی امر",
        "branch_dashboard.completed": "بشپړ شوی",
        "branch_dashboard.ongoing": "د مرسته",
        "branch_dashboard.canceled": "لغوه شوی",
        "branch_dashboard.order_type_data_not_avl": "د امر ډول معلومات موجود نه دی",
        "branch_dashboard.order_summary_data_not_avl": "د لیست لیکل معلومات موجود نه دی",
        "branch_dashboard.top_products": "ټاپ محصولات",
        "branch_dashboard.top_product_not_avl": "ټاپ محصولات معلومات موجود نه دی",
        "branch_dashboard.order": "امر",
        "branch_dashboard.recent_order_reviews": "وروسته امر انتقادونه",
        "branch_dashboard.recent_order_reviews_not_avl": "وروسته امر انتقادونه معلومات موجود نه دی",
        "branch_dashboard.order_id": "امر شناخت",
        "branch_dashboard.reviewed_branch": "تبصره شوي شاخه",
        "branch_dashboard.data_time": "نیټه او وخت",
        "branch_dashboard.reviewed_by": "تبصره شوی له",
        "branch_dashboard.message": "پیغام",
        "branch_dashboard.rating": "ریتلونکی",
        "branch_dashboard.recent_product_reviews": "وروسته محصولات انتقادونه",
        "branch_dashboard.recent_product_reviews_not_avl": "وروسته محصولات انتقادونه معلومات موجود نه دی",
        "branch_dashboard.reviewed_product": "تبصره شوی محصول",
        "branch_dashboard.product_review": "محصول انتقاد",
        "branch_dashboard.order_review": "امر تبصره",

        // analytics
        "analytics.top_branches": "ټاپ شاخونه",
        "analytics.top_branch_not_avl": "ټاپ شاخونه معلومات موجود نه دی",

        // table
        "table.table_list": "میزونه لیست",
        "table.table_data_not_available": "میزونه معلومات موجود نه دی",
        "table.create_table": "میز جوړول",
        "table.update_table": "میز تازه کول",
        "table.create_success_msg": "میز په کامیابی سره جوړ شوی",
        "table.update_success_msg": "میز په کامیابی سره تازه شوی",
        "table_form.name_required": "میز شمیره مهمه",
        "table_form.table_name": "میز نوم",
        "table_form.description": "توضیحات",
        "table_form.max_person": "په نوم لرې موندل",
        "table_form.max_person_btwn": "په نوم لرې موندل باید ۱ له ۱۰۰ ترې",
        "table_form.max_person_required": "په نوم لرې موندل مهمه",

        // catering section
        "catering_section.catering_section_list": "لیست د پیشه سیکشنونه",
        "catering_section.create_catering_section": "پیشه سیکشن جوړول",
        "catering_section.catering_section_name": "سیکشن نوم",
        "catering_section.catering_section_name_required": "سیکشن نوم ضروری دی",
        "catering_section.note": "یاداشت",
        "catering_section.create_success_msg": "پیشه سیکشن په بریالی جوړ شو",
        "catering_section.update_success_msg": "پیشه سیکشن په بریالی تازه شو",
        "catering_section.data_not_available": "پیشه سیکشن معلومات د لاسرسی موجود نه دی",
        "catering_section.update_catering_section": "پیشه سیکشن تازه کول",
        "catering_section.catering_section": "پیشه سیکشن",

        // catering product
        "catering.request_catering_product": "پیشه محصول طلب",
        "catering.product_list": "پیشه محصولونه لیست",
        "catering.create_product": "پیشه محصول جوړول",
        "catering.update_product": "پیشه محصول تازه کول",
        "catering.create_product_success_msg": "پیشه محصول په بریالی جوړ شو",
        "catering.update_product_success_msg": "پیشه محصول په بریالی تازه شو",

        // catering menu
        "catering.catering_menu_list": "پیشه مینو لیست",
        "catering.create_catering_menu": "پیشه مینو جوړول",
        "catering.update_catering_menu": "پیشه مینو تازه کول",
        "catering.catering_menu_name": "پیشه مینو نوم",
        "catering.catering_menu_name_required": "پیشه مینو نوم ضروری دی",
        "catering.catering_menu_details": "پیشه مینو تفصیلات",
        "catering.branch_menu_assigned": "شاخه مینو په بریالی ورته دی",
        "catering.request_catering_menu": "پیشه مینو طلب",
        "catering.menu_name": "مینو نوم",
        "catering.add_at_least_one_item": "لړ کم او کم یوه توکی اضافه کړئ",
        "catering.duplicate": "تکثیر",
        "catering.item_already_added": "دا توکی انتها کړل شوی دی",
        "catering.add_alternative": "بدلی کول",
        "catering.empty_plus": "خالی پلس",
        "catering.item_can_selected": "توکی انتخاب شوی کولای شی",
        "catering.please_select_product": "مهربانی وکړئ محصول انتخاب کړئ",
        "catering.choice_of_item": "توکی د انتخاب",
        "catering.choice_of_item_required": "د انتخاب ضرورت دی",
        "catering.at_least_choice_of_item": "د انتخاب په کمترین حد باید ۱ واحد وي",
        "catering.menu_data_unavailable": "مینو معلومات د لاسرسی موجود نه دی",
        "catering.you_have_to_select": "تاسو باید لړ تر لړ انتخاب کړئ",
        "catering.because_choice": "چونکه تاسو د انتخاب باید ولرئ",
        "catering.catering_menu_create_msg": "پیشه مینو په بریالی جوړ شو",
        "catering.catering_menu_update_msg": "پیشه مینو په بریالی تازه شو",
        "catering.catering_menu_has_been": "پیشه مینو بهرنی شوی",
        "catering.the_catering_menu": "پیشه مینو",

        // catering order
        "catering.catering_order_list": "پیشه طلب لیست",
        "catering.create_catering_order": "پیشه طلب جوړول",
        "catering.update_catering_order": "پیشه طلب تازه کول",
        "catering.billed_to": "د محاسبه ته ورکړل شوی",
        "catering.invoice_no": "د رسید شمیره",
        "catering.no_of_guests": "مهمانونه شمیره",
        "catering.including_tax": "مالیات سره",
        "catering.invoice_total": "د رسید کل",
        "catering.event_details": "پیښه معلومات",
        "catering.signature": "د لمبې ورکولو په ګوره",
        "catering.client_signature": "د مشتری لمبی",
        "catering.thank_you_for_coming": "مهرباني دلته یې خوش آمدید",

        // catering order form
        "catering_order_form.included": "شامل",
        "catering_order_form.additional": "اضافی",
        "catering_order_form.current_order": "اوسنی طلب",
        "catering_order_form.all_catering_menu": "ټول پیشه مینو",
        "catering_order_form.enter_guest": "مهمان داخل کړئ",
        "catering_order_form.select_catering_menu": "پیشه مینو انتخاب کړئ",
        "catering_order_form.menu_section": "مینو سکشن",
        "catering_order_form.select_menu": "مینو انتخاب کړئ",
        "catering_order_form.confirm": "تصدیق",
        "catering_order_form.alternative_items": "بدلی مواد",
        "catering_order_form.choose": "غوره کول",
        "catering_order_form.item_here": "د لمبی څخه",
        "catering_order_form.selected_items": "انتخاب شوی مواد",
        "catering_order_form.selected": "انتخاب شوی",
        "catering_order_form.select_category": "د زمونږ موادو د ورکړې انتخاب",
        "catering_order_form.search_product_name": "د مواد نوم پلټنه",
        "catering_order_form.choice_of": "د انتخاب",
        "catering_order_form.percentage": "د نسبت",
        "catering_order_form.flat": "تخته",
        "catering_order_form.note_optional": "یاداشت (اختیاري)",
        "catering_order_form.select_event_date": "پیښې نیټه انتخاب کړئ",
        "catering_order_form.event_location": "پیښې ځای",
        "catering_order_form.ny_sales_tax": "د مالیاتونو",
        "catering_order_form.event_date_required": "پیښه نیټه ضروری دی، مهرباني وکړئ خپله پیښې نیټه انتخاب کړئ",
        "catering_order_form.customer_required": "مشتری ضروری دی، مهرباني وکړئ مشتری انتخاب کړئ",
        "catering_order_form.least_one_menu": "خوا لړمه په کمترین حد وی، مهرباني وکړئ یوه مینو اضافه کړئ",
        "catering_order_form.catering_invoice": "پیشه رسید",

        // all
        "product.catering_type": "پیشه",

        // stock
        "stock.stock_list": "د سټاک فهرست",
        "stock.stock_small": "سټاک",
        "stock.create_stock": "جوړول",
        "stock.update_stock": "تازه کول",
        "stock.total_stock": "ټول سټاک",
        "stock.stock_number": "سټاک نمبر",
        "stock.stock_details": "سټاک جزئیات",
        "stock.paid_amount": "ادا شوی مقدار",
        "stock.stock_type": "سټاک ډول",
        "stock.stock_data_unavailable": "سټاک معلومات د لاسرسی موجود نه دی",
        "stock.stock_create_msg": "سټاک په بریالی جوړ شو",
        "stock.stock_update_msg": "سټاک په بریالی تازه شو",
        "stock.expired_at": "پای ته پای",
        "stock.expired_date": "پایيدنې نیټه",
        "stock.available_qty": "د موجودې مقدار",

        // stock details
        "stock_details.ingredient_info": "مواد معلومات",
        "stock_details.stock_info": "سټاک معلومات",
        "stock_details.agent_info": "نماینده معلومات",
        "stock_details.balance": "ترازه",
        "stock_details.total_spent": "ټوله خرڅولو",

        // stock in form
        "stock_in_form.one_item_required": "مهرباني وکړئ کم او کم یوه مواد انتخاب کړئ",
        "stock_in_form.mfg_date": "پراوسته کړه نیټه",
        "stock_in_form.expire_date": "پای نیټه",
        "stock_in_form.select_supplier": "عرضه کونکی انتخاب کړئ",
        "stock_in_form.select_ingredient": "مواد انتخاب کړئ",
        "stock_in_form.ingredient_required": "مواد ضروری دی",
        "stock_in_form.unit_price": "واحد قیمت",
        "stock_in_form.unit_price_max_zero": "واحد قیمت باید صفر یا ډېر وي",
        "stock_in_form.qty_max_one": "مقدار باید یوه یا ډېر وي",
        "stock_in_form.due_amount": "مقدار",
        "stock_in_form.voucher_number": "وچر نمبر",
        "stock_in_form.attachment": "سپرښت",
        "stock_in_form.stock_date": "سټاک نیټه",
        "stock_in_form.select_agent": "نماینده انتخاب کړئ",
        "stock_in_form.select_date": "نیټه انتخاب کړئ",
        "stock_in_form.threshold": "حد",
        "stock_in_form.threshold_max_one": "حد باید یوه مثبت عدد وي",

        // stock summary
        "stock_summary.stock_summary_list": "سټاک خلاصې فهرست",
        "stock_summary.stock_summary_details": "سټاک خلاصې جزئیات",
        "stock_summary.total_stock_item": "ټوله سټاک مواد",
        "stock_summary.total_stock_summary": "ټوله سټاک خلاصې",
        "stock_summary.available": "موجوده",
        "stock_summary.unavailable": "غیر د موجودې",
        "stock_summary.select_stock_availability": "سټاک موجودیت انتخاب کړئ",
        "stock_summary.stock_summary_download": "سټاک خلاصې داونلوډ",

        // stock out
        "stock_out.stock_out_list": "سټاک خلاصې فهرست",
        "stock_out.total_stock_out": "ټول سټاک خلاصې",
        "stock_out.stock_out_details": "سټاک خلاصې جزئیات",
        "stock.stock_out_msg": "سټاک بریالی خلاصې شو",
        "stock.stock_out_update_msg": "سټاک بریالی تازه شو",
        "stock.out_of_stock": "سټاک پای ته رسیدل",
        "stock.stock_out_small": "سټاک بریالی",

        // stock_out_form
        "stock_out_form.select_stock": "سټاک انتخاب کړئ",
        "stock_out_form.stock_required": "سټاک ضروری دی",
        "stock_out_form.qty_required": "مقدار ضروری دی",
        "stock_out_form.qty_between": "مقدار باید ۱ او اوسه",
        "stock_out_form.stock_type_required": "سټاک ډول ضروری دی",
        "stock_out_form.missing": "غوښتل شوی",
        "stock_out_form.out": "بریالی",
        "stock_out_form.spoilage": "پیچوونکی",
        "stock_out_form.wastage": "ضایع شوی",

        // stock_summary
        "stock_summary.create_date_range": "نیول شوی نیټه اندازه",
        "stock_summary.stock_date_range": "سټاک نیټه اندازه",
        "stock_summary.expire_date_range": "پای نیټه اندازه",

        // branch_config
        "branch_config.branch_config_list": "شعبه سازمان جزئیات",
        "branch_config.create_branch_config": "شعبه سازمان جوړول",
        "branch_config.update_branch_config": "شعبه سازمان تازه کول",
        "branch_config.create_msg": "شعبه سازمان بریالی جوړ شو",
        "branch_config.update_msg": "شعبه سازمان بریالی تازه شو",
        "branch_config.data_not_available": "شعبه سازمان معلومات د لاسرسی موجود نه دی",
        "branch_config.activate_confirm_title": "شعبه سازمان فعالول",
        "branch_config.disable_confirm_title": "شعبه سازمان غیر فعالول",
        "branch_config.activate_confirm_desc": "آیا تاسو مطمئن یاست چی می خواهید شعبه سازمان فعال کړئ؟",
        "branch_config.disable_confirm_desc": "آیا تاسو مطمئن یاست چی می خواهید شعبه سازمان غیر فعال کړئ؟",
        "branch_config.active_success_msg": "شعبه سازمان بریالی فعال شو",
        "branch_config.disable_success_msg": "شعبه سازمان بریالی غیر فعال شو",
        "branch_config.mx_cancel_count": "زیاتون له خوندۍ پای ته رسیدل",
        "branch_config.order_cancel_time": "د خریداری کولو وخت (دقیقه)",
        "branch_config.tax_percentage": "د مالیه د درصد",
        "branch_config.order_cancel_time_required": "د خریدارۍ کولو وخت ضروري دی",
        "branch_config.order_cancel_time_least": "دقیقو د خریدارۍ کولو وخت باید کم اوسه ۵",
        "branch_config.tax_percentage_greater": "د مالیه د درصد باید زیاتر وی از ۰",

        // agent_payment
        "agent_payment.total_agent_payment": "ټوله نمایندونه",
        "agent_payment.agent_payment_list": "نماینده د پیسو جزئیات",
        "agent_payment.create_agent_payment": "نماینده د پیسو جوړول",
        "agent_payment.update_agent_payment": "نماینده د پیسو تازه کول",

        // expired_stock
        "expire_stock.expired_stock_list": "پای نیټه رسیدل سټاک فهرست",

        // pos_left_menu
        "pos_left_menu.all_items": "ټول مواد",

        // pos_middle_content
        "pos_middle_content.its_all_msg": "دا همه دی، هیڅ اضافه نه دی",
        "pos_middle_content.out_of_stock": "سټاک پای ته رسیدل",
        "pos_middle_content.search_place_holder": "د نوم یا ګډونکی لخوا مواد لټون کړئ",
        "pos_middle_content.dine_in": "ځای میز",

        // pos_cart
        "pos_cart.cart": "کارټ",
        "pos_cart.payment": "ادا کړه",
        "pos_cart.clear_cart": "کارټ پاکول",
        "pos_cart.total_items": "ټوله موادونه",
        "pos_cart.sub_total": "فرعي مجموعه",
        "pos_cart.sale_tax": "نیویارک د مالیاتونو",
        "pos_cart.hold": "وساتل",
        "pos_cart.save_print_kot": "ساتل او پرینټ کړه",
        "pos_cart.save_print_bill": "ساتل او پرینټ کړه",
        "pos_cart.item_name": "مواد نوم",
        "pos_cart.delete": "حذف",
        "pos_cart.qty": "مقدار",
        "pos_cart.table_duplicate_add_msg": "د میز د اضافه کولو مخ کړل شوه",
        "pos_cart.table_no": "میز نمبر",
        "pos_cart.order_note": "خریداری یاداشت",
        "pos_cart.order_instruction_place_holder": "هر څه خریدارۍ تلویز کولو لپاره، سوس یا سپائس وغیره",
        "pos_cart.select_customer": "مشتری انتخاب کړئ",
        "pos_cart.new_customer": "نوی مشتری",
        "pos_cart.no_of_guest": "میزونو نمبر",
        "pos_cart.table_number": "میز نمبر",
        "pos_cart.paid_amount_small": "ادا شوی مقدار",
        "pos_cart.tables": "میزونه",
        "pos_cart.online": "آنلاین",
        "pos_cart.tips": "پوره",
        "pos_cart.table_already_added": "دا میز اضافه کړل شوی دی",
        "pos_cart.select_delivery_address": "سپارښت پته انتخاب کړئ",
        "pos_cart.address_title": "پته عنوان",
        "pos_cart.address_title_placeholder": "پته عنوان مثلاً دفتر",
        "pos_cart.select_branch_location": "خپله شعبه موقعیت انتخاب کړئ",

        // pos order list
        "pos_order_list.priority_updated": "اولویت تازه شو",
        "pos_order_list.successfully": "باموفقیت",
        "pos_order_list.priority_has_been": "اولویت به ته رسیده",
        "pos_order_list.set": "رسیدل",
        "pos_order_list.canceled_small": "لغو شو",
        "pos_order_list.order_cancel_msg": "د خریدارۍ بریالی لغو شوه",
        "pos_order_list.update_status": "حالت تازه کول",
        "pos_order_list.payment_status": "ادا کړنی حالت",
        "pos_order_list.table_view": "میز وګورئ",
        "pos_order_list.hold_list": "پرلې غورځولو لیست",
        "pos_order_list.cancel_priority": "اولویت لغو کول",
        "pos_order_list.set_priority": "اولویت رسیدل",
        "pos_order_list.print_kot": "KOT پرینټ کړئ",
        "pos_order_list.print_bill": "بیل پرینټ کړئ",
        "pos_order_list.cancel_order": "د خریدارۍ لغوول",
        "pos_order_list.edit_order": "د خریدارۍ ترتیب کول",
        "pos_order_list.order_edit_confirm_msg": "آیا تاسو مطمئن یاست چی دا خریدارۍ ترتیب کول؟",
        "pos_order_list.order_clear_msg": "تاسو تر له مخونو پخپله خریدارۍ باید حذف کړئ",
        "pos_order_list.order_date_time": "خریدارۍ نیټه او وخت",
        "pos_order_list.unpaid": "ادا نه شوی",
        "pos_order_list.no_guest": "میزونو نمبر",
        "pos_order_list.order_status": "خریدارۍ حالت",
        "pos_order_list.customer_details": "مشتری معلومات",
        "pos_order_list.delivery_address": "سپارښت پته",
        "pos_order_list.payment_details": "ادا کړه معلومات",
        "pos_order_list.total_paid": "ټوله ادا شوی",
        "pos_order_list.total_due": "ټوله موقوفه",
        "pos_order_list.payment_method": "ادا کړه وسیله",
        "pos_order_list.total": "ټوله",
        "pos_order_list.add_qty": "مقدار اضافه کړئ",
        "pos_order_list.add_price": "قیمت اضافه کړئ",
        "pos_order_list.add_on_total": "ټوله اضافه",
        "pos_order_list.item_details": "مواد تفصیل",
        "pos_order_list.pint_discount": "نقطه تخفیف",
        "pos_order_list.search_order_by_id": "د خریدارۍ ID په واسطه پلټنه",
        "pos_order_list.assign_delivery_partner": "سپارښتی نښه شوی مخ کړئ",
        "pos_order_list.assign": "نښه کول",
        "pos_order_list.track_order": "خریدارۍ دپلټنې",

        // pos hold list
        "pos_hold_list.resume_hold_order": "پرلې غورځولو خریدارۍ بیرته کړئ",
        "pos_hold_list.confirm_hold_order_resume": "آیا تاسو مطمئن یاست چی دا پرلې غورځولو خریدارۍ بیرته کړئ؟",
        "pos_hold_list.remove_hold_order": "پرلې غورځولو خریدارۍ لغو کړئ",
        "pos_hold_list.confirm_remove_hold_order": "آیا تاسو مطمئن یاست چی دا پرلې غورځولو خریدارۍ لغو کړئ؟",

        // random user
        "user_form.please_assign_role": "لطفا نقش ورکړئ",
        "user_form.assign_role": "نقش ورکړئ",
        "user_form.search_role": "نقش لټون",
        "user_form.ok": "سم",
        "user.change_password": "پاسورډ بدل کړئ",
        "user.assigned_role_list": "نامزد شوی نقشونه لیست",
        "user.assigned_roles": "نامزد شوی نقشونه",
        "user.branch_manager": "د شعبې منیجر",
        "user.waiter": "پېښور",
        "user.chef": "پخور",
        "user.franchise_owner": " فرانشايز دار",

        // analytics
        "analytics.order_type": "د امر خوښونه",
        "analytics.customer_type": "د پیرودونکی ډول",
        "analytics.total_number": "کل نمره",
        "analytics.customer_map": "پیرودونکی نقشه",
        "analytics.no_data": "هیڅ معلومات نشته",
        "analytics.new_customer": "نوی پیرودونکی",
        "analytics.retained_customer": "په سر کښې د پیرودونکی",
        "analytics.excel": "ایکسیل",
        "analytics.pdf": "پی ډی اف",
        "analytics.customer_count": "پیرودونکی شمیر",
        "analytics.restaurant_name": "رستوران نوم",
        "analytics.customer": "پیرودونکی",
        "analytics.total_customer": "کل پیرودونکی",
        "analytics.order_count": "د امر شمیر",
        "analytics.sl": "سیل",
        "analytics.start_date": "د شروع تاریخ",
        "analytics.end_date": "د ختم تاریخ",
        "analytics.generated_at": "پیدا شوی د",
        "analytics.all_right_reserved": "په تمام حقوق څخه",

        // cart
        "cart.save_order": "ذخیره کول",
        "cart.complete_order": "کامله کول"

    }
}
