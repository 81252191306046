import {Locales} from "../Locales";

export const en_US = {
    [Locales.english]: {
        // common
        "common.error": "Error",
        "common.created": "Created",
        "common.added": "Added",
        "common.updated": "Updated",
        "common.submit": "Submit",
        "common.email": "Email",
        "common.password": "Password",
        "common.all": "All",
        "common.action": "Action",
        "common.reset": "Reset",
        "common.search_list": "Search List",
        "common.select_language": "Select Language",
        "common.language_required": "Please select a language",
        "common.image": "Image",
        "common.address": "Address",
        "common.city": "City",
        "common.country": "Country",
        "common.zipcode": "ZIP Code",
        "common.assigned": "Assigned",
        "common.yes": "YES",
        "common.no": "NO",
        "common.approved": "Approved",
        "common.approve": "Approve",
        "common.approve_small": "approve",
        "common.disable": "Disable",
        "common.disable_small": "disable",
        "common.password_changed": "Password Changed",
        "common.confirm_msg": "Are you sure you want to",
        "common.the_order": "the order",
        "common.the_product": "the product",
        "common.the_promo": "the promo",
        "common.the_banner": "the banner",
        "common.the_add_ons": "the add-ons",
        "common.success_msg_has": "has been",
        "common.success": "successfully",
        "common.activated": "Activated",
        "common.mile": "Mile",
        "common.create": "Create",
        "common.update": "Update",
        "common.optional": "Optional",
        "common.add": "Add",
        "common.created_at": "Created At",
        "common.created_date": "Created Date",
        "common.cancelled": "Canceled",
        "common.download": "Download",
        "common.date": "Date",
        "common.items": "Items",
        "common.assign_to_branch": "Assign To Your Branch",
        "common.edit": "Edit",
        "common.details": "Details",

        // nav header
        "nav_header.profile": "Profile",
        "nav_header.logout": "Logout",
        "nav_header.english": "English",
        "nav_header.bengali": "Bengali",
        "nav_header.urdu": "Urdu",
        "nav_header.hindi": "Hindi",
        "nav_header.pashto": "Pashto",

        // aside left
        "aside_left.dashboard": "Dashboard",
        "aside_left.user_management": "User Management",
        "aside_left.user_list": "User List",
        "aside_left.product_management": "Product Management",
        "aside_left.product": "Product",
        "aside_left.product_category": "Category",
        "aside_left.product_add_ons": "Add-Ons",
        "aside_left.branch": "Branch",
        "aside_left.branch_list": "Branch List",
        "aside_left.orders": "Orders",
        "aside_left.roles": "Roles",
        "aside_left.assign_employee": "Assign Employee",
        "aside_left.requested_product": "Requested Product",
        "aside_left.requested_add_ons_list": "Requested Add-Ons",
        "aside_left.branch_product": "Branch Product",
        "aside_left.branch_add_ons": "Branch Add-Ons",
        "aside_left.promo": "Promo",
        "aside_left.global": "Global",
        "aside_left.employee_list": "Employee List",
        "aside_left.customer_list": "Customer List",
        "aside_left.global_config": "Global Config",
        "aside_left.customer": "Customer",
        "aside_left.banner": "Banner",
        "aside_left.analytics": "Analytics",
        "aside_left.catering": "Catering",
        "aside_left.catering_products": "Catering Products",
        "aside_left.catering_sections": "Catering Sections",
        "aside_left.catering_menu": "Catering Menu",
        "aside_left.catering_order": "Catering Orders",
        "aside_left.kitchen_display": "Kitchen Display",
        "aside_left.pos": "POS",
        "aside_left.ingredient_management": "Ingredient Management",
        "aside_left.ingredient_category": "Ingredient Category",
        "aside_left.ingredient": "Ingredient",
        "aside_left.requested_ingredient": "Requested Ingredient",
        "aside_left.supplier": "Supplier",
        "aside_left.stock_management": "Stock Management",
        "aside_left.stock": "Stock",
        "aside_left.stock_in": "Stock In",
        "aside_left.stock_out": "Stock Out",
        "aside_left.expense": "Expense",
        "aside_left.agent": "Agent",
        "aside_left.agent_management": "Agent Management",
        "aside_left.agent_payment": "Agent Payment",
        "aside_left.branch_config": "Branch Config",
        "aside_left.stock_summary": "Stock Summary",
        "aside_left.expired_stock": "Expired Stock",
        "aside_left.table": "Table",
        "aside_left.requested_products": "Requested Products",
        "aside_left.catering_product": "Catering Product",
        "aside_left.order": "Order",
        "aside_left.requested_menu": "Requested Menu",
        "aside_left.franchises": "Franchises",
        "aside_left.order_management": "Order Management",
        "aside_left.order_reports": "Order Reports",
        "aside_left.order_item_reports": "Order Item Reports",
        "aside_left.order_history": "Order History",
        "aside_left.franchise_users": "Franchise Users",
        "aside_left.franchise_list": "Franchise List",

        // owner login
        "login.email_required_msg": "Email is required",
        "login.valid_email_msg": "Please enter a valid email",
        "login.password_required_msg": "Password is required",
        "login.sign_in": "Sign In",
        "login.sign_in_msg": "Enter your email and password to sign in!",

        // user
        "user.total_employee": "Total Employee",
        "user.create_user": "Create User",
        "user.update_user": "Update User",
        "user.full_name": "Full Name",
        "user.create_success_msg": "User has been created successfully",
        "user.update_success_msg": "User has been updated successfully",
        "user.user_data_not_available": "User data not available",
        "user.profile_picture": "Profile Picture",
        "user.phone_number": "Phone Number",
        "user.date_of_birth": "Date Of Birth",
        "user.gender": "Gender",
        "user.active": "Active",
        "user.disabled": "Disabled",
        "user.banned": "Banned",
        "user.verified": "Verified",
        "user.email_verified": "Email Verified",
        "user.phone_verified": "Phone Verified",
        "user.profile": "Profile",
        "user.profile_update_msg": "Profile has been updated successfully",
        "user.changed_password_msg": "Password has been changed successfully",
        "user.create_employee": "Create Employee",
        "user.update_employee": "Update Employee",
        "user.select_role": "Please select role",

        // user form
        "user_form.full_name_required": "Full name is required",
        "user_form.email_required": "Email name is required",
        "user_form.male": "Male",
        "user_form.female": "Female",
        "user_form.others": "Others",
        "user_form.role_required": "Role is required",
        "user_form.phone_number_required": "Phone number is required",
        "user_form.invalid_email": "Invalid email",
        "user_form.hotline": "Hotline",
        "user_form.hotline_required": "Hotline is required",

        // customer
        "customer.total_customer": "Total Customer",
        "customer.customer_list": "Customer List",
        "customer.customer_name": "Customer Name",
        "customer.customer_create_msg": "Customer has been created successfully",
        "customer.invalid_phone": "Invalid phone number",
        "customer.customer_name_required": "Customer name is required",
        "customer.cancel": "Cancel",
        "customer.save": "Save",
        "customer.customer_phone": "Customer Phone",

        // role
        "role.role_list": "Role List",
        "role.roles": "Roles",
        "role.create_role": "Create Role",
        "role.update_role": "Update Role",
        "role.create_success_msg": "Role has been created successfully",
        "role.update_success_msg": "Role has been updated successfully",
        "role.role_data_not_available": "Role data not available",
        "role.alias": "Alias",
        "role.role_level": "Role Level",
        "role.select_role": "Select Role",

        // role form
        "role_form.predefine_role_msg": "This is a predefine role so you can not edit the alias",
        "role_form.alias_required_msg": "Alias is required",
        "role_form.all": "All",
        "role_form.system": "SYSTEM",
        "role_form.restaurant": "RESTAURANT",
        "role_form.branch": "BRANCH",
        "role_form.customer": "CUSTOMER",
        "role_form.role_level_required": "Role level is required",

        // product
        "product.product_list": "Product List",
        "product.previous_product_list": "Previous Order Product List",
        "product.branch_product_list": "Branch Product List",
        "product.create_product": "Create Product",
        "product.name": "Product Name",
        "product.create_success_msg": "Product has been created successfully",
        "product.update_success_msg": "Product has been updated successfully",
        "product.update_product": "Update Product",
        "product.product_not_available": "Product data not available",
        "product.type": "Product Type",
        "product.select_type": "Select Product Type",
        "product.category": "Product Category",
        "product.price": "Price",
        "product.description": "Description",
        "product.single_type": "SINGLE",
        "product.combo_type": "COMBO",
        "product.select_product": "Select Product",
        "product.enable": "Enable",
        "product.quantity": "Quantity",
        "product.discount_percent": "Discount Percent",
        "product.discount_percent_negative": "Discount percent must be a positive number",
        "product.approved_msg": "Product has been approved successfully",
        "product.approved_status_msg": "the product",
        "product.sold_unit": "Sold Unit",
        "product.combo_items": "Combo Items",
        "product.popular": "Popular",
        "product.change_popular_status": "Change Popular Status",
        "product.popular_status_sure": "Are you sure you want to change the popular status?",
        "product.related_products": "Related Products",
        "product.shortcut_key": "Shortcut Key",
        "product.product_small": "product",

        // requested product
        "requested_product.requested_product_list": "Requested Product List",

        // branch request product
        "branch_request_product.assign_success_msg": "Product has been assigned successfully",
        "branch_request_product.request_product": "Request Product",
        "branch_request_product.create_product": "Create Request Product",
        "branch_request_product.update_product": "Update Request Product",
        "branch_request_product.request_success_msg": "Product request has been created successfully",
        "branch_request_product.request_update_success_msg": "Product request has been updated successfully",

        // product form
        "product_form.name_required": "Product name is required",
        "product_form.type_required": "Product type is required",
        "product_form.category_required": "Product category is required",
        "product_form.price_required": "Product price is required",
        "product_form.duplicate_product": "You have already added this product",
        "product_form.negative_price": "Price must be greater than zero",
        "product_form.product_sale_threshold": "Max Sale Quantity",
        "product_form.product_sale_threshold_negative": "Max sale quantity must be a positive",

        // product category
        "product_category.total_category": "Total Category",
        "product_category.category_list": "Category List",
        "product_category.create_category": "Create Category",
        "product_category.update_category": "Update Category",
        "product_category.create_success_msg": "Category has been created successfully",
        "product_category.update_success_msg": "Category has been updated successfully",
        "product_category.category_data_not_available": "Category data not available",
        "product_category.category_name": "Category Name",
        "product_category.available_from": "Available From",
        "product_category.available_to": "Available To",
        "product_category.available_days": "Available Days",
        "product_category.available_week_days": "Available Week Days",


        // global ingredient category
        "ingredient_category.ingredient_category_list": "Ingredient Category List",
        "ingredient_category.create_ingredient_category": "Create ingredient Category",
        "ingredient_category.total_ingredient_category": "Total ingredient Category",
        "ingredient_category.ingredient_category_name": "Ingredient Category Name",
        "ingredient_category.description": "Description",

        // ingredient
        "ingredient.ingredient_list": "Ingredient List",
        "ingredient.create_ingredient": "Create Ingredient",
        "ingredient.total_ingredient": "Total branch ingredient",
        "ingredient.ingredient_name": "Ingredient Name",
        "ingredient.description": "Description",
        "ingredient.category_name": "Category Name",
        "ingredient.unit": "Unit",
        "ingredient.quantity": "Quantity",
        "ingredient.default_price": "Default Price",
        "ingredient.default_price_positive": "Default price must be a positive number",
        "ingredient.required_ingredient_name": "Ingredient name required",
        "ingredient.required_unit": "Unit required",
        "ingredient.update_ingredient": "Update Ingredient",
        "ingredient.approved_msg": "Ingredient has been approved successfully",
        "ingredient.unApproved_msg": "Ingredient has been unapproved successfully",
        "ingredient.search_by_name": "Search by ingredient name",
        "ingredient.select_unit": "Select Unit",
        "ingredient.price_positive": "Price should be a positive number",

        // requested ingredient
        "requested_ingredient.requested_ingredient_list": "Requested Ingredient List",
        "requested_ingredient.total_requested_ingredient": "Total requested ingredient",
        "requested_ingredient.requested_ingredient_name": "Requested Ingredient Name",
        "requested_ingredient.update_requested_ingredient": "Update Requested Ingredient",
        "requested_ingredient.create_success_msg": "Ingredient create successfully",

        //branch global_ingredient category
        "branch_ingredient.create_branch_ingredient": "Create Branch Ingredient",
        "branch_ingredient.branch_ingredient_list": "Branch Ingredient List",
        "branch_ingredient.total_branch_ingredient": "Total branch ingredient",
        "branch_ingredient.branch_ingredient_name": "Branch ingredient Name",
        "branch_ingredient.request_branch_ingredient": "Request Branch Ingredient",
        "branch_ingredient.create_request_branch_ingredient": "Request Branch Ingredient",
        "branch_ingredient.update_branch_ingredient": "Update Branch Ingredient",
        "branch_ingredient.update_ingredient": "Update Ingredient",
        "branch_ingredient.quantity": "Quantity",
        "branch_ingredient.assign_supplier": "Assign Supplier",
        "branch_ingredient.update_msg": "Branch ingredient has been updated successfully",
        "branch_request_ingredient.assign_success_msg": "Branch Ingredient has been assigned successfully",

        //supplier
        "supplier.supplier_list": "Supplier List",
        "supplier.create_supplier": "Create Supplier",
        "supplier.update_supplier": "Update Supplier",
        "supplier.total_supplier": "Total supplier",
        "supplier.supplier_name": "Supplier Name",
        "supplier.description": "Description",
        "supplier.update_successfully": "Supplier update successfully",
        "supplier.supplier_name_required": "Supplier name is required",
        "supplier.supplier_data_unavailable": "Supplier data not found",
        "supplier.supplier_assigned_msg": "Supplier has been assigned successfully",

        //agent
        "agent.agent_list": "Agent List",
        "agent.create_agent": "Create Agent",
        "agent.update_agent": "Update Agent",
        "agent.add_agent": "Add Agent",
        "agent.total_agent": "Total Agents",
        "agent.agent_name": "Agent Name",
        "agent.name": "Name",
        "agent.email": "Email",
        "agent.mobile_number": "Mobile Number",
        "agent.description": "Description",
        "agent.address": "Address",
        "agent.agent_create_msg": "Agent has been created successfully",
        "agent.advance": "Advance",
        "agent.payments": "Payments",

        // agent form
        "agent_form.name_required": "Agent name is required",
        "agent_form.mobile_num_required": "Mobile number is required",
        "agent_form.address_required": "Address is required",
        "agent.agent_update_msg": "Agent has been updated successfully",

        // agent payment
        "agent_payment.cancel_msg": "Agent payment has been canceled successfully",
        "agent_payment.payment_category": "Payment Category",
        "agent_payment.receive_amount": "Receive Amount",
        "agent_payment.cancel_payment": "Cancel Agent Payment",
        "agent_payment.cancel_confirm_msg": "Are you sure you want to cancel the agent payment",

        // agent payment form
        "agent_payment_form.amount": "Amount",
        "agent_payment_form.amount_required": "Amount is required",
        "agent_payment_form.amount_positive": "Amount must be greater than 0",
        "agent_payment_form.payment_category_required": "Payment category is required",
        "agent_payment_form.received": "Received",
        "agent_payment_form.payment_type_required": "Payment type is required",
        "agent_payment_form.cash": "Cash",
        "agent_payment_form.mfs": "MFS",
        "agent_payment_form.payment_create_msg": "Agent payment has been created successfully",
        "agent_payment_form.payment_update_msg": "Agent payment has been updated successfully",
        "agent_payment_form.payment_unavailable": "Payment data not available",

        //branch supplier
        "supplier.assign_ingredient": "Assign Ingredient",
        "supplier.assign": "Assign",
        "supplier.ingredient_required": "Ingredient required",
        "supplier.supplier_created_successfully": "Supplier Created Successfully",

        // product category form
        "product_category_form.required_category_name": "Category name is required",

        // add-ons
        "product_add_ons.total_add_on": "Total Add-On",
        "product_add_ons.add_ons_list": "Add-Ons List",
        "product_add_ons.update_add_ons": "Update Add-Ons",
        "product_add_ons.branch_add_ons_list": "Branch Add-Ons List",
        "product_add_ons.create_add_ons": "Create Add-Ons",
        "product_add_ons.create_success_msg": "Add-Ons has been created successfully",
        "product_add_ons.update_success_msg": "Add-Ons has been updated successfully",
        "product_add_ons.add_ons_not_available": "Add-Ons data not available",
        "product_add_ons.add_ons_name": "Add-Ons Name",
        "product_add_ons.select_add_ons": "Select Add-Ons",
        "product_add_ons.duplicate_add_ons": "This add-ons is already selected",
        "product_add_ons.approve_msg": "Add-ons has been approved successfully",
        "product_add_ons.approve_status_msg": "Add-ons approve status has been updated successfully",

        // add-ons form
        "product_add_ons_form.add_ons_name_required": "Add-Ons name is required",
        "product_add_ons_form.add_ons_price_negative": "Add-Ons price must be a positive number",

        // request add-ons
        "request_aad_ons.request_add_ons": "Request Add-Ons",
        "request_aad_ons.create_success_msg": "Request Add-Ons has been created successfully",
        "request_aad_ons.update_success_msg": "Request Add-Ons has been updated successfully",
        "request_aad_ons.create_request_add_ons": "Create Add-Ons Request",
        "request_aad_ons.update_request_add_ons": "Update Request Add-Ons",

        // branch
        "branch.branch_list": "Branch List",
        "branch.branch": "Branch",
        "branch.create_branch": "Create Branch",
        "branch.create_success_msg": "Branch has been created successfully",
        "branch.branch_name": "Branch Name",
        "branch.update_success_msg": "Branch has been updated successfully",
        "branch.update_branch": "Update Branch",
        "branch.branch_data_not_available": "Branch data not available",
        "branch.select_branch": "Select Branch",
        "branch.branch_details": "Branch Details",
        "branch.employee": "Employee",
        "branch.total_branch": "Total Branch",

        // branch form
        "branch_form.branch_name_required": "Branch name is required",
        "branch_form.select_restaurant": "Select Restaurant",
        "branch_form.restaurant_required": "Restaurant is required",
        "branch_form.enabled": "Enabled",
        "branch_form.delivery_radius": "Delivery Area Radius (Mile)",
        "branch_form.delivery_radius_required": "Delivery area radius is required",
        "branch_form.branch_location": "Branch Location",
        "branch_form.branch_location_required": "Branch location is required",
        "branch_form.order_types": "Order Types",
        "branch_form.opening_time": "Opening Time",
        "branch_form.closing_time": "Closing Time",
        "branch_form.deliver_radius_negative": "Delivery area radius must be greater than zero",

        // franchise
        "franchise.franchise_list": "Franchise List",
        "franchise.franchise": "Franchise",
        "franchise.create_franchise": "Create Franchise",
        "franchise.create_success_msg": "Franchise has been created successfully",
        "franchise.franchise_name": "Franchise Name",
        "franchise.update_success_msg": "Franchise has been updated successfully",
        "franchise.update_franchise": "Update Franchise",
        "franchise.franchise_data_not_available": "Franchise data not available",
        "franchise.select_franchise": "Select Franchise",
        "franchise.franchise_details": "Franchise Details",
        "franchise.employee": "Employee",
        "franchise.total_franchise": "Total Franchise",

        // franchise form
        "franchise_form.franchise_name_required": "Franchise name is required",
        "franchise_form.select_restaurant": "Select Restaurant",
        "franchise_form.restaurant_required": "Restaurant is required",
        "franchise_form.enabled": "Enabled",
        "franchise_form.delivery_radius": "Delivery Area Radius (Mile)",
        "franchise_form.delivery_radius_required": "Delivery area radius is required",
        "franchise_form.franchise_location": "Franchise Location",
        "franchise_form.franchise_location_required": "Franchise location is required",
        "franchise_form.order_types": "Order Types",
        "franchise_form.opening_time": "Opening Time",
        "franchise_form.closing_time": "Closing Time",
        "franchise_form.deliver_radius_negative": "Delivery area radius must be greater than zero",
        "franchise_form.franchise": "franchise",

        // assign employee
        "assign_employee.select_employee": "Select Employee",
        "assign_employee.success_msg": "Employee has been assigned to the selected branch successfully",
        "assign_employee.employee_required": "Employee is required",
        "assign_employee.branch_required": "Branch is required",

        // order
        "order.order_list": "Order List",
        "order.order_number": "Order Number",
        "order.customer_name": "Customer Name",
        "order.status": "Status",
        "order.order_type": "Order Type",
        "order.payment_type": "Payment Type",
        "order.paid": "Paid",
        "order.create_order": "Create Order",
        "order.update_order": "Update Order",
        "order.order_details": "Order Details",
        "order.order_ifo": "Order Info",
        "order.customer_ifo": "Customer Info",
        "order.invoice": "Invoice",
        "order.customer_id": "Customer Id",
        "order.total_price": "Total Price",
        "order.total_product_price": "Total Product Price",
        "order.total_add_on_price": "Total Add-On Price",
        "order.total_product": "Total Product",
        "order.total_add_on": "Total Add-On",
        "order.promo_discount": "Promo Discount",
        "order.point_amount": "Point Amount",
        "order.final_price": "Final Price",
        "order.not_available": "Order data not available",
        "order.total_order": "Total Order",
        "order.order_type_required": "Order type is required",
        "order.select_table": "Select Table",
        "order.table_required": "Table is required",
        "order.discount_type": "Discount Type",
        "order.discount": "Discount",
        "order.comment": "Comment",
        "order.comment_required": "Comment is required",
        "order.remarks": "Remarks",
        "order.guest_numbers": "Guest Numbers",
        "order.event_date": "Event Date",
        "order.total_payable_amount": "Total Payable Amount",
        "order.invoice_number": "Invoice Number",
        "order.guests": "Guests",
        "order.generate_date": "Generated Date",
        "order.generate_time": "Generated Time",
        "order.generate_by": "Generated By",
        "order.change": "Change",
        "order.thank_order": "Thank You For Your Order",
        "order.visit": "Visit",
        "order.to_online_order": "to order online",
        "order.phone_no": "Phone No",

        // branch product
        "branch_product.availability_msg": "Branch product availability has been updated successfully",
        "branch_product.update_msg": "Branch product has been updated successfully",
        "branch_product.related_product_success_msg": "Related product has been added successfully",
        "branch_product.product_has_been": "Product has been",
        "branch_product.successfully": "successfully",
        "branch_product.enabled_small": "enabled",
        "branch_product.disabled_small": "disabled",

        // promo
        "promo.total_promo": "Total Promo",
        "promo.promo_code": "Promo Code",
        "promo.promo_list": "Promo List",
        "promo.create_promo": "Create Promo",
        "promo.update_promo": "Update Promo",
        "prom.create_success_msg": "Promo has been created successfully",
        "prom.update_success_msg": "Promo has been updated successfully",
        "prom.data_not_available": "Promo data not available",
        "prom.select_promo": "Select Promo",

        // promo form
        "promo_form.promo_code_required": "Promo code is required",
        "promo_form.valid_from": "Valid From",
        "promo_form.valid_to": "Valid To",
        "promo_form.valid_from_required": "Valid from is required",
        "promo_form.valid_to_required": "Valid to is required",
        "promo_form.price_type": "Price Type",
        "promo_form.price_type_required": "Price type is required",
        "promo_form.flat_amount": "Flat Amount",
        "promo_form.flat_amount_required": "Flat amount is required",
        "promo_form.percent": "Percent",
        "promo_form.percent_required": "Percent is required",
        "promo_form.applicable_percent_amount": "Applicable Percent Amount",
        "promo_form.applicable_order_amount": "Applicable Order Amount",
        "promo_form.flat_amount_negative": "Flat amount must be greater than zero",
        "promo_form.percent_negative": "Percent must be greater than zero",
        "promo_form.percent_max_amount_negative": "Percent max amount must be a positive number",
        "promo_form.min_order_amount_negative": "Min order amount must be a positive number",
        "promo_form.desc": "Description",

        // order type
        "order_type.delivery": "Delivery",
        "order_type.takeaway": "Takeaway",
        "order_type.dine_in": "Dine In",
        "order_type.catering": "Catering",

        // global config
        "global_config.global_config_list": "Global Config List",
        "global_config.create_global_config": "Create Global Config",
        "global_config.update_global_config": "Update Global Config",
        "global_config.create_msg": "Global config has been created successfully",
        "global_config.update_msg": "Global config has been updated successfully",
        "global_config.data_not_available": "Global config data not available",
        "global_config.activate_confirm_title": "Active Global Config",
        "global_config.disable_confirm_title": "Disable Global Config",
        "global_config.activate_confirm_desc": "Are you sure you want to activate the global config?",
        "global_config.disable_confirm_desc": "Are you sure you want to disable the global config?",
        "global_config.active_success_msg": "Global config has been activated successfully",
        "global_config.disable_success_msg": "Global config has been disabled successfully",

        // global config form
        "global_config_form.order_point": "Point Per Order",
        "global_config_form.value_per_point": "Value Per Point",
        "global_config_form.applicable_max_point_value": "Applicable Max Point Value",
        "global_config_form.branch_search_area": "Branch Search Area (Mile)",
        "global_config_form.branch_search_area_negative": "Branch search area must be a positive number",
        "global_config_form.branch_search_area_column": "Branch Search Area",
        "global_config_form.value_per_point_negative": "Value per point must be a positive number",
        "global_config_form.applicable_max_point_value_negative": "Applicable max point value must be a positive number",
        "global_config_form.currency": "Currency",
        "global_config_form.currency_required": "Currency is required",
        "global_config_form.select_currency": "Select Currency",
        "global_config_form.order_cancel_feature": "Order Cancel Feature",
        "global_config_form.cancel_count_per_day": "Maximum Cancel Count Per Day",
        "global_config_form.cancel_count_per_day_negative": "Maximum cancel count per day must be a positive number",

        // banner
        "banner.banner_list": "Banner List",
        "banner.create_banner": "Create Banner",
        "banner.update_banner": "Update Banner",
        "banner.total_banner": "Total Banner",
        "banner.banner_type": "Banner Type",
        "banner.banner_title": "Banner Title",
        "banner.banner_create_success": "Banner has been created successfully",
        "banner.banner_update_success": "Banner has been updated successfully",
        "banner.banner_data_not_available": "Banner data not available",
        "banner.sequence": "Position",

        // banner form
        "banner_form.title_required": "Banner title is required",
        "banner_form.banner_type_required": "Banner type is required",
        "banner_form.promo_required": "Promo is required",

        // restaurant dashboard
        "restaurant_dashboard.total_earning": "Total Earning",
        "restaurant_dashboard.total_earning_not_available": "Total earning data not available",
        "restaurant_dashboard.top_branch": "Top Branch",
        "restaurant_dashboard.earning_by_branch": "Earning By Branch",
        "restaurant_dashboard.earning_by_branch_not_available": "Earning by branch data not available",
        "restaurant_dashboard.total_order": "Total Order",
        "restaurant_dashboard.total_order_not_available": "Total order data not available",
        "restaurant_dashboard.order_by_branch": "Orders By Branch",
        "restaurant_dashboard.order_by_branch_not_available": "Orders by branch data nota available",
        "restaurant_dashboard.top_section_not_available": "Total summary data not available",

        // branch dashboard
        "branch_dashboard.order_summary": "Order Summary",
        "branch_dashboard.completed_order": "Completed Orders",
        "branch_dashboard.ongoing_order": "Ongoing Orders",
        "branch_dashboard.cancel_order": "Canceled Orders",
        "branch_dashboard.completed": "Completed",
        "branch_dashboard.ongoing": "Ongoing",
        "branch_dashboard.canceled": "Canceled",
        "branch_dashboard.order_type_data_not_avl": "Order type data not available",
        "branch_dashboard.order_summary_data_not_avl": "Order summary data not available",
        "branch_dashboard.top_products": "Top Products",
        "branch_dashboard.top_product_not_avl": "Top products data not available",
        "branch_dashboard.order": "Order",
        "branch_dashboard.recent_order_reviews": "Recent Order Reviews",
        "branch_dashboard.recent_order_reviews_not_avl": "Recent order reviews data not available",
        "branch_dashboard.order_id": "Order Id",
        "branch_dashboard.reviewed_branch": "Reviewed Branch",
        "branch_dashboard.data_time": "Date & Time",
        "branch_dashboard.reviewed_by": "Reviewed By",
        "branch_dashboard.message": "Message",
        "branch_dashboard.rating": "Rating",
        "branch_dashboard.recent_product_reviews": "Recent Product Reviews",
        "branch_dashboard.recent_product_reviews_not_avl": "Recent product reviews data not available",
        "branch_dashboard.reviewed_product": "Reviewed Product",
        "branch_dashboard.product_review": "Product Review",
        "branch_dashboard.order_review": "Order Review",

        // analytics
        "analytics.top_branches": "Top Branches",
        "analytics.top_branch_not_avl": "Top branch data not available",

        // table
        "table.table_list": "Table List",
        "table.table_data_not_available": "Table data not available",
        "table.create_table": "Create Table",
        "table.update_table": "Update Table",
        "table.create_success_msg": "Table has been created successfully",
        "table.update_success_msg": "Table has been updated successfully",
        "table_form.name_required": "Table number is required",
        "table_form.table_name": "Table Name",
        "table_form.description": "Description",
        "table_form.max_person": "Max Person",
        "table_form.max_person_btwn": "Max person must be between 1 to 100",
        "table_form.max_person_required": "Max person is required",

        // catering section
        "catering_section.catering_section_list": "Catering Section List",
        "catering_section.create_catering_section": "Create Catering Section",
        "catering_section.catering_section_name": "Section Name",
        "catering_section.catering_section_name_required": "Catering section name is required",
        "catering_section.note": "Note",
        "catering_section.create_success_msg": "Catering section has been created successfully",
        "catering_section.update_success_msg": "Catering section has been updated successfully",
        "catering_section.data_not_available": "Catering section data not available",
        "catering_section.update_catering_section": "Update Catering Section",
        "catering_section.catering_section": "Catering Section",

        // catering product
        "catering.request_catering_product": "Request Catering Product",
        "catering.product_list": "Catering Product List",
        "catering.create_product": "Create Catering Product",
        "catering.update_product": "Update Catering Product",
        "catering.create_product_success_msg": "Catering product has been created successfully",
        "catering.update_product_success_msg": "Catering product has been updated successfully",

        // catering menu
        "catering.catering_menu_list": "Catering Menu List",
        "catering.create_catering_menu": "Create Catering Menu",
        "catering.update_catering_menu": "Update Catering Menu",
        "catering.catering_menu_name": "Catering Menu Name",
        "catering.catering_menu_name_required": "Catering menu name is required",
        "catering.catering_menu_details": "Catering Menu Details",
        "catering.branch_menu_assigned": "Branch menu has been assigned successfully",
        "catering.request_catering_menu": "Request Catering Menu",
        "catering.menu_name": "Menu Name",
        "catering.add_at_least_one_item": "Please add at least one item",
        "catering.duplicate": "Duplicate",
        "catering.item_already_added": "This item has been already added",
        "catering.add_alternative": "Add Alternative",
        "catering.empty_plus": "Empty Plus",
        "catering.item_can_selected": "Item can be selected",
        "catering.please_select_product": "Please select product",
        "catering.choice_of_item": "Choice Of Item",
        "catering.choice_of_item_required": "Choice of item is required",
        "catering.at_least_choice_of_item": "Choice of item should be at least 1",
        "catering.menu_data_unavailable": "Catering menu data not available",
        "catering.you_have_to_select": "You have to select at least",
        "catering.because_choice": "Because your choice of item is",
        "catering.catering_menu_create_msg": "Catering menu has been created successfully",
        "catering.catering_menu_update_msg": "Catering menu has been updated successfully",
        "catering.catering_menu_has_been": "Catering menu has been",
        "catering.the_catering_menu": "the catering menu",

        // catering order
        "catering.catering_order_list": "Catering Order List",
        "catering.create_catering_order": "Create Catering Order",
        "catering.update_catering_order": "Update Catering Order",
        "catering.billed_to": "Billed To",
        "catering.invoice_no": "Invoice No",
        "catering.no_of_guests": "No. Of Guests",
        "catering.including_tax": "Including Tax",
        "catering.invoice_total": "Invoice Total",
        "catering.event_details": "Event Details",
        "catering.signature": "Signature",
        "catering.client_signature": "Client Signature",
        "catering.thank_you_for_coming": "Thank you for coming to our restaurant",

        // catering order form
        "catering_order_form.included": "Included",
        "catering_order_form.additional": "Additional",
        "catering_order_form.current_order": "Current Order",
        "catering_order_form.all_catering_menu": "All Catering Menu",
        "catering_order_form.enter_guest": "Enter Guest",
        "catering_order_form.select_catering_menu": "Select Catering Menu",
        "catering_order_form.menu_section": "Menu Section",
        "catering_order_form.select_menu": "Select Menu",
        "catering_order_form.confirm": "CONFIRM",
        "catering_order_form.alternative_items": "Alternative Items",
        "catering_order_form.choose": "Choose",
        "catering_order_form.item_here": "Item Here",
        "catering_order_form.selected_items": "Selected Items",
        "catering_order_form.selected": "Selected",
        "catering_order_form.select_category": "Select Category",
        "catering_order_form.search_product_name": "Search Product Name",
        "catering_order_form.choice_of": "Choice of",
        "catering_order_form.percentage": "Percentage",
        "catering_order_form.flat": "FLAT",
        "catering_order_form.note_optional": "Note (Optional)",
        "catering_order_form.select_event_date": "Select Event Date",
        "catering_order_form.event_location": "Event Location",
        "catering_order_form.ny_sales_tax": "N.Y Sales Tax",
        "catering_order_form.event_date_required": "Event date is required, please select your event date",
        "catering_order_form.customer_required": "Customer is required, please select customer",
        "catering_order_form.least_one_menu": "Please add at least one menu",
        "catering_order_form.catering_invoice": "Catering Invoice",

        // all
        "product.catering_type": "CATERING",

        // stock
        "stock.stock_list": "Stock In List",
        "stock.stock_small": "stock",
        "stock.create_stock": "Create",
        "stock.update_stock": "Update",
        "stock.total_stock": "Total Stock In",
        "stock.stock_number": "Stock Number",
        "stock.stock_details": "Stock In Details",
        "stock.paid_amount": "Paid Amount",
        "stock.stock_type": "Stock Type",
        "stock.stock_data_unavailable": "Stock data not available",
        "stock.stock_create_msg": "Stock has been created successfully",
        "stock.stock_update_msg": "Stock has been updated successfully",
        "stock.expired_at": "Expired At",
        "stock.expired_date": "Expired Date",
        "stock.available_qty": "Available Qty",

        // stock details
        "stock_details.ingredient_info": "Ingredient Info",
        "stock_details.stock_info": "Stock Info",
        "stock_details.agent_info": "Agent Info",
        "stock_details.balance": "Balance",
        "stock_details.total_spent": "Total Spent",

        // stock in form
        "stock_in_form.one_item_required": "Please select at least one item",
        "stock_in_form.mfg_date": "Mfg Date",
        "stock_in_form.expire_date": "Expire Date",
        "stock_in_form.select_supplier": "Select Supplier",
        "stock_in_form.select_ingredient": "Select Ingredient",
        "stock_in_form.ingredient_required": "Ingredient is required",
        "stock_in_form.unit_price": "Unit Price",
        "stock_in_form.unit_price_max_zero": "Unit price must be equal or greater than 0",
        "stock_in_form.qty_max_one": "Quantity must be equal or greater than 1",
        "stock_in_form.due_amount": "Due Amount",
        "stock_in_form.voucher_number": "Voucher Number",
        "stock_in_form.attachment": "Attachment",
        "stock_in_form.stock_date": "Stock Date",
        "stock_in_form.select_agent": "Select Agent",
        "stock_in_form.select_date": "Select Date",
        "stock_in_form.threshold": "Threshold",
        "stock_in_form.threshold_max_one": "Threshold must be a positive number",

        // stock summary
        "stock_summary.stock_summary_list": "Stock Summary List",
        "stock_summary.stock_summary_details": "Stock Summary Details",
        "stock_summary.total_stock_item": "Total Stock Item",
        "stock_summary.total_stock_summary": "Total Stock Summary",
        "stock_summary.available": "Available",
        "stock_summary.unavailable": "Unavailable",
        "stock_summary.select_stock_availability": "Select Stock Availability",
        "stock_summary.stock_summary_download": "Stock Summary Download",

        //stock out
        "stock_out.stock_out_list": "Stock Out List",
        "stock_out.total_stock_out": "Total Stock Out",
        "stock_out.stock_out_details": "Stock Out Details",
        "stock.stock_out_msg": "Stock has been out successfully",
        "stock.stock_out_update_msg": "Stock has been updated successfully",
        "stock.out_of_stock": "Out Of Stock",
        "stock.stock_out_small": "stock out",

        // stock out form
        "stock_out_form.select_stock": "Select Stock",
        "stock_out_form.stock_required": "Stock is required",
        "stock_out_form.qty_required": "Quantity is required",
        "stock_out_form.qty_between": "Quantity must be between 1 and",
        "stock_out_form.stock_type_required": "Stock type is required",
        "stock_out_form.missing": "Missing",
        "stock_out_form.out": "Out",
        "stock_out_form.spoilage": "Spoilage",
        "stock_out_form.wastage": "Wastage",

        // stock summary
        "stock_summary.create_date_range": "Created Date Range",
        "stock_summary.stock_date_range": "Stock Date Range",
        "stock_summary.expire_date_range": "Expire Date Range",

        // branch config
        "branch_config.branch_config_list": "Branch Config List",
        "branch_config.create_branch_config": "Create Branch Config",
        "branch_config.update_branch_config": "Update Branch Config",
        "branch_config.create_msg": "Branch config has been created successfully",
        "branch_config.update_msg": "Branch config has been updated successfully",
        "branch_config.data_not_available": "Branch config data not available",
        "branch_config.activate_confirm_title": "Active Branch Config",
        "branch_config.disable_confirm_title": "Disable Branch Config",
        "branch_config.activate_confirm_desc": "Are you sure you want to activate the branch config?",
        "branch_config.disable_confirm_desc": "Are you sure you want to disable the branch config?",
        "branch_config.active_success_msg": "Branch config has been activated successfully",
        "branch_config.disable_success_msg": "Branch config has been disabled successfully",
        "branch_config.mx_cancel_count": "Max Cancel Count / Day",
        "branch_config.order_cancel_time": "Order Cancellation Time (Minute)",
        "branch_config.tax_percentage": "Tax Percentage",
        "branch_config.order_cancel_time_required": "Order cancellation time is required",
        "branch_config.order_cancel_time_least": "Order cancellation time in minute must be at least 5",
        "branch_config.tax_percentage_greater": "Tax percentage must be greater than 0",

        // agent payment
        "agent_payment.total_agent_payment": "Total Payment",
        "agent_payment.agent_payment_list": "Agent Payment List",
        "agent_payment.create_agent_payment": "Create Agent Payment",
        "agent_payment.update_agent_payment": "Update Agent Payment",

        // expired stock
        "expire_stock.expired_stock_list": "Expired Stock List",

        // pos left menu
        "pos_left_menu.all_items": "All Items",

        // pos middle content
        "pos_middle_content.its_all_msg": "It is all, nothing more",
        "pos_middle_content.out_of_stock": "Out of stock",
        "pos_middle_content.search_place_holder": "Search item by name or shortcut",
        "pos_middle_content.dine_in": "Dine-In",

        // pos cart
        "pos_cart.cart": "Cart",
        "pos_cart.payment": "Payment",
        "pos_cart.clear_cart": "Clear Cart",
        "pos_cart.total_items": "Total Items",
        "pos_cart.sub_total": "Sub Total",
        "pos_cart.sale_tax": "N.Y. Sales Tax",
        "pos_cart.hold": "Hold",
        "pos_cart.save_print_kot": "Save & Print KOT",
        "pos_cart.save_print_bill": "Save & Print Bill",
        "pos_cart.item_name": "Item Name",
        "pos_cart.delete": "Delete",
        "pos_cart.qty": "Qty",
        "pos_cart.table_duplicate_add_msg": "Table has been added already",
        "pos_cart.table_no": "Table No",
        "pos_cart.order_note": "Order Note",
        "pos_cart.order_instruction_place_holder": "Any order instruction e.g. Sauce, add Spice etc.",
        "pos_cart.select_customer": "Select Customer",
        "pos_cart.new_customer": "New Customer",
        "pos_cart.no_of_guest": "No Of Guest's",
        "pos_cart.table_number": "Table Number",
        "pos_cart.paid_amount_small": "paid amount",
        "pos_cart.tables": "Tables",
        "pos_cart.online": "Online",
        "pos_cart.tips": "Tips",
        "pos_cart.table_already_added": "This table has been already added",
        "pos_cart.select_delivery_address": "Select Delivery Address",
        "pos_cart.address_title": "Address Title",
        "pos_cart.address_title_placeholder": "Address Title e.g Office",
        "pos_cart.select_branch_location": "Select Your Branch Location",
        "pos_cart.save_order": "Save Order",
        "pos_cart.complete_order": "Complete Order",

        // pos order list
        "pos_order_list.priority_updated": "Priority Updated",
        "pos_order_list.successfully": "successfully",
        "pos_order_list.priority_has_been": "Priority has been",
        "pos_order_list.set": "set",
        "pos_order_list.canceled_small": "canceled",
        "pos_order_list.order_cancel_msg": "Order has been canceled successfully",
        "pos_order_list.update_status": "Update Status",
        "pos_order_list.payment_status": "Payment Status",
        "pos_order_list.table_view": "Table View",
        "pos_order_list.hold_list": "Hold List",
        "pos_order_list.cancel_priority": "Cancel Priority",
        "pos_order_list.set_priority": "Set Priority",
        "pos_order_list.print_kot": "Print KOT",
        "pos_order_list.print_bill": "Print Bill",
        "pos_order_list.cancel_order": "Cancel Order",
        "pos_order_list.edit_order": "Edit Order",
        "pos_order_list.order_edit_confirm_msg": "Are you sure you want to edit this order",
        "pos_order_list.order_clear_msg": "Your previous order will be cleared",
        "pos_order_list.order_date_time": "Order Date Time",
        "pos_order_list.unpaid": "Unpaid",
        "pos_order_list.no_guest": "Number Of Guest",
        "pos_order_list.order_status": "Order Status",
        "pos_order_list.customer_details": "Customer Details",
        "pos_order_list.delivery_address": "Delivery Address",
        "pos_order_list.payment_details": "Payment Details",
        "pos_order_list.total_paid": "Total Paid",
        "pos_order_list.total_due": "Total Due",
        "pos_order_list.payment_method": "Payment Method",
        "pos_order_list.total": "Total",
        "pos_order_list.add_qty": "Add Qty",
        "pos_order_list.add_price": "Add Price",
        "pos_order_list.add_on_total": "Add On Total",
        "pos_order_list.item_details": "Item Details",
        "pos_order_list.pint_discount": "Point Discount",
        "pos_order_list.search_order_by_id": "Search Order By Order Id",
        "pos_order_list.assign_delivery_partner": "Assign Delivery Partner",
        "pos_order_list.assign": "Assign",
        "pos_order_list.track_order": "Track Order",

        // pos hold list
        "pos_hold_list.resume_hold_order": "Resume Hold Order",
        "pos_hold_list.confirm_hold_order_resume": "Are you sure you want to resume this hold order",
        "pos_hold_list.remove_hold_order": "Remove Hold Order",
        "pos_hold_list.confirm_remove_hold_order": "Are you sure you want to remove this hold order",

        // random user
        "user_form.please_assign_role": "Please assign a role",
        "user_form.assign_role": "Assign Role",
        "user_form.search_role": "Search Role",
        "user_form.ok": "Ok",
        "user.change_password": "Change Password",
        "user.assigned_role_list": "Assigned Role List",
        "user.assigned_roles": "Assigned Roles",
        "user.branch_manager": "Branch Manager",
        "user.waiter": "Waiter",
        "user.chef": "Chef",
        "user.franchise_owner": " Franchise Owner",

        "analytics.order_type": "Order Type",
        "analytics.customer_type": "Customer Type",
        "analytics.total_number": "Total Number",
        "analytics.customer_map": "Customer Map",
        "analytics.no_data": "No data",
        "analytics.new_customer": "New Customer",
        "analytics.retained_customer": "Retained Customer",
        "analytics.excel": "Excel",
        "analytics.pdf": "PDF",
        "analytics.customer_count": "Customer Count",
        "analytics.restaurant_name": "Restaurant Name",
        "analytics.customer": "Customer",
        "analytics.total_customer": "Total Customer",
        "analytics.order_count": "Order Count",
        "analytics.sl": "SL.",
        "analytics.start_date": "Start Date",
        "analytics.end_date": "End Date",
        "analytics.generated_at": "Generated At",
        "analytics.all_right_reserved": "All right reserved by",

        "owner_earn.branch_earning": "Branch Earning",
        "owner_earn.total_earning_report": "Total Earning Report",
        "owner_earn.total_order_report": "Total Order Report",
        "owner_earn.earning": "Earning",

        "order_item.total_item": "Total Item",
        "order_item.total_revenue": "Total Revenue",

        "user.create_franchise_user": "Create Franchise User",
        "user.update_franchise_user": "Update Franchise User",
        "user.franchise_create_msg": "Franchise user has been created successfully",
        "user.franchise_update_msg": "Franchise user has been updated successfully",
        "user.view_roles": "View Roles",

        "franchise_dashboard.total_franchise": "Total Franchise",
        "franchise_dashboard.earning_by_franchise": "Earning By Franchise",
        "franchise_dashboard.order_by_franchise": "Order By Franchise",
        "franchise_dashboard.franchise_name": "Franchise Name",
        "restaurant_dashboard.top_franchise": "Top Franchise",

        "payment.payment_create_msg": "Payment has been created successfully",

        "ingredient_category.ingredient_category_name_required": "Ingredient category name is required",
        "ingredient_category.create_msg": "Ingredient category has been created successfully",
        "ingredient_category.update_msg": "Ingredient category has been updated successfully",

        "ingredient.create_msg": "Ingredient has been created successfully",
        "ingredient.update_msg": "Ingredient has been updated successfully",

        "supplier.create_msg": "Supplier has been created successfully",
        "supplier.update_msg": "Supplier has been updated successfully",

        "ingredient.total_global_ingredient": "Total global ingredient",

        // random
        "image.smaller_then_two_mb": "Image must smaller than 2MB!",
        "image.upload_image_type": "You can only upload JPG/PNG/SVG/WEBP file!",
        "image.upload_image_type_two": "You can only upload JPG/PNG/SVG file!",
        "image.can_not_upload": "Can not upload image ! ",

        "toast.string_error": "Error title must be a string.",
        "toast.desc_string_error": "Error description must be a string.",

        "item.qty": "Qty.",

        "order.order": "Order",
        "order.mark_as_ready": "Mark as ready",
        "order.upcoming_order": "Upcoming Order",
        "order.take_order": "Take order",
        "order.discount_amount": "Discount Amount",
        "order.text_amount": "Tax Amount",
        "order.total_paid_amount": "Total Paid Amount",
        "order.order_item_not_available": "Order Item Report Data Not Available",
        "order.order_report_data_not_available": "Order Report Data Not Available",
        "order.selected_product_added_already": "Your selected product has been added already.",
        "order.order_created_successfully": "Order has been created successfully",
        "order.order_updated_successfully": "Order has been updated successfully",
        "order.cart_added_successfully": "Your cart has been added to hold successfully",
        "order.removed": "Removed",
        "order.order_removed": "Hold order has been removed successfully",
        "order.status_updated": "Default status has been updated successfully",
        "order.cancel_note": "Cancel Note",
        "order.order_created": "Order has been created successfully.",

        "display.display_not_support": "Kitchen display not support to your current screen.",
        "display.required_minimum_width": "It required minimum 1024px screen width.",
        "display.current_screen_width": "Your current screen width",

        "summary.latest_order_summary": "Latest 10 order item summary",

        "customer.table_numbers": "Table Numbers",

        "product.product_required": "Product is required",

        "product.quantity_greater_than_zero": "Quantity must be greater than zero",

        "delivery.pickup_info": "Pickup Info",
        "delivery.pickup_address": "Pickup Address",
        "delivery.pickup_phone": "Pickup Phone",
        "delivery.delivery_partner": "Delivery Partner",
        "delivery.pick_time": "Pickup Time",
        "delivery.pickup": "Pickup time is required",
        "delivery.notification": "Notification",
        "delivery.alcohol": "Alcohol",
        "delivery.pickup_instruction": "Pickup Instruction",

        "customer.customer_address": "Customer Address",

        "customer.customer_instructions": "Customer Instructions",

        "table.no_of_table": "No Of Orders",

        "catering.already_catering_added": "Already this catering item is added",

        "branch.make_default": "Make Default",
        "branch.make_branch_default": "Are you sure you want to make this branch as default",
        "branch.item_already_added": "Item has been already added",
        "branch.sure_want_to": "Are you sure you want to",
        "branch.the_order": "the order ?",
        "branch.payment_amount": "Your payment amount",
        "branch.payment_error": "Payment Error",
        "branch.is_less_than_total_amount": "is less than total amount",
        "branch.pay_total_amount": "Please pay the total amount",
        "branch.add_on": "Add Add-on",
        "branch.current_order_summery": "Current Order Summary",
        "branch.prev_order_summery": "Previous Order Summary",
        "branch.cash_amount_greater_than_zero": "Cash amount must be greater than 0",
        "branch.cash_amount_required": "Cash amount is required",
        "branch.online_amount_greater_than_zero": "Online amount must be greater than 0",
        "branch.mfs_amount_greater_than_zero": "MFS amount must be greater than 0",
        "branch.online_amount_required": "Online amount is required",
        "branch.mfs_amount_required": "MFS amount is required",
        "branch.replace_amount": "Replace Product",
        "branch.order_history_data_not_available": "Order History Data Not Available",
        "branch.view": "view",
        "branch.kot": "KOT",
        "branch.product_status_changed": "Product status has been changed to stock successfully",
        "branch.product_status_changed_stock_out": "Product status has been changed to out of stock successfully",
        "branch.product_popular_state_changed": "Product popular status has been changed successfully",
        "branch.select_at+least_one_product": "Please select at least one product. And give minimum quantity one",

        "catering.catering_menu_requested_successfully": "Catering menu has been requested successfully",
    }
}
