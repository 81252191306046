import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";
import {getBranchId} from "../../helpers/Utils";

export default class BranchOrderService {

    static getAllBranchOrder = params => privateAPI.get(
        `${URL.GET_ALL_BRANCH_ORDER_URL}/${getBranchId()}`, {
            params,
            paramsSerializer: {
                indexes: null // by default: false
            }
        }
    );

    static getBranchOrderHistory = orderId => privateAPI.get(
        `${URL.GET_BRANCH_ORDER_HISTORY_URL}/${orderId}/${getBranchId()}`
    );

    static getBranchOrderById = orderId => privateAPI.get(
        `${URL.GET_BRANCH_ORDER_BY_ID_URL}/${orderId}/${getBranchId()}`
    );

    static getBranchOrderCountByStatus = () => privateAPI.get(
        `${URL.GET_BRANCH_ORDER_COUNT_BY_STATUS}/${getBranchId()}`
    );

    static updateBranchOrder = (orderId, data) => privateAPI.put(
        `${URL.UPDATE_BRANCH_ORDER_URL}/${orderId}/${getBranchId()}`, data
    );

    static urgentBranchOrder = (orderId, urgent) => privateAPI.put(
        `${URL.URGENT_BRANCH_ORDER_URL}/${orderId}/${getBranchId()}`, null, {params: urgent}
    );

    static createBranchOrder = data => privateAPI.post(
        `${URL.CREATE_BRANCH_ORDER_URL}/${getBranchId()}`, data
    );

    static cancelBranchOrder = (id, params) => privateAPI.put(
        `${URL.CANCEL_BRANCH_ORDER}/ ${id}/${getBranchId()}`, null, {params}
    );

    static assignDeliveryPartner = data => privateAPI.post(
        `${URL.ASSIGN_DOORDASH_DELIVERY_PARTNER}/${data.id}/${getBranchId()}`, data
    );

    static getDoordashStateList = orderId => privateAPI.get(
        `${URL.GET_ALL_DOORDASH_ORDER_STATE}/${orderId}/${getBranchId()}`
    );

}
