import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";
import {getBranchId} from "../helpers/Utils";

export default class BranchReportService {

    static getBranchReportTotalCount = params => privateAPI.get(
        `${URL.GET_BRANCH_REPORT_TOTAL_COUNT_URL}/${getBranchId()}`, {params}
    );

    static getBranchReportTopProduct = params => privateAPI.get(
        `${URL.GET_BRANCH_REPORT_TOP_PRODUCT_URL}/${getBranchId()}`, {params}
    );

    static getBranchReportOrderType = params => privateAPI.get(
        `${URL.GET_BRANCH_REPORT_ORDER_TYPE_URL}/${getBranchId()}`, {params}
    );

    static getBranchReportOrderSummary = params => privateAPI.get(
        `${URL.GET_BRANCH_REPORT_ORDER_SUMMARY_URL}/${getBranchId()}`, {params}
    );

    static getBranchReportOrderLineChart = params => privateAPI.get(
        `${URL.GET_BRANCH_REPORT_ORDER_LINE_CHART_URL}/${getBranchId()}`, {params}
    );

    static getBranchReportReview = params => privateAPI.get(
        `${URL.GET_BRANCH_REPORT_REVIEW_URL}/${getBranchId()}`, {params}
    );

    static getBranchOrderReport = params => privateAPI.get(
        `${URL.GET_BRANCH_ORDER_REPORT_URL}/${getBranchId()}`, {params}
    );

    static getBranchOrderItemReport = params => privateAPI.get(
        `${URL.GET_BRANCH_ORDER_ITEM_SALE_REPORT_URL}/${getBranchId()}`, {params}
    );
}
