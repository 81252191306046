import {notification} from "antd";
import Translator from "../../i18n/translator";

export const Toast = (type, title, desc) => {

    if (typeof title !== 'string') {
        desc = Translator("toast.string_error");
    }

    if (typeof desc !== 'string') {
        desc = Translator("toast.desc_string_error");
    }

    notification[type]({
        message: title,
        description: desc,
    });
};
