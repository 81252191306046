import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";
import {getBranchId} from "../../helpers/Utils";

export default class BranchProductService {

    static assignBranchProduct = data => privateAPI.post(
        `${URL.ASSIGN_BRANCH_PRODUCT}/${getBranchId()}`, data
    );

    static addRelatedProduct = (id, data) => privateAPI.put(
        `${URL.ADD_RELATED_PRODUCT}/${id}/${getBranchId()}`, data
    );

    static getAllBranchProduct = params => privateAPI.get(
        `${URL.GET_ALL_BRANCH_PRODUCT}/${getBranchId()}`, {
            params,
            paramsSerializer: {
                indexes: null // by default: false
            }
        }
    );

    static getBranchProductById = id => privateAPI.get(
        `${URL.GET_BRANCH_PRODUCT_BY_ID}/${id}/${getBranchId()}`
    );

    static getAllRelatedProduct = id => privateAPI.get(
        `${URL.GET_ALL_RELATED_PRODUCT}/${id}/${getBranchId()}`
    );

    static updateBranchProduct = (id, data) => privateAPI.put(
        `${URL.UPDATE_BRANCH_PRODUCT}/${id}/${getBranchId()}`, data
    );

    static getBranchProductIngredients = id => privateAPI.get(
        `${URL.GET_BRANCH_PRODUCT_INGREDIENTS}/${id}/${getBranchId()}`
    );

    static updateBranchProductIngredients = (id, data) => privateAPI.put(
        `${URL.UPDATE_BRANCH_PRODUCT_INGREDIENTS}/${id}/${getBranchId()}`, data
    );

}
